/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-concat */
/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import ReactDOM from 'react-dom';
import BScroll from '@better-scroll/core';
import { getPicture, reqCheck } from '@/request/login';
import styles from './style.module.scss';
import { aesEncrypt } from '@/utils/ase';

export interface CaptchaSuccess {
  captchaVerification: string;
}

interface VerifySlideFixedProps {
  vSpace?: number;
  barSize?: {
    width?: string;
    height?: string;
  };
  isSlideShow?: boolean;
  finishText?: string;
  onClose?: () => void;
  success?(value: CaptchaSuccess): void;
}

const VerifySlideFixed = (props: VerifySlideFixedProps) => {
  const { t } = useTranslation('common');
  const divRef = useRef<HTMLDivElement>(null);
  const {
    vSpace = 5,
    barSize = {
      width: '330px',
      height: '40px',
    },
    finishText = '',
    isSlideShow,
    onClose,
    success,
  } = props;
  const setSize = {
    imgHeight: 155,
    imgWidth: 330,
    barHeight: 0,
    barWidth: 0,
  };
  const imgSize = {
    width: 330,
    height: 155,
  };
  const captchaType = 'blockPuzzle';
  const textDefault = t('向右滑动完成验证');
  const [blockSize] = useState({ width: 50, height: 50 });
  const [backImgBase, setBackImgBase] = useState('');
  const [blockBackImgBase, setBlockBackImgBase] = useState('');
  const [backToken, setBackToken] = useState('');
  const [startMoveTime, setStartMoveTime] = useState(0);
  const [secretKey, setSecretKey] = useState('');
  const [moveBlockBackgroundColor, setMoveBlockBackgroundColor] = useState('rgb(255, 255, 255)');
  const [leftBarBorderColor, setLeftBarBorderColor] = useState('');
  const [iconColor, setIconColor] = useState('');
  const [barAreaLeft, setBarAreaLeft] = useState(0);
  const [barAreaOffsetWidth, setBarAreaOffsetWidth] = useState(0);
  const [startLeft, setStartLeft] = useState(0);
  const [moveBlockLeft, setMoveBlockLeft] = useState(0);
  const [leftBarWidth, setLeftBarWidth] = useState(0);
  const [status, setStatus] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [passFlag, setPassFlag] = useState(false);
  const [tipWords, setTipWords] = useState('');
  const [text, setText] = useState(textDefault);
  const [transitionLeft, setTransitionLeft] = useState('');
  const [transitionWidth, setTransitionWidth] = useState('');
  //  存储better-scroll的实例
  const [scrollObj, setscrollObj] = useState<any>();
  const slideRef = useRef<any>();

  // 初始话 uuid
  const uuid = () => {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    // @ts-ignore
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    const slider = `${'slider' + '-'}${s.join('')}`;
    const point = `${'point' + '-'}${s.join('')}`;
    // 判断下是否存在 slider
    if (!localStorage.getItem('slider')) {
      localStorage.setItem('slider', slider);
    }
    if (!localStorage.getItem('point')) {
      localStorage.setItem('point', point);
    }
  };

  const getData = () => {
    getPicture({ captchaType, clientUid: localStorage.getItem('slider') || '', ts: Date.now() }).then((res: any) => {
      if (res?.repCode === '0000') {
        setBackImgBase(res?.repData?.originalImageBase64 || '');
        setBlockBackImgBase(res?.repData?.jigsawImageBase64 || '');
        setBackToken(res?.repData?.token || '');
        setSecretKey(res?.repData?.secretKey || '');
      }
      // 请求次数超限
      if (res.repCode === '6201') {
        setBackImgBase('');
        setBlockBackImgBase('');
        setLeftBarBorderColor('#d9534f');
        setIconColor('#fff');
        setPassFlag(false);
        setTipWords(res?.repMsg || '');
        setTimeout(() => {
          setTipWords('');
        }, 1000);
      }
      setTransitionLeft('');
      setTransitionWidth('');
      setMoveBlockLeft(0);
      setLeftBarWidth(0);
    });
  };

  useEffect(() => {
    uuid();
    getData();
  }, []);

  const refresh = () => {
    getData();
    setMoveBlockLeft(0);
    setLeftBarWidth(0);
    setText(t('向右滑动完成验证'));
    setMoveBlockBackgroundColor('#fff');
    setLeftBarBorderColor('#337AB7');
    setIconColor('#fff');
    setStatus(false);
    setIsEnd(false);
    setTransitionWidth('');
    setTransitionLeft('');
  };

  const move = (e: any = window.event) => {
    e.preventDefault();
    if (status && isEnd === false) {
      let x;
      if (!e.touches) {
        // 兼容PC端
        x = e.clientX;
      } else {
        // 兼容移动端
        x = e.touches[0].pageX;
      }
      const bar_area_left = barAreaLeft;
      let move_block_left = x - bar_area_left; // 小方块相对于父元素的left值
      if (move_block_left >= barAreaOffsetWidth - blockSize.width / 2 - 2) {
        move_block_left = barAreaOffsetWidth - blockSize.width / 2 - 2;
      }
      if (move_block_left <= 0) {
        move_block_left = blockSize.width / 2;
      }
      // 拖动后小方块的left值
      setMoveBlockLeft(move_block_left - startLeft);
      setLeftBarWidth(move_block_left - startLeft);
    }
  };
  const end = () => {
    const endMovetime = +new Date();
    // 判断是否重合
    if (status && isEnd === false) {
      let moveLeftDistance = moveBlockLeft;
      moveLeftDistance = (moveLeftDistance * 310) / setSize.imgWidth;
      const data = {
        captchaType,
        pointJson: secretKey
          ? aesEncrypt(JSON.stringify({ x: moveLeftDistance, y: 5.0 }), secretKey)
          : JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
        token: backToken,
        clientUid: localStorage.getItem('slider'),
        ts: Date.now(),
      };
      reqCheck(data).then((res: any) => {
        if (res.repCode === '0000') {
          setIsEnd(true);
          setPassFlag(true);
          setTipWords(`${((endMovetime - startMoveTime) / 1000).toFixed(2)}s t('验证成功')`);
          setTransitionLeft('left .3s');
          setTransitionWidth('width .3s');
          setTimeout(() => {
            setTipWords('');
            refresh();
          }, 1000);
          const captchaVerification: string = secretKey
            ? aesEncrypt(`${backToken}---${JSON.stringify({ x: moveLeftDistance, y: 5.0 })}`, secretKey)
            : `${backToken}---${JSON.stringify({ x: moveLeftDistance, y: 5.0 })}`;
          if (success) {
            success({ captchaVerification });
          }
        } else {
          setIsEnd(true);
          setMoveBlockBackgroundColor('#d9534f');
          setLeftBarBorderColor('#d9534f');
          setIconColor('#fff');
          setPassFlag(false);
          setTipWords(t('验证失败'));
          setTransitionLeft('left .3s');
          setTransitionWidth('width .3s');
          setTimeout(() => {
            setTipWords('');
            refresh();
          }, 1000);
        }
      });
      setStatus(false);
    }
  };

  useEffect(() => {
    const slideRefCurrent = slideRef?.current;
    slideRefCurrent?.addEventListener?.('touchmove', move, { passive: false });
    return () => {
      slideRefCurrent?.removeEventListener?.('touchmove', move);
    };
  });

  useEffect(() => {
    window.addEventListener('mousemove', move);
    return () => {
      window.removeEventListener('mousemove', move);
    };
  });

  useEffect(() => {
    window.addEventListener('touchend', end);
    return () => {
      window.removeEventListener('touchend', end);
    };
  });

  useEffect(() => {
    window.addEventListener('mouseup', end);
    return () => {
      window.removeEventListener('mouseup', end);
    };
  });

  const initBScroll = () => {
    setscrollObj(
      new BScroll(divRef.current as HTMLDivElement, {
        //  可以使用原生的点击
        click: true,
      }),
    );
  };

  useEffect(() => {
    if (divRef.current) {
      initBScroll();
    }
    return () => {
      //  组件卸载时记得将其销毁
      scrollObj?.destroy();
    };
  }, [divRef.current]);

  const setBarArea = (event: any) => {
    const areaLeft = event && event.getBoundingClientRect().left;
    const areaOffsetWidth = event && event.offsetWidth;
    setBarAreaLeft(areaLeft);
    setBarAreaOffsetWidth(areaOffsetWidth);
  };
  const start = (e: any = window.event) => {
    let x;
    if (!e.touches) {
      // 兼容PC端
      x = e.clientX;
    } else {
      // 兼容移动端
      x = e.touches[0].pageX;
    }
    const startLeftN = Math.floor(x - barAreaLeft);
    setStartLeft(startLeftN);
    // 开始滑动的时间
    const time = +new Date(); // 开始滑动的时间
    setStartMoveTime(time);
    if (isEnd === false) {
      setText('');
      setMoveBlockBackgroundColor('#337ab7');
      setLeftBarBorderColor('#337AB7');
      setIconColor('#fff');
      e.stopPropagation();
      setStatus(true);
    }
  };

  const closeBox = () => {
    if (onClose) {
      onClose();
    }
  };

  return isSlideShow ? (
    ReactDOM.createPortal(
      <div className={styles.mask} style={{ display: isSlideShow ? 'block' : 'none' }}>
        <div className={styles.verifybox} style={{ maxWidth: `${imgSize.width + 30}px` }} ref={slideRef}>
          <div className={styles.verifyboxTop}>
            {t('请完成安全验证')}
            <span className={styles.verifyboxClose} onClick={closeBox}>
              <i className={cls(styles.iconfont, styles.iconClose)} />
            </span>
          </div>
          <div className={styles.verifyboxBottom} style={{ padding: '15px' }}>
            {/* 验证容器 */}
            <div style={{ position: 'relative' }} className={styles.stopUserSelect}>
              <div className={styles.verifyImgOut} style={{ height: setSize.imgHeight + vSpace }}>
                <div className={styles.verifyImgPanel} style={{ width: setSize.imgWidth, height: setSize.imgHeight }}>
                  {backImgBase ? (
                    <img
                      src={`data:image/png;base64,${backImgBase}`}
                      alt=""
                      style={{ width: '100%', height: '100%', display: 'block' }}
                    />
                  ) : (
                    <img
                      src="/imgs/verifition-default.jpeg"
                      alt=""
                      style={{ width: '100%', height: '100%', display: 'block' }}
                    />
                  )}
                  <div className={styles.verifyRefresh} onClick={refresh}>
                    <i className={cls(styles.iconfont, styles.iconRefresh)} />
                  </div>
                  <div className={cls(styles.tips)}>
                    <span
                      className={cls(styles.verifyTips, tipWords.length > 0 && styles.tipsEnter, {
                        [styles.sucBg]: passFlag,
                        [styles.errBg]: !passFlag,
                      })}
                    >
                      {tipWords}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={styles.verifyBarArea}
                style={{ width: setSize.imgWidth, height: barSize.height, lineHeight: barSize.height }}
                ref={(bararea) => setBarArea(bararea)}
              >
                <span className={styles.verifyMsg}>{text}</span>
                <div
                  className={styles.verifyLeftBar}
                  style={{
                    width: leftBarWidth !== undefined ? leftBarWidth : barSize.height,
                    height: barSize.height,
                    borderColor: leftBarBorderColor,
                    // @ts-ignore
                    transaction: transitionWidth,
                  }}
                >
                  <span className={styles.verifyMsg}>{finishText}</span>

                  <div
                    className={styles.verifyMoveBlock}
                    onTouchStart={(e) => start(e)}
                    onMouseDown={(e) => start(e)}
                    style={{
                      width: barSize.height,
                      height: barSize.height,
                      backgroundColor: moveBlockBackgroundColor,
                      left: moveBlockLeft,
                      transition: transitionLeft,
                    }}
                  >
                    <i
                      className={cls(styles.verifyIcon, styles.iconfont, styles.iconRight)}
                      style={{ color: iconColor }}
                    />
                    <div
                      className={styles.verifySubBlock}
                      style={{
                        width: `${Math.floor((setSize.imgWidth * 47) / 310)}px`,
                        height: setSize.imgHeight,
                        top: `-${setSize.imgHeight + vSpace}px`,
                        backgroundSize: `${setSize.imgWidth} ${setSize.imgHeight}`,
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${blockBackImgBase}`}
                        alt=""
                        style={{ width: '100%', height: '100%', display: 'block' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document?.body,
    )
  ) : (
    <></>
  );
};

export default VerifySlideFixed;
