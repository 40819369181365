import RCForm from 'rc-field-form';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import classNames from 'classnames';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import Button from '../Button';
import Toast from '@/components/Toast';
import CodeInput from '../CodeInput';
import ToastLoading from '@/components/ToastLoading';
import PhoneInput from '../PhoneInput';
import { registerAccountV2 } from '@/request/login';
import { setTokenInfo } from '@/utils/token';
import Modal from '@/components/Modal';
// eslint-disable-next-line import/no-cycle
import LoginPC from '..';
import RegistSuccess from '../RegistSuccess';
import { LoginBtnReportParams } from '@/request/interface/login';

interface FormValues {
  phone: string[];
  verificationCode: string;
}

interface Props {
  handleLoginFinish: (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => void;
  handleClose: () => void;
  isEmailRegist?: boolean;
}

const MobileRegistForm = forwardRef<{ resetForm: () => void }, Props>(
  ({ handleLoginFinish, handleClose, isEmailRegist }, ref) => {
    const [form] = RCForm.useForm();
    const { t } = useTranslation(['common']);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [needLogin, setNeedLogin] = useState<boolean>(false);
    const [country, setCountry] = useState('');
    const [successVisible, setSuccessVisible] = useState<boolean>(false);
    const [loginPCVisible, setLoginPCVisible] = useState<boolean>(false);
    // const [params, setParams] = useState<{ phone: string[] } | undefined>();

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        form.resetFields();
      },
    }));

    const isValidFormValues = (values: FormValues) => {
      const { phone } = values;
      if (!/^\d+$/.test(phone?.[1])) {
        Toast.info(t('请输入有效手机号'));
        return false;
      }
      return true;
    };

    const handleFinish = throttle(async (values: FormValues) => {
      try {
        if (!isValidFormValues(values)) {
          return;
        }
        const { phone, verificationCode } = values;
        ToastLoading.show(t('加载中...'));
        const { content } = await registerAccountV2({
          target: `${phone[0]} ${phone[1]}`,
          country,
          registerWay: 'PHONE',
          verificationCode,
          ...(isEmailRegist ? { email: localStorage.getItem('preEmail') || '' } : {}),
        });
        const { token, errorCode, errorMessage } = content;
        if (errorCode && errorMessage) {
          Toast.info(errorMessage);
          return;
        }
        if (!token) {
          return;
        }
        setTokenInfo(token);
        if (content?.accountData?.loginOrRegister === 'active') {
          handleLoginFinish(false);
        } else {
          handleLoginFinish(true, { data: { _event: 'O_RegisterSuccess', logon_method: '手机' } });
          setSuccessVisible(true);
        }
        ToastLoading.hide();
        handleClose();
      } finally {
        ToastLoading.hide();
      }
    }, 1000);

    const checkSubmit = () => {
      const phone = form?.getFieldValue?.('phone')?.filter?.((item: string) => !!item);
      const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
      setCanSubmit(formLength === 2 && phone?.length === 2);
    };

    return (
      <div className={styles.resetFormContainer}>
        <div className={styles.title}>{t('注册领取免费课程')}</div>
        <RCForm className={styles.form} form={form} onFinish={handleFinish}>
          <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
          <FormItem
            style={{ marginBottom: 16 }}
            name="phone"
            rules={[
              {
                validator() {
                  setNeedLogin(false);
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput
              maxLength={30}
              placeholder={t('请输入手机号')}
              onChangeCountry={(value) => setCountry(value)}
              className={classNames({ [styles.redBorder]: needLogin })}
            />
          </FormItem>
          {needLogin && (
            <FormItem style={{ marginBottom: 16 }} className={styles.registInfoContainer}>
              <>
                <div className={styles.registInfo}>
                  {t('账号已注册')}
                  {/* <span
                    onClick={() => {
                      handleClose();
                      setLoginPCVisible(true);
                      const phone = form.getFieldValue('phone');
                      phone.push(country);
                      setParams({ phone });
                    }}
                  >
                    {t('点击登录')}
                  </span> */}
                </div>
              </>
            </FormItem>
          )}
          <FormItem
            style={{ marginBottom: 0 }}
            shouldUpdate={(preValues, nextValues) => {
              return preValues.phone !== nextValues.phone;
            }}
          >
            {() => {
              return (
                <FormItem
                  style={{ marginBottom: 16 }}
                  name="verificationCode"
                  rules={[
                    {
                      validator() {
                        checkSubmit();
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CodeInput
                    placeholder={t('请输入验证码')}
                    maxLength={6}
                    target={
                      form.getFieldValue('phone')?.length === 2
                        ? `${form.getFieldValue('phone')[0]} ${form.getFieldValue('phone')[1]}`
                        : ''
                    }
                    type="MOBILE_PHONE"
                    way="REGISTER"
                    auto
                    btnDisabled={needLogin}
                    onNeedLogin={(value) => setNeedLogin(value)}
                  />
                </FormItem>
              );
            }}
          </FormItem>
          <Button
            onClick={() => {
              form.submit();
            }}
            disabled={!canSubmit}
            style={{ marginTop: 190 }}
          >
            {t('完成')}
          </Button>
        </RCForm>
        <Modal visible={loginPCVisible} handleClose={() => setLoginPCVisible(false)}>
          <LoginPC
            handleLoginFinish={handleLoginFinish}
            handleClose={() => setLoginPCVisible(false)}
            handleOpen={() => setLoginPCVisible(true)}
            // params={params}
          />
        </Modal>
        <Modal visible={successVisible} handleClose={() => setSuccessVisible(false)}>
          <RegistSuccess registType="手机" handleLoginFinish={handleLoginFinish} />
        </Modal>
      </div>
    );
  },
);

export default MobileRegistForm;
