import React, { useEffect, useRef, useState, useContext } from 'react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useMount } from 'react-use';
import { MyContext } from '@/config/context-manager';
import Input from '../Input';
import { getCountry } from '../../../request/nav';

import styles from './style.module.scss';

const NUMBER_REG = /^\d+$/g;
export interface PhoneInputProps {
  className?: string;
  onChange?: (value: [string, string] | string) => void;
  value?: [string, string];
  maxLength?: number;
  codeClassName?: string;
  onChangeCountry?: (value: string) => void;
  onClick?: () => void;
  position?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  // 用户手动点击切换国家
  handleChangeCountry?: (value: string) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  const { className, onChange, maxLength, value, onChangeCountry, onClick, position, disabled, handleChangeCountry } =
    props;
  const { t } = useTranslation('form');
  const { locale } = useContext(MyContext);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentOptions, setCurrentOptions] = useState<{ label: string; value: string }[]>([]);
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const listRef = useRef<HTMLDivElement>(null);

  useMount(() => {
    if (disabled) {
      return;
    }
    getCountry({ time: '' }).then((res) => {
      const countries = res?.data;
      sessionStorage.setItem('timeZone', JSON.stringify(res?.data?.timeZone));
      sessionStorage.setItem('countryIp', JSON.stringify(res?.data?.country));
      if (countries?.countryCode === '+86') {
        // 如果是+86默认更换为+1
        const country = countries?.countryList?.find((item) => item?.d_code === '+1');
        onChange?.(country?.d_code ?? '');
        onChangeCountry?.(country?.zh_name ?? '');
      } else {
        onChange?.(countries?.countryCode ?? '');
        onChangeCountry?.(countries?.country);
      }
      if (countries?.countryList) {
        setOptions(
          countries?.countryList?.map((item) => ({
            label: locale === 'en' ? item.en_name : item.zh_name,
            value: item.d_code,
          })),
        );
        setCurrentOptions(
          countries?.countryList?.map((item) => ({
            label: locale === 'en' ? item.en_name : item.zh_name,
            value: item.d_code,
          })),
        );
      }
    });
  });

  useEffect(() => {
    const handleDomClick = () => {
      setVisible(false);
    };

    window.addEventListener('click', handleDomClick);

    return () => {
      window.removeEventListener('click', handleDomClick);
    };
  }, []);

  const codeNode = (
    <div className={cls(locale === 'en' && styles.localeEn)}>
      <div
        className={cls(styles.codeBox, position && styles.codeBoxPosition)}
        onClick={(e) => {
          if (disabled) {
            return;
          }
          onClick?.();
          setVisible(!visible);
          e.stopPropagation();
        }}
      >
        <span className={styles.codeValue}>{value?.[0] || '+1'}</span>
        <svg
          width="14"
          height="14"
          className={visible ? styles.iconRotate : styles.icon}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.22229 11.0378C6.62253 11.533 7.37747 11.533 7.77771 11.0378L11.7458 6.12862C12.2744 5.47468 11.8089 4.5 10.9681 4.5H3.03193C2.19108 4.5 1.72565 5.47468 2.25422 6.12862L6.22229 11.0378Z"
            fill="#8D8FA6"
            fillOpacity="0.85"
          />
        </svg>
      </div>
      {visible && (
        <div
          className={cls(styles.list, position && styles.listPosition)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Input
            placeholder={t('表单-国家或地区')}
            className={styles.search}
            value={searchValue}
            onChange={(e) => {
              const { value: searchText } = e.currentTarget;
              setSearchValue(searchText);
              if (searchText.match(NUMBER_REG) || searchText.trim().startsWith('+')) {
                setCurrentOptions(options?.filter((item) => item.value?.includes(searchText)));
              } else {
                setCurrentOptions(
                  options?.filter((item) => item.label?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase())),
                );
              }
            }}
            prefix={
              <div className={styles.searchIcon}>
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.01" width="18" height="18" fill="#D8D8D8" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3078 11.6538C11.6323 11.3294 12.1583 11.3294 12.4827 11.6538L15.4199 14.5911C15.7444 14.9155 15.7444 15.4415 15.4199 15.7659C15.0955 16.0904 14.5695 16.0904 14.245 15.7659L11.3078 12.8287C10.9834 12.5043 10.9834 11.9783 11.3078 11.6538Z"
                    fill="#D1D1D1"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.13965 5.48624C3.43637 7.18953 3.43637 9.9511 5.13965 11.6544C6.84294 13.3577 9.60451 13.3577 11.3078 11.6544C13.0111 9.9511 13.0111 7.18953 11.3078 5.48624C9.60451 3.78295 6.84294 3.78295 5.13965 5.48624ZM3.96477 12.8293C1.61261 10.4771 1.61261 6.66351 3.96477 4.31135C6.31693 1.9592 10.1305 1.9592 12.4827 4.31135C14.8348 6.66351 14.8348 10.4771 12.4827 12.8293C10.1305 15.1814 6.31693 15.1814 3.96477 12.8293Z"
                    fill="#D1D1D1"
                  />
                </svg>
              </div>
            }
          />
          <div className={styles.listBox}>
            <div ref={listRef} style={{ height: 'auto' }}>
              {currentOptions?.map((item, index) => (
                <div
                  className={cls(styles.listItem, value?.[0] === item.value && styles.listItemActive)}
                  onClick={() => {
                    onChange?.([item?.value ?? '', value?.[1] ?? '']);
                    setVisible(false);
                    onChangeCountry?.(item.label);
                    handleChangeCountry?.(item.label);
                  }}
                  key={index}
                >
                  <span>{item.label}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Input
      onChange={(e) => onChange?.([value?.[0] || '', e.currentTarget.value])}
      placeholder={t('表单-手机号')}
      maxLength={maxLength}
      prefixCls={styles.code}
      prefix={codeNode}
      className={cls(className, position && styles.inputPositionT)}
      value={value?.[1] || ''}
      disabled={disabled}
    />
  );
};

export default PhoneInput;
