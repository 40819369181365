import RCForm from 'rc-field-form';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import { traceEvent } from '@wk/wk-gatherer';
import { useUpdateEffect } from 'react-use';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import PhoneInput from '../PhoneInput';
import Input from '../Input';
import Button from '../Button';
// eslint-disable-next-line import/no-cycle
import Footer from '../Footer';
import Toast from '@/components/Toast';
import { loginByPhone } from '@/request/login';
import CodeInput from '../CodeInput';
import ToastLoading from '@/components/ToastLoading';
import { setTokenInfo } from '@/utils/token';
// eslint-disable-next-line import/no-cycle
import MobileResetPasswordForm from '../MobileResetPasswordForm';
import Modal, { showConfirmModal } from '@/components/Modal';
// eslint-disable-next-line import/no-cycle
import Register from '../Register';
import VerifySecurityVisibleForm from '../VerifySecurityVisibleForm';
import SetLoginPasswordForm from '../SetLoginPasswordForm';
import { LoginBtnReportParams } from '@/request/interface/login';
import LoginContext from '@/context/LoginContext';
import PrivacyConfirmTip from '../PrivacyConfirmTip';
import RegistSuccess from '../RegistSuccess';

interface FormValues {
  phone: string[];
  password: string;
  verificationCode: string;
  inviteCode?: string;
}

interface Props {
  handleLoginFinish: (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => void;
  handleClose: () => void;
  handleOpen: () => void;
  params?: { phone: string[] } | undefined;
  loginType: 'password' | 'code';
  setLoginType: (loginType: 'password' | 'code') => void;
  tabType: 'mobile' | 'email';
  setTabType: (tabType: 'mobile' | 'email') => void;
  privacyChecked: boolean;
  setPrivacyChecked: (privacyChecked: boolean) => void;
}

const MobileLoginForm: FC<Props> = ({
  handleLoginFinish,
  handleClose,
  handleOpen,
  params,
  loginType,
  setLoginType,
  tabType,
  setTabType,
  privacyChecked,
  setPrivacyChecked,
}) => {
  const [form] = RCForm.useForm();
  const { t } = useTranslation(['common']);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState<boolean>(false);
  const [registerModalVisible, setRegisterModalVisible] = useState<boolean>(false);
  const [verifySecurityVisible, setVerifySecurityVisible] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [country, setCountry] = useState<string>();
  const [loginPasswordVisible, setLoginPasswordVisible] = useState<boolean>(false);
  const loginBtnReportParamsRef = useRef<LoginBtnReportParams>();
  const { loginPCVisible } = useContext(LoginContext);
  const [prePhone, setPrePhone] = useState<string>('');
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const resetPasswordFormRef = useRef<{ resetForm: () => void }>(null);

  useEffect(() => {
    setPrePhone(localStorage.getItem('prePhone') || '');
  }, [loginPCVisible]);

  useUpdateEffect(() => {
    if (!loginPCVisible) {
      setTimeout(() => {
        form.setFieldsValue({
          verificationCode: '',
          password: '',
          phone: ['', ''],
        });
        setPrivacyChecked(false);
      }, 200);
    }
  }, [loginPCVisible]);

  useUpdateEffect(() => {
    form.setFieldsValue({
      verificationCode: '',
      password: '',
    });
    setCanSubmit(false);
  }, [loginType, tabType]);

  const isValidFormValues = (values: FormValues) => {
    const { phone } = values;
    if (!/^\d+$/.test(phone?.[1])) {
      Toast.info(t('请输入有效手机号'));
      return false;
    }
    return true;
  };

  const handleFinish = throttle(async (values: FormValues, continueNext?: boolean) => {
    try {
      if (!isValidFormValues(values)) {
        return;
      }
      if (privacyChecked === false && !continueNext) {
        showConfirmModal({
          content: <PrivacyConfirmTip t={t} />,
          okText: t('同意并继续'),
          cancelText: t('取消'),
          onConfirm: () => {
            setPrivacyChecked(true);
            handleFinish(values, true);
          },
        });
        return;
      }
      const { phone, password, verificationCode, inviteCode } = values;
      ToastLoading.show(t('加载中...'));
      const { content } = await loginByPhone({
        oauthType: loginType === 'password' ? 'PHONE_PASSWORD' : 'PHONE_VERIFICATION_CODE',
        phone: `${phone[0]} ${phone[1]}`,
        ...(loginType === 'password' ? { password } : { verificationCode }),
        inviteCode,
      });
      ToastLoading.hide();
      const { token, errorCode, errorMessage } = content;
      if (loginType === 'password' && (errorCode === 1201 || errorCode === 1210 || errorCode === 407)) {
        Toast.info(t('该账号未注册，请使用验证码登录'));
        return;
      }
      if (errorCode === 1202) {
        handleClose();
        setLoginPasswordVisible(true);
        localStorage.setItem('prePhone', phone?.join?.('&'));
        setFormValues({ ...form.getFieldsValue(), phone: `${phone[0]} ${phone[1]}`, country });
        return;
      }
      if (errorCode === 1211) {
        handleClose();
        setVerifySecurityVisible(true);
        localStorage.setItem('prePhone', phone?.join?.('&'));
        setFormValues({ ...form.getFieldsValue(), phone: `${phone[0]} ${phone[1]}`, country });
        return;
      }
      if (errorCode && errorMessage) {
        Toast.info(errorMessage);
        return;
      }
      if (!token) {
        return;
      }
      setTokenInfo(token);
      if (content?.accountData?.loginOrRegister === 'login') {
        handleLoginFinish(false, loginBtnReportParamsRef.current);
      } else {
        setSuccessVisible(true);
        handleLoginFinish(true);
      }
      localStorage.setItem('prePhone', phone?.join?.('&'));
      handleClose();
    } finally {
      ToastLoading.hide();
    }
  }, 1000);

  const checkSubmit = () => {
    const { phone: mobile, verificationCode, password } = form?.getFieldsValue() || {};
    const phone = mobile?.filter?.((item: string) => !!item);
    setCanSubmit((verificationCode || password) && phone?.length === 2);
  };

  return (
    <>
      <RCForm className={styles.form} form={form} onFinish={handleFinish}>
        <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
        <FormItem
          style={{ marginBottom: 16 }}
          name="phone"
          rules={[
            {
              validator() {
                checkSubmit();
                return Promise.resolve();
              },
            },
          ]}
        >
          <PhoneInput
            maxLength={30}
            placeholder={t('请输入手机号')}
            onChangeCountry={(value) => setCountry(value)}
            initValue={params?.phone?.join?.('&') || prePhone}
            needRequestCountries={loginPCVisible}
          />
        </FormItem>
        {loginType === 'password' && (
          <FormItem
            style={{ marginBottom: 16 }}
            name="password"
            rules={[
              {
                validator() {
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t('请输入密码')}
              maxLength={20}
              suffixCls={styles.password}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, @next/next/no-img-element
              suffix={
                <img
                  onClick={() => setShowPassword(!showPassword)}
                  src={showPassword ? '/images/login/password_open.svg' : '/images/login/password_close.svg'}
                  alt=""
                />
              }
              type={showPassword ? 'text' : 'password'}
              isPassword
            />
          </FormItem>
        )}
        {loginType === 'code' && (
          <FormItem
            style={{ marginBottom: 0 }}
            shouldUpdate={(preValues, nextValues) => {
              return preValues.phone !== nextValues.phone;
            }}
          >
            {() => {
              return (
                <FormItem
                  style={{ marginBottom: 16 }}
                  name="verificationCode"
                  rules={[
                    {
                      validator() {
                        checkSubmit();
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CodeInput
                    apiUrl="/public/authentication/verify/send_code"
                    placeholder={t('请输入验证码')}
                    maxLength={6}
                    way="LOGIN"
                    mode="Bind"
                    // className={cls(styles.input, styles.inputCode)}
                    type="MOBILE_PHONE"
                    target={
                      form.getFieldValue('phone')?.length === 2
                        ? `${form.getFieldValue('phone')[0]} ${form.getFieldValue('phone')[1]}`
                        : ''
                    }
                  />
                </FormItem>
              );
            }}
          </FormItem>
        )}
        <FormItem style={{ marginBottom: 20 }} name="inviteCode">
          <Input maxLength={50} placeholder={t('邀请码（非必填）')} />
        </FormItem>
        <FormItem style={{ marginBottom: 20 }}>
          <div className={styles.operation}>
            <span
              className={styles.item}
              onClick={() => {
                if (loginType === 'password') {
                  traceEvent({ data: { _event: 'O_AuthCodeClick' } });
                }
                setLoginType(loginType === 'password' ? 'code' : 'password');
              }}
            >
              {loginType === 'password' ? t('验证码登录') : t('密码登录')}
            </span>
            {loginType === 'password' && (
              <span
                onClick={() => {
                  traceEvent({ data: { _event: 'O_ForgetPasswordClick', tab_name: '手机号' } });
                  handleClose();
                  setResetPasswordVisible(true);
                }}
                className={styles.item}
              >
                {t('忘记密码?')}
              </span>
            )}
          </div>
        </FormItem>
        <FormItem style={{ marginBottom: 20 }}>
          <Button
            onClick={() => {
              const reportParams: LoginBtnReportParams = {
                data: {
                  _event: 'O_LoginButtonClick',
                  tab_name: '手机号',
                  login_method: loginType === 'password' ? '密码' : '验证码',
                },
              };
              loginBtnReportParamsRef.current = reportParams;
              traceEvent({
                ...reportParams,
              });
              form.submit();
            }}
            disabled={!canSubmit}
          >
            {t('登录')}
          </Button>
        </FormItem>
      </RCForm>
      <Footer
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleLoginFinish={handleLoginFinish}
        pageType="wukongsch_pc_phone_login"
        tabType={tabType}
        setTabType={setTabType}
        privacyChecked={privacyChecked}
        setPrivacyChecked={setPrivacyChecked}
      />
      <Modal
        visible={resetPasswordVisible}
        handleClose={() => {
          setTimeout(() => {
            resetPasswordFormRef.current?.resetForm?.();
          }, 200);
          setResetPasswordVisible(false);
        }}
        handleReturn={() => {
          setTimeout(() => {
            resetPasswordFormRef.current?.resetForm?.();
          }, 200);
          setResetPasswordVisible(false);
          handleOpen();
        }}
      >
        <MobileResetPasswordForm
          handleReturn={() => {
            setTimeout(() => {
              resetPasswordFormRef.current?.resetForm?.();
            }, 200);
            setResetPasswordVisible(false);
            handleOpen();
          }}
          handleLoginFinish={handleLoginFinish}
          handleOpen={() => setResetPasswordVisible(true)}
          ref={resetPasswordFormRef}
          setLoginType={setLoginType}
        />
      </Modal>
      <Modal
        visible={registerModalVisible}
        handleClose={() => setRegisterModalVisible(false)}
        handleReturn={() => {
          setRegisterModalVisible(false);
          handleOpen();
        }}
      >
        <Register
          handleOpen={() => setRegisterModalVisible(true)}
          handleClose={() => setRegisterModalVisible(false)}
          handleLoginFinish={handleLoginFinish}
        />
      </Modal>
      <Modal
        visible={verifySecurityVisible}
        handleClose={() => setVerifySecurityVisible(false)}
        handleReturn={() => {
          setVerifySecurityVisible(false);
          handleOpen();
        }}
      >
        <VerifySecurityVisibleForm
          handleClose={() => setVerifySecurityVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={formValues}
        />
      </Modal>
      <Modal
        visible={loginPasswordVisible}
        handleClose={() => setLoginPasswordVisible(false)}
        handleReturn={() => {
          setLoginPasswordVisible(false);
          checkSubmit();
          handleOpen();
        }}
      >
        <SetLoginPasswordForm
          handleClose={() => setLoginPasswordVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={formValues}
        />
      </Modal>
      <Modal visible={successVisible} handleClose={() => setSuccessVisible(false)}>
        <RegistSuccess registType="手机" handleLoginFinish={handleLoginFinish} />
      </Modal>
    </>
  );
};

export default MobileLoginForm;
