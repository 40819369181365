import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';
import Image from 'next/legacy/image';
import ReturnImg from '@public/images/common/return.svg';
import CloseImg from '@public/images/common/close.svg';
import ReactDOM from 'react-dom';
import styles from './style.module.scss';

interface Props {
  visible: boolean;
  handleClose?: () => void;
  handleReturn?: () => void;
  width?: number | string;
  contentStyle?: CSSProperties;
  children: ReactNode;
  classNames?: { mask?: string; wrapper?: string; header?: string; body?: string; footer?: string };
  modalStyles?: {
    mask?: CSSProperties;
    wrapper?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
    footer?: CSSProperties;
  };
}

interface ConfirmProps {
  content: string | ReactElement;
  okText?: string;
  cancelText?: string;
  onConfirm?: () => void;
}

const Modal: FC<Props> = ({
  visible,
  handleClose,
  handleReturn,
  children,
  width,
  contentStyle,
  classNames = {},
  modalStyles = {},
}) => {
  return (
    <Dialog
      className={styles.modal}
      visible={visible}
      animation="zoom"
      maskAnimation="fade"
      width={width || 420}
      classNames={classNames}
      styles={modalStyles}
      // destroyOnClose
    >
      {!!handleReturn && (
        <div className={styles.returnImg} onClick={handleReturn}>
          <Image src={ReturnImg} alt="返回" />
        </div>
      )}
      {!!handleClose && (
        <div className={styles.closeImg} onClick={handleClose}>
          <Image src={CloseImg} alt="关闭" />
        </div>
      )}
      <div className={styles.content} style={contentStyle}>
        {children}
      </div>
    </Dialog>
  );
};

export const showConfirmModal = ({ content, okText = '确定', cancelText = '取消', onConfirm }: ConfirmProps) => {
  const root = document.createElement('div');

  const coseModal = (
    <Modal visible={false} width={340} contentStyle={{ padding: '8px 4px 4px 4px' }}>
      {content}
      <footer className={styles.confirmFooter}>
        <div className={styles.confirmBtn} style={{ backgroundColor: '#EEE' }}>
          {cancelText}
        </div>
        <div className={styles.confirmBtn} style={{ backgroundColor: '#FF5353', color: '#fff' }}>
          {okText}
        </div>
      </footer>
    </Modal>
  );

  const handleCancel = () => {
    ReactDOM.render(coseModal, root);
    document.body.append(root);
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(root);
      document.body.removeChild(root);
    }, 300);
  };

  const handleConfirm = () => {
    onConfirm?.();
    handleCancel();
  };

  const modal = (
    <Modal visible width={340} contentStyle={{ padding: '8px 4px 4px 4px' }}>
      {content}
      <footer className={styles.confirmFooter}>
        <div onClick={handleCancel} className={styles.confirmBtn} style={{ backgroundColor: '#EEE' }}>
          {cancelText}
        </div>
        <div
          onClick={handleConfirm}
          className={styles.confirmBtn}
          style={{ backgroundColor: '#FF5353', color: '#fff' }}
        >
          {okText}
        </div>
      </footer>
    </Modal>
  );

  ReactDOM.render(modal, root);
  document.body.append(root);
};

export default Modal;
