import RCForm from 'rc-field-form';
import React, { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import classNames from 'classnames';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import Button from '../Button';
import Toast from '@/components/Toast';
import CodeInput from '../CodeInput';
import ToastLoading from '@/components/ToastLoading';
import PhoneInput from '../PhoneInput';
import Modal from '@/components/Drawer';
// eslint-disable-next-line import/no-cycle
import Register from '../Register';
import { bindOtherWay } from '@/request/login';
import { setTokenInfo } from '@/utils/token';
import RegistSuccess from '../RegistSuccess';

interface FormValues {
  phone: string[];
  verificationCode: string;
}

interface Props {
  handleLoginFinish: (notToStuCenter?: boolean) => void;
  handleClose: () => void;
  handleOpen: () => void;
  params: any;
  bindType: 'login' | 'register';
}

const BindAccountForm: FC<Props> = ({ handleLoginFinish, handleClose, handleOpen, params, bindType }) => {
  const [form] = RCForm.useForm();
  const { t } = useTranslation(['common']);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [registerModalVisible, setRegisterModalVisible] = useState<boolean>(false);
  const [country, setCountry] = useState('');
  const [needRegister, setNeedRegister] = useState<boolean>(false);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);

  const isValidFormValues = (values: FormValues) => {
    const { phone } = values;
    if (!/^\d+$/.test(phone?.[1])) {
      Toast.info(t('请输入有效手机号'));
      return false;
    }
    return true;
  };

  const handleFinish = throttle(async (values: FormValues) => {
    try {
      if (!isValidFormValues(values)) {
        return;
      }
      const { phone, verificationCode } = values;
      ToastLoading.show(t('加载中...'));
      const { oauthType = '', openId = '', unionId = '' } = params || {};
      const { content } = await bindOtherWay({
        target: `${phone[0]} ${phone[1]}`,
        verificationCode,
        oauthType,
        openId,
        unionId,
        country,
      });
      ToastLoading.hide();
      const { token } = content;
      if (!token) {
        return;
      }
      setTokenInfo(token);
      handleClose();
      if (content?.accountData?.loginOrRegister === 'register') {
        setSuccessVisible(true);
        handleLoginFinish(true);
        return;
      }
      handleLoginFinish();
    } finally {
      ToastLoading.hide();
    }
  }, 1000);

  const checkSubmit = () => {
    const phone = form?.getFieldValue?.('phone')?.filter?.((item: string) => !!item);
    const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
    setCanSubmit(formLength === 2 && phone?.length === 2);
  };

  return (
    <>
      <div className={styles.resetFormContainer}>
        <div className={styles.title}>{bindType === 'register' ? t('仅剩一步，加入悟空') : t('绑定已有账号')}</div>
        <RCForm className={styles.form} form={form} onFinish={handleFinish}>
          <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
          <FormItem
            style={{ marginBottom: 12 }}
            name="phone"
            rules={[
              {
                validator() {
                  setNeedRegister(false);
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput
              placeholder={t('请输入手机号')}
              className={classNames({ [styles.redBorder]: needRegister })}
              onChangeCountry={(value) => setCountry(value)}
            />
          </FormItem>
          {needRegister && (
            <FormItem className={styles.registInfoContainer}>
              <>
                <div className={styles.registInfo}>
                  {t('该账号未注册，')}
                  <span
                    onClick={() => {
                      handleClose();
                      setRegisterModalVisible(true);
                    }}
                  >
                    {t('点击注册')}
                  </span>
                </div>
              </>
            </FormItem>
          )}
          <FormItem
            shouldUpdate={(preValues, nextValues) => {
              return preValues.phone !== nextValues.phone;
            }}
          >
            {() => {
              return (
                <FormItem
                  name="verificationCode"
                  rules={[
                    {
                      validator() {
                        checkSubmit();
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CodeInput
                    placeholder={t('请输入验证码')}
                    maxLength={6}
                    target={
                      form.getFieldValue('phone')?.length === 2
                        ? `${form.getFieldValue('phone')[0]} ${form.getFieldValue('phone')[1]}`
                        : ''
                    }
                    type="MOBILE_PHONE"
                    way="REGISTER"
                    mode="Bind"
                    btnDisabled={needRegister}
                    onNeedRegister={(value) => setNeedRegister(value)}
                  />
                </FormItem>
              );
            }}
          </FormItem>
          <Button onClick={() => form.submit()} disabled={!canSubmit} style={{ marginTop: 64 }}>
            {t('完成')}
          </Button>
        </RCForm>
      </div>
      <Modal
        visible={registerModalVisible}
        handleClose={() => setRegisterModalVisible(false)}
        handleReturn={() => {
          setRegisterModalVisible(false);
          handleOpen();
        }}
      >
        <Register
          handleOpen={() => setRegisterModalVisible(true)}
          handleClose={() => setRegisterModalVisible(false)}
          handleLoginFinish={handleLoginFinish}
        />
      </Modal>
      <Modal visible={successVisible} handleClose={() => setSuccessVisible(false)}>
        <RegistSuccess registType="微信" handleLoginFinish={handleLoginFinish} />
      </Modal>
    </>
  );
};

export default BindAccountForm;
