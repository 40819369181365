import RCForm from 'rc-field-form';
import React, { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import Button from '../Button';
import CodeInput from '../CodeInput';
import ToastLoading from '@/components/ToastLoading';
import { registerAccountV2 } from '@/request/login';
// eslint-disable-next-line import/no-cycle
import { setTokenInfo } from '@/utils/token';
import { desensitizeAccount } from '@/utils/login';

interface FormValues {
  verificationCode: string;
}

interface Props {
  handleClose: () => void;
  handleLoginFinish: () => void;
  params: any;
}

const VerifySecurityVisibleForm: FC<Props> = ({ handleLoginFinish, handleClose, params }) => {
  const [form] = RCForm.useForm();
  const { t } = useTranslation(['common']);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const { phone = '', email = '', country = '', password = '' } = params || {};

  const handleFinish = throttle(async (values: FormValues) => {
    ToastLoading.show(t('加载中...'));
    try {
      const { content } = await registerAccountV2({
        target: email || phone || '',
        country,
        password,
        registerWay: !email ? 'PHONE' : 'EMAIL',
        verificationCode: values?.verificationCode,
      });

      const { token } = content;
      if (!token) {
        return;
      }
      setTokenInfo(token);
      handleLoginFinish();
      ToastLoading.hide();
      handleClose();
    } catch {
      ToastLoading.hide();
    }
  }, 1000);

  const checkSubmit = () => {
    const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
    setCanSubmit(formLength === 1);
  };

  return (
    <div className={styles.resetFormContainer}>
      <div className={styles.title}>{t('账号安全验证')}</div>
      <div className={styles.subTitle}>
        <span>{t('为保护您的账号安全,需要对账号进行验证,验证码已发送至')}</span>
        <span>{desensitizeAccount(email || phone)}</span>
      </div>
      <RCForm className={styles.form} form={form} onFinish={handleFinish}>
        <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
        <FormItem
          name="verificationCode"
          rules={[
            {
              validator() {
                checkSubmit();
                return Promise.resolve();
              },
            },
          ]}
        >
          <CodeInput
            placeholder={t('请输入验证码')}
            maxLength={6}
            target={email || phone || ''}
            type={email ? 'EMAIL' : 'MOBILE_PHONE'}
            way="REGISTER"
            auto
          />
        </FormItem>
        <Button onClick={() => form.submit()} disabled={!canSubmit} style={{ marginTop: 186 }}>
          {t('完成')}
        </Button>
      </RCForm>
    </div>
  );
};

export default VerifySecurityVisibleForm;
