import React, { useContext } from 'react';
import Link from 'next/link';
import cls from 'classnames';
import { MyContext } from '@/config/context-manager';

import styles from './style.module.scss';

export type Theme =
  | 'white'
  | 'red'
  | 'blue'
  | 'pewter'
  | 'gray'
  | 'yellow'
  | 'yellow2'
  | 'yellow3'
  | 'green'
  | 'purple'
  | 'mediumorchid'
  | 'orange2'
  | 'orange'
  | 'blue2'
  | 'blue3'
  | 'green2'
  | 'royalblue'
  | 'royalblue2'
  | 'royalblue3'
  | 'royalblue4'
  | 'royalblue5'
  | 'royalblue6'
  | 'pinkPurple';

interface ButtonProps {
  className?: string;
  size?: 's' | 'm' | 'l';
  theme?: Theme;
  reverse?: boolean;
  href?: string;
  target?: string;
}

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({
  className,
  size = 'm',
  theme = 'white',
  children,
  href,
  reverse,
  target,
  ...props
}) => {
  const { locale } = useContext(MyContext);
  const button = (
    <button
      className={cls(className, styles.container, styles[`size-${size}`], styles[`theme-${theme}`], {
        [styles.reverse]: reverse,
        [styles.localeEn]: locale === 'en',
      })}
      {...props}
      type="button"
    >
      {children}
    </button>
  );

  // TODO href 是/开头 加上当前语言环境前缀地址 自己落地页面noreferrer是否合理 为满足评分要求
  if (href && target) {
    return (
      <a href={href} target={target}>
        {button}
      </a>
    );
  }
  if (href) {
    return (
      <Link href={href} legacyBehavior>
        {button}
      </Link>
    );
  }

  return button;
};

export default Button;
