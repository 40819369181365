import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import React, { CSSProperties, FC } from 'react';
import Image from 'next/legacy/image';
import ReturnImg from '@public/images/common/return.svg';
import CloseImg from '@public/images/common/close.svg';
import { useUpdateEffect } from 'react-use';
import styles from './style.module.scss';
import motionProps from './motion';

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleReturn?: () => void;
  style?: CSSProperties;
}

const Modal: FC<Props> = ({ visible, handleClose, handleReturn, style = {}, children }) => {
  useUpdateEffect(() => {
    if (visible) {
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.position = '';
      document.body.style.overflowY = '';
    };
  }, [visible]);

  useUpdateEffect(() => {
    setTimeout(() => {
      const drawerContainer = document?.querySelector?.('.rc-drawer-open');
      const drawer = drawerContainer?.querySelector?.('.rc-drawer-content-wrapper') as HTMLDivElement;
      // 获取原窗口的高度
      const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const resizeCallback = () => {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
          // 当软键盘弹起，在此处操作
          drawer.classList.add(styles.panel);
        } else {
          // 当软键盘收起，在此处操作
          drawer.classList.remove(styles.panel);
        }
      };
      if (visible) {
        window.addEventListener('resize', resizeCallback);
      } else {
        window.removeEventListener('resize', resizeCallback);
      }
    }, 300);
  }, [visible]);

  return (
    <Drawer
      className={styles.modal}
      open={visible}
      placement="bottom"
      getContainer={() => document.body}
      width="100%"
      {...motionProps}
      style={style}
      // destroyOnClose
    >
      {!!handleReturn && (
        <div className={styles.returnImg} onClick={handleReturn}>
          <Image src={ReturnImg} alt="返回" />
        </div>
      )}
      <div className={styles.closeImg} onClick={handleClose}>
        <Image src={CloseImg} alt="关闭" />
      </div>
      <div className={styles.content}>{children}</div>
    </Drawer>
  );
};

export default Modal;
