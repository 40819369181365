/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import ReturnImg from '@public/images/common/return.svg';
import CloseImg from '@public/images/common/close.svg';
import styles from './style.module.scss';
import CountryData from './country-code.json';
import Input from '../Input';

const NUMBER_REG = /^\d+$/g;
const Letters = Object.keys(CountryData);

interface CountryCodeItem {
  code: string;
  zhName: string;
  enName: string;
}

export interface CountryCodePanelProps {
  value?: string;
  children?(args: { showPanel(): void; value?: string }): React.ReactElement;
  onChange?(value: CountryCodeItem): void;
  onVisibleChange?(visible: boolean): void;
  language?: 'zh' | 'en';
}

type CountryDataType = Record<string, Array<CountryCodeItem>>;

const CountryCodePanel = ({ children, value, onChange, onVisibleChange, language }: CountryCodePanelProps) => {
  const [innerVisible, setVisible] = useState(false);
  const [touchingBar, setTouchingBar] = useState(false);
  const letterContainerRef = useRef<HTMLDivElement>(null);
  const anchorsRef = useRef<Record<string, HTMLDivElement>>({});
  const codeItemRef = useRef<Record<string, HTMLDivElement>>({});
  const [innerValue, setValue] = useState(value);
  const touchTemp = useRef([0, 0]);
  const { t } = useTranslation(['common']);

  const [letters, setLetters] = useState(Object.keys(CountryData));
  const [countryData, setCountryData] = useState<CountryDataType>(CountryData);
  const pannelRef = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      // 获取原窗口的高度
      const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const resizeCallback = () => {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
          // 当软键盘弹起，在此处操作
          if (pannelRef.current?.style) {
            pannelRef.current.style.top = '10px';
          }
        } else {
          // 当软键盘收起，在此处操作
          // eslint-disable-next-line no-lonely-if
          if (pannelRef.current?.style) {
            pannelRef.current.style.top = 'auto';
          }
        }
      };
      if (innerVisible) {
        window.addEventListener('resize', resizeCallback);
      } else {
        window.removeEventListener('resize', resizeCallback);
      }
    }, 300);
  }, [innerVisible]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    setLetters(Letters);
    setCountryData(CountryData);
  }, [innerVisible, language]);

  useEffect(() => {
    onVisibleChange?.(innerVisible);
    document.body.style.overflow = innerVisible ? 'hidden' : '';
  }, [innerVisible]);

  useEffect(() => {
    if (innerVisible && innerValue) {
      codeItemRef.current[innerValue]?.scrollIntoView({ block: 'center' });
    }
  }, [innerVisible]);

  useEffect(() => {
    const handleTouchEnd = () => {
      setTouchingBar(false);
    };

    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const handleStartFindLetter: React.TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchingBar(true);
    const { currentTarget } = e;
    const { top } = currentTarget.getBoundingClientRect();
    const letterDOMs = Array.from(currentTarget.children);
    const itemHeight = letterDOMs.pop()!.clientHeight;
    const paddingTop = parseInt(getComputedStyle(currentTarget).paddingTop, 10);
    touchTemp.current = [top + paddingTop, itemHeight];
  };

  const scrollToAnchorByLetter = async (letter: string) => {
    anchorsRef.current[letter]?.scrollIntoView();
  };

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
    const { touches } = e;

    const [offsetTop, itemHeight] = touchTemp.current;

    const touchOffsetTop = touches[0].clientY - offsetTop;

    if (touchOffsetTop > 0) {
      const index = Math.ceil(touchOffsetTop / itemHeight) - 1;

      scrollToAnchorByLetter(letters[index]);
    }
  };

  const child = children?.({ showPanel: () => setVisible(true), value: innerValue });

  const search = (searchText: string, type: 'enName' | 'code') => {
    if (!searchText) {
      setLetters(Letters);
      setCountryData(CountryData);
      return;
    }
    let text = searchText;
    if (type === 'enName') {
      text = searchText.toLocaleLowerCase();
    }
    const searchLetters: string[] = [];
    const searchCountryData: any = {};
    Letters.forEach((letter: string) => {
      (CountryData as any)?.[letter]?.forEach((item: any) => {
        const countryValue = type === 'enName' ? item?.enName?.toLocaleLowerCase() : item?.code;
        if (countryValue?.includes(text)) {
          if (!searchLetters.includes(letter)) {
            searchLetters.push(letter);
          }
          if (searchCountryData?.[letter]) {
            searchCountryData[letter].push(item);
          } else {
            searchCountryData[letter] = [item];
          }
        }
      });
    });
    if (searchLetters?.length) {
      setLetters(searchLetters);
      setCountryData(searchCountryData);
    }
  };

  return (
    <>
      {child}
      {ReactDOM.createPortal(
        <>
          <CSSTransition
            unmountOnExit
            timeout={300}
            in={innerVisible}
            classNames={{
              enter: styles.slideIn,
              enterActive: styles.slideInActive,
              exitActive: styles.slideOutActive,
            }}
          >
            <div className={styles.panel} ref={pannelRef}>
              <div className={styles.titleBox}>
                <div className={styles.returnImg} onClick={() => setVisible?.(false)}>
                  <Image src={ReturnImg} alt="返回" />
                </div>

                <div className={styles.closeImg} onClick={() => setVisible?.(false)}>
                  <Image src={CloseImg} alt="关闭" />
                </div>
              </div>
              <div className={styles.tipBox}>
                <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.01" width="16" height="16" fill="white" />
                  <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#FF5353" />
                  <line
                    x1="8.00078"
                    y1="4.7"
                    x2="8.00078"
                    y2="7.8"
                    stroke="#FF5353"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                  <circle cx="7.99922" cy="10.8" r="0.8" fill="#FF5353" />
                </svg>
                <span>{t('暂不支持中国大陆手机号')}</span>
              </div>
              {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
              <div
                className={styles.searchInputContainer}
                role="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <Input
                  placeholder={t('请输入国家或地区')}
                  className={styles.searchInput}
                  onChange={(e) => {
                    const { value: searchText } = e.currentTarget;
                    if (searchText.match(NUMBER_REG) || searchText.trim().startsWith('+')) {
                      search(searchText, 'code');
                    } else {
                      search(searchText, 'enName');
                    }
                  }}
                  prefix={
                    <div className={styles.searchIcon}>
                      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.01" width="18" height="18" fill="#D8D8D8" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.3078 11.6538C11.6323 11.3294 12.1583 11.3294 12.4827 11.6538L15.4199 14.5911C15.7444 14.9155 15.7444 15.4415 15.4199 15.7659C15.0955 16.0904 14.5695 16.0904 14.245 15.7659L11.3078 12.8287C10.9834 12.5043 10.9834 11.9783 11.3078 11.6538Z"
                          fill="#D1D1D1"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.13965 5.48624C3.43637 7.18953 3.43637 9.9511 5.13965 11.6544C6.84294 13.3577 9.60451 13.3577 11.3078 11.6544C13.0111 9.9511 13.0111 7.18953 11.3078 5.48624C9.60451 3.78295 6.84294 3.78295 5.13965 5.48624ZM3.96477 12.8293C1.61261 10.4771 1.61261 6.66351 3.96477 4.31135C6.31693 1.9592 10.1305 1.9592 12.4827 4.31135C14.8348 6.66351 14.8348 10.4771 12.4827 12.8293C10.1305 15.1814 6.31693 15.1814 3.96477 12.8293Z"
                          fill="#D1D1D1"
                        />
                      </svg>
                    </div>
                  }
                />
              </div>

              <div className={classNames(styles.countryCodes, touchingBar && styles.touchingStatic)}>
                {letters.map((letter, i) => (
                  <div key={i} className={styles.codeGroup}>
                    <div
                      className={styles.letter}
                      tabIndex={-1}
                      ref={(dom) => {
                        if (dom) {
                          anchorsRef.current[letter] = dom;
                        }
                      }}
                    >
                      <span>{letter}</span>
                    </div>
                    {countryData?.[letter].map((item: any, index: number) => (
                      <div
                        className={classNames(styles.codeItem, innerValue === item.code && styles.selected)}
                        key={index}
                        onClick={() => {
                          setValue(item.code);
                          onChange?.(item);
                          setVisible(false);
                        }}
                        ref={(codeDom) => {
                          if (codeDom) {
                            codeItemRef.current[item.code] = codeDom;
                          }
                        }}
                      >
                        {item.enName}
                        <div className={styles.code}>{item.code}</div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div
                className={classNames(styles.touchBar, touchingBar && styles.touching)}
                onTouchStart={handleStartFindLetter}
                onTouchMove={handleTouchMove}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                ref={letterContainerRef}
              >
                {letters.map((letter) => (
                  <div
                    className={styles.letterCode}
                    key={letter}
                    onClick={() => {
                      setTimeout(() => {
                        scrollToAnchorByLetter(letter);
                      });
                    }}
                    onTouchStart={() => {
                      setTimeout(() => {
                        scrollToAnchorByLetter(letter);
                      });
                    }}
                  >
                    {letter}
                  </div>
                ))}
              </div>
            </div>
          </CSSTransition>
        </>,
        document.body,
      )}
    </>
  );
};

export default CountryCodePanel;
