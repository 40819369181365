/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useRef } from 'react';
import RCForm from 'rc-field-form';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
// import Lottie from 'lottie-web';
import { traceEvent } from '@wk/wk-gatherer';
import Toast from '@/components/Toast';
import { EMAIL_REG, REPORT_PAGE_NAME_OFFICIAL_WEBSITE, LEAD_CHINESE_ZH } from '@/utils/constants';
import LoadingModal from '@/components/Form/Loading';
import Input from '@/components/Form/Input';
import { MyContext } from '@/config/context-manager';
import { report } from '@/utils/buryingPoint';
import styles from './style.module.scss';
import Button from '@/components/Button';
import { subscribe } from '@/request/commonGatWay';
import FormItem from '@/components/Form/FormItem';

// const Lottie = lazy(() => import('lottie-web'));

const pageName = REPORT_PAGE_NAME_OFFICIAL_WEBSITE;

const leadId = LEAD_CHINESE_ZH;

const reportParams = {
  l: leadId,
  subject: 'CHINESE',
  landingPageVersion: pageName,
  pv: null,
  uv: null,
};

const Form = () => {
  const [form] = RCForm.useForm();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['common']);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [submitSuccessMail, setSubmitSuccessMail] = useState('');
  const { locale } = useContext(MyContext);

  const handleShowSuccess = () => {
    if (ref.current) {
      import('lottie-web').then((Lottie) => {
        Lottie.default.loadAnimation({
          container: ref.current as Element,
          loop: false,
          path: '/json/iconSubscriptionSuccess.json',
        });
      });
    }
  };

  const onFinish = (value: { email: string }) => {
    if (btnLoading) {
      return;
    }
    if (submitSuccessMail === value?.email) {
      Toast.success(t('提醒-提交'));
      return;
    }
    report(
      `g_OfficalWebsite_FooterSubscribe_Click`,
      {
        ...reportParams,
        event: `g_OfficalWebsite_FooterSubscribe_Click`,
        originalUrl: window.location.href,
      },
      true,
    );
    traceEvent({
      data: {
        _event: 'O_SubscriptionClick',
        button_name: t('订阅-按钮'),
        InputBox_mail: value?.email,
        page_position: window.location.href,
      },
    });
    LoadingModal.show('Loading...');
    if (value?.email) {
      setBtnLoading(true);
      subscribe({ email: value?.email })
        .then(() => {
          // 成功
          setSubmitSuccess(true);
          setSubmitSuccessMail(value?.email);
          handleShowSuccess();
        })
        .finally(() => {
          LoadingModal.hide();
          setBtnLoading(false);
        });
    }
  };

  const onChange = () => {
    if (submitSuccess) {
      setSubmitSuccess(false);
    }
  };

  // useEffect(() => {

  // }, []);

  const btnNode = (
    <Button className={styles.button} theme="red" type="submit" onClick={form.submit}>
      <div className={styles.successIcon} ref={ref} />
      {!submitSuccess && <div>{t('订阅-按钮')}</div>}
    </Button>
  );

  return (
    <div
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <RCForm className={styles.form} form={form} onFinish={onFinish} onChange={onChange}>
        <FormItem
          name="email"
          label={t('订阅-标题')}
          labelClassName={styles.formItemLabel}
          className={styles.formItem}
          errClassName={styles.formItemErr}
          rules={[
            {
              validator(_, value) {
                // 不能全是空格 或者为空
                const length = value?.split(' ')?.join('')?.length;
                if (!length) {
                  return Promise.reject(new Error(t('订阅-验证-空')));
                }
                if (!EMAIL_REG.test(value)) {
                  return Promise.reject(new Error(t('订阅-验证')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            className={cls(styles.input)}
            placeholder={t('订阅-邮箱-占位')}
            maxLength={80}
            name="mail"
            suffix={btnNode}
            suffixCls={styles.buttonInput}
          />
        </FormItem>
      </RCForm>
    </div>
  );
};

export default Form;
