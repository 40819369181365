import RCForm from 'rc-field-form';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import classNames from 'classnames';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import Input from '../Input';
import Button from '../Button';
import Toast from '@/components/Toast';
import CodeInput from '../CodeInput';
import ToastLoading from '@/components/ToastLoading';
import { forgetPasswordV2 } from '@/request/login';
// eslint-disable-next-line import/no-cycle
import Register from '../Register';
import Modal from '@/components/Modal';

interface FormValues {
  email: string;
  password: string;
  confirmVerificationCode: string;
  verificationCode: string;
}

interface Props {
  handleReturn: () => void;
  handleLoginFinish: () => void;
  handleOpen: () => void;
  setLoginType: (loginType: 'password' | 'code') => void;
}

const EmailResetPasswordForm = forwardRef<{ resetForm: () => void }, Props>(
  ({ handleReturn, handleLoginFinish, handleOpen, setLoginType }, ref) => {
    const [form] = RCForm.useForm();
    const { t, i18n } = useTranslation(['common']);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showComfirmPassword, setShowComfirmPassword] = useState<boolean>(false);
    const [needRegister, setNeedRegister] = useState<boolean>(false);
    const [registerModalVisible, setRegisterModalVisible] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        form.resetFields();
        setNeedRegister(false);
      },
    }));

    const isValidFormValues = (values: FormValues) => {
      const { email, password, confirmVerificationCode } = values;
      if (!/^[^@]{1,}@[^@]{1,}$/.test(email)) {
        Toast.info(t('邮箱格式错误'));
        return false;
      }
      if (!/^\S*(?=\S{8,})(?=\S*\d)(?=\S*[a-z])\S*$/.test(password)) {
        Toast.info(t('请设置8-20个字符做为密码，密码必须包含字母和数字'));
        return false;
      }
      if (password !== confirmVerificationCode) {
        Toast.info(t('两次密码不一致'));
        return false;
      }
      return true;
    };

    const handleFinish = throttle(async (values: FormValues) => {
      try {
        if (!isValidFormValues(values)) {
          return;
        }
        const { email, password, verificationCode } = values;
        ToastLoading.show(t('加载中...'));
        const { content } = await forgetPasswordV2({
          target: email ?? '',
          password: password ?? '',
          verificationCode: verificationCode ?? '',
          verificationType: 'EMAIL',
        });
        const { errorCode, errorMessage } = content;
        if (errorCode && errorMessage) {
          Toast.info(errorMessage);
          ToastLoading.hide();
          return;
        }
        Toast.info(t('密码重置成功'));
        ToastLoading.hide();
        handleReturn();
      } finally {
        ToastLoading.hide();
      }
    }, 1000);

    const checkSubmit = () => {
      const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
      setCanSubmit(formLength === 4);
    };

    return (
      <div className={styles.resetFormContainer}>
        <div className={styles.title}>{t('重置密码')}</div>
        <RCForm className={styles.form} form={form} onFinish={handleFinish}>
          <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
          <FormItem
            style={{ marginBottom: 16 }}
            name="email"
            rules={[
              {
                validator() {
                  setNeedRegister(false);
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              maxLength={50}
              placeholder={t('请输入邮箱')}
              className={classNames({ [styles.redBorder]: needRegister })}
            />
          </FormItem>
          {needRegister && (
            <FormItem style={{ marginBottom: 16 }} className={styles.registInfoContainer}>
              <>
                {i18n.language === 'en' ? (
                  <div className={styles.registInfo}>
                    Please{' '}
                    <span
                      onClick={() => {
                        setLoginType('code');
                        handleReturn();
                      }}
                    >
                      log in
                    </span>{' '}
                    using a verification code.
                  </div>
                ) : (
                  <div className={styles.registInfo}>
                    账号未注册，请使用验证码
                    <span
                      onClick={() => {
                        setLoginType('code');
                        handleReturn();
                      }}
                    >
                      登录
                    </span>
                  </div>
                )}
              </>
            </FormItem>
          )}
          <FormItem
            style={{ marginBottom: 0 }}
            shouldUpdate={(preValues, nextValues) => {
              return preValues.email !== nextValues.email;
            }}
          >
            {() => {
              return (
                <FormItem
                  style={{ marginBottom: 16 }}
                  name="verificationCode"
                  rules={[
                    {
                      validator() {
                        checkSubmit();
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CodeInput
                    apiUrl="/public/authentication/verify/send_code/login"
                    placeholder={t('请输入验证码')}
                    maxLength={6}
                    target={form.getFieldValue('email') || ''}
                    way="LOGIN"
                    type="EMAIL"
                    special
                    btnDisabled={needRegister}
                    onNeedRegister={(value) => setNeedRegister(value)}
                  />
                </FormItem>
              );
            }}
          </FormItem>
          <FormItem
            style={{ marginBottom: 16 }}
            name="password"
            rules={[
              {
                validator() {
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t('8-20位密码，必须包含字母、数字')}
              maxLength={20}
              suffixCls={styles.password}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, @next/next/no-img-element
              suffix={
                <img
                  onClick={() => setShowPassword(!showPassword)}
                  src={showPassword ? '/images/login/password_open.svg' : '/images/login/password_close.svg'}
                  alt=""
                />
              }
              type={showPassword ? 'text' : 'password'}
              isPassword
            />
          </FormItem>
          <FormItem
            style={{ marginBottom: 16 }}
            name="confirmVerificationCode"
            rules={[
              {
                validator() {
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t('请确认密码')}
              maxLength={20}
              suffixCls={styles.password}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, @next/next/no-img-element
              suffix={
                <img
                  onClick={() => setShowComfirmPassword(!showComfirmPassword)}
                  src={showComfirmPassword ? '/images/login/password_open.svg' : '/images/login/password_close.svg'}
                  alt=""
                />
              }
              type={showComfirmPassword ? 'text' : 'password'}
              isPassword
            />
          </FormItem>
          <Button onClick={() => form.submit()} disabled={!canSubmit} style={{ marginTop: 62 }}>
            {t('完成')}
          </Button>
        </RCForm>
        <Modal
          visible={registerModalVisible}
          handleClose={() => setRegisterModalVisible(false)}
          handleReturn={() => {
            setRegisterModalVisible(false);
            handleOpen();
          }}
        >
          <Register
            handleOpen={() => setRegisterModalVisible(true)}
            handleClose={() => setRegisterModalVisible(false)}
            handleLoginFinish={handleLoginFinish}
          />
        </Modal>
      </div>
    );
  },
);

export default EmailResetPasswordForm;
