import RCForm from 'rc-field-form';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import classNames from 'classnames';
import { traceEvent } from '@wk/wk-gatherer';
import { useUpdateEffect } from 'react-use';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import Input from '../Input';
import Button from '../Button';
// eslint-disable-next-line import/no-cycle
import Footer from '../Footer';
import Toast from '@/components/Toast';
import { loginByPhone } from '@/request/login';
import { setTokenInfo } from '@/utils/token';
import CodeInput from '../CodeInput';
import ToastLoading from '@/components/ToastLoading';
import Modal from '@/components/Drawer';
// eslint-disable-next-line import/no-cycle
import EmailResetPasswordForm from '../EmailResetPasswordForm';
// eslint-disable-next-line import/no-cycle
import Register from '../Register';
import VerifySecurityVisibleForm from '../VerifySecurityVisibleForm';
import SetLoginPasswordForm from '../SetLoginPasswordForm';
import { LoginBtnReportParams } from '@/request/interface/login';
import LoginContext from '@/context/LoginContext';

interface FormValues {
  email: string;
  password: string;
  verificationCode: string;
}

interface Props {
  handleLoginFinish: (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => void;
  handleClose: () => void;
  handleOpen: () => void;
  loginType: 'password' | 'code';
  setLoginType: (loginType: 'password' | 'code') => void;
  tabType: 'mobile' | 'email';
}

const EmailLoginForm: FC<Props> = ({
  handleLoginFinish,
  handleClose,
  handleOpen,
  loginType,
  setLoginType,
  tabType,
}) => {
  const [form] = RCForm.useForm();
  const { t } = useTranslation(['common']);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState<boolean>(false);
  const [needRegister, setNeedRegister] = useState<boolean>(false);
  const [registerModalVisible, setRegisterModalVisible] = useState<boolean>(false);
  const [verifySecurityVisible, setVerifySecurityVisible] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [loginPasswordVisible, setLoginPasswordVisible] = useState<boolean>(false);
  const loginBtnReportParamsRef = useRef<LoginBtnReportParams>();
  const { loginMobileVisible } = useContext(LoginContext);
  const resetPasswordFormRef = useRef<{ resetForm: () => void }>(null);

  useEffect(() => {
    form.setFieldsValue({ email: localStorage.getItem('preEmail') || '' });
  }, []);

  useUpdateEffect(() => {
    if (!loginMobileVisible) {
      setTimeout(() => {
        form.setFieldsValue({
          verificationCode: '',
          password: '',
          email: localStorage.getItem('preEmail') || '',
        });
        setNeedRegister(false);
      }, 200);
    }
  }, [loginMobileVisible]);

  useUpdateEffect(() => {
    form.setFieldsValue({
      verificationCode: '',
      password: '',
    });
    setCanSubmit(false);
  }, [loginType, tabType]);

  const isValidFormValues = (values: FormValues) => {
    const { email } = values;
    if (!/^[^@]{1,}@[^@]{1,}$/.test(email)) {
      Toast.info(t('邮箱格式错误'));
      return false;
    }
    return true;
  };

  const handleFinish = throttle(async (values: FormValues) => {
    try {
      if (!isValidFormValues(values)) {
        return;
      }
      const { email, password, verificationCode } = values;
      ToastLoading.show(t('加载中...'));
      const { content } = await loginByPhone({
        oauthType: loginType === 'password' ? 'EMAIL_PASSWORD' : 'EMAIL_VERIFICATION_CODE',
        email,
        ...(loginType === 'password' ? { password } : { verificationCode }),
      });
      ToastLoading.hide();
      const { token, errorCode, errorMessage } = content;
      if (errorCode === 1201 || errorCode === 1210 || errorCode === 407) {
        setNeedRegister(true);
        return;
      }
      if (errorCode === 1202) {
        handleClose();
        setLoginPasswordVisible(true);
        setFormValues(form.getFieldsValue());
        return;
      }
      if (errorCode === 1211) {
        handleClose();
        setVerifySecurityVisible(true);
        setFormValues(form.getFieldsValue());
        return;
      }
      if (errorCode && errorMessage) {
        Toast.info(errorMessage);
        return;
      }
      if (!token) {
        return;
      }
      setTokenInfo(token);
      handleLoginFinish(false, loginBtnReportParamsRef.current);
      localStorage.setItem('preEmail', email);
      handleClose();
    } finally {
      ToastLoading.hide();
    }
  }, 1000);

  const checkSubmit = () => {
    const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
    setCanSubmit(formLength === 2);
  };

  return (
    <>
      <RCForm className={styles.form} form={form} onFinish={handleFinish}>
        <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
        <FormItem
          style={{ marginBottom: 12 }}
          name="email"
          rules={[
            {
              validator() {
                setNeedRegister(false);
                checkSubmit();
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            maxLength={50}
            placeholder={t('请输入邮箱')}
            className={classNames({ [styles.redBorder]: needRegister })}
          />
        </FormItem>
        {needRegister && (
          <FormItem className={styles.registInfoContainer}>
            <>
              <div className={styles.registInfo}>
                {t('该账号未注册，')}
                <span
                  onClick={() => {
                    handleClose();
                    setRegisterModalVisible(true);
                  }}
                >
                  {t('点击注册')}
                </span>
              </div>
            </>
          </FormItem>
        )}
        {loginType === 'password' && (
          <FormItem
            style={{ marginBottom: 12 }}
            name="password"
            rules={[
              {
                validator() {
                  checkSubmit();
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder={t('请输入密码')}
              maxLength={20}
              suffixCls={styles.password}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, @next/next/no-img-element
              suffix={
                <img
                  onClick={() => setShowPassword(!showPassword)}
                  src={showPassword ? '/images/login/password_open.svg' : '/images/login/password_close.svg'}
                  alt=""
                />
              }
              type={showPassword ? 'text' : 'password'}
            />
          </FormItem>
        )}
        {loginType === 'code' && (
          <FormItem
            style={{ marginBottom: 0 }}
            shouldUpdate={(preValues, nextValues) => {
              return preValues.email !== nextValues.email;
            }}
          >
            {() => {
              return (
                <FormItem
                  name="verificationCode"
                  style={{ marginBottom: 12 }}
                  rules={[
                    {
                      validator() {
                        checkSubmit();
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CodeInput
                    apiUrl="/public/authentication/verify/send_code/login"
                    placeholder={t('请输入验证码')}
                    maxLength={6}
                    way="LOGIN"
                    mode="Bind"
                    // className={cls(styles.input, styles.inputCode)}
                    type="EMAIL"
                    target={form.getFieldValue('email') || ''}
                    btnDisabled={needRegister}
                    onNeedRegister={(value) => setNeedRegister(value)}
                  />
                </FormItem>
              );
            }}
          </FormItem>
        )}
        <FormItem style={{ marginBottom: 16 }}>
          <div className={styles.operation}>
            <span
              className={styles.item}
              onClick={() => {
                if (loginType === 'password') {
                  traceEvent({ ak: 'official_mobile', data: { _event: 'O_AuthCodeClick' } });
                }
                setLoginType(loginType === 'password' ? 'code' : 'password');
              }}
            >
              {loginType === 'password' ? t('验证码登录') : t('密码登录')}
            </span>
            {loginType === 'password' && (
              <span
                onClick={() => {
                  traceEvent({ ak: 'official_mobile', data: { _event: 'O_ForgetPasswordClick', tab_name: '邮箱' } });
                  handleClose();
                  setResetPasswordVisible(true);
                }}
                className={styles.item}
              >
                {t('忘记密码?')}
              </span>
            )}
          </div>
        </FormItem>
        <FormItem style={{ marginBottom: 16 }}>
          <Button
            onClick={() => {
              const reportParams: LoginBtnReportParams = {
                data: {
                  _event: 'O_LoginButtonClick',
                  tab_name: '邮箱',
                  login_method: loginType === 'password' ? '密码' : '验证码',
                },
              };
              loginBtnReportParamsRef.current = reportParams;
              traceEvent({
                ...reportParams,
              });
              form.submit();
            }}
            disabled={!canSubmit}
            style={{ position: 'static', width: '100%' }}
          >
            {t('登录')}
          </Button>
        </FormItem>
      </RCForm>
      <Footer handleOpen={handleOpen} handleClose={handleClose} handleLoginFinish={handleLoginFinish} />
      <Modal
        visible={resetPasswordVisible}
        handleClose={() => {
          setTimeout(() => {
            resetPasswordFormRef.current?.resetForm?.();
          }, 200);
          setResetPasswordVisible(false);
        }}
        handleReturn={() => {
          setTimeout(() => {
            resetPasswordFormRef.current?.resetForm?.();
          }, 200);
          setResetPasswordVisible(false);
          handleOpen();
        }}
      >
        <EmailResetPasswordForm
          handleReturn={() => {
            setResetPasswordVisible(false);
            handleOpen();
          }}
          handleLoginFinish={handleLoginFinish}
          handleOpen={() => setResetPasswordVisible(true)}
          handleClose={() => {
            setTimeout(() => {
              resetPasswordFormRef.current?.resetForm?.();
            }, 200);
            setResetPasswordVisible(false);
          }}
          ref={resetPasswordFormRef}
        />
      </Modal>
      <Modal
        visible={registerModalVisible}
        handleClose={() => setRegisterModalVisible(false)}
        handleReturn={() => {
          setRegisterModalVisible(false);
          handleOpen();
        }}
      >
        <Register
          handleOpen={() => setRegisterModalVisible(true)}
          handleClose={() => setRegisterModalVisible(false)}
          handleLoginFinish={handleLoginFinish}
        />
      </Modal>
      <Modal
        visible={verifySecurityVisible}
        handleClose={() => setVerifySecurityVisible(false)}
        handleReturn={() => {
          setVerifySecurityVisible(false);
          handleOpen();
        }}
      >
        <VerifySecurityVisibleForm
          handleClose={() => setVerifySecurityVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={formValues}
        />
      </Modal>
      <Modal
        visible={loginPasswordVisible}
        handleClose={() => setLoginPasswordVisible(false)}
        handleReturn={() => {
          setLoginPasswordVisible(false);
          handleOpen();
        }}
      >
        <SetLoginPasswordForm
          handleClose={() => setLoginPasswordVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={formValues}
        />
      </Modal>
    </>
  );
};

export default EmailLoginForm;
