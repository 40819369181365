export interface PathsItemProps {
  label: string;
  href?: string;
  ga?: string;
  fn?: () => void;
}

type Paths = {
  children: PathsItemProps[];
}[];

const generatePaths = (t: (name: string) => string): Paths => {
  return [
    {
      children: [
        { label: t('中文课程'), href: '/chinese/', ga: 'ChineseLessons' },
        { label: t('数学课程'), href: '/math/', ga: 'MathLessons' },
        { label: t('英文课程'), href: '/english/', ga: 'EnglishLessons' },
        { label: t('悟空师资'), href: '/teachers/', ga: 'Faculty' },
        { label: t('下载中心'), href: '/download/', ga: 'Download' },
        { label: t('常见问题'), href: '/faq/', ga: 'FAQS' },
      ],
    },
    {
      children: [
        { label: t('关于我们'), href: '/aboutus/', ga: 'AboutUs' },
        { label: t('联系我们'), href: '/aboutus/#contant', ga: 'ContactUs' },
        { label: t('加入我们'), href: '/joinus/#wukong-zhaopin', ga: 'JoinUs' },
        { label: t('商务合作'), href: '/joinus/#wukong-cooperation', ga: 'BusinessCooperation' },
        { label: t('服务条款'), href: '/policy/', ga: 'PrivacyPolicy' },
        { label: t('cookie协议'), href: '/cookie-policy/', ga: 'cookiePolicy' },
      ],
    },
  ];
};

export default generatePaths;
