export default {
  // openGraph: {
  //   type: 'website',
  //   locale: 'en_IE',
  //   url: 'https://www.url.ie/',
  //   site_name: 'SiteName',
  // },
  // twitter: {
  //   handle: '@handle',
  //   site: '@site',
  //   cardType: 'summary_large_image',
  // },
  openGraph: {
    type: 'website',
    // url: 'https://www.wukongsch.com',
    // title: 'Online Chinese & Math Class for Students Aged 3-18 | 在线中文&数学课',
    // description: 'Explore more to get  information about online Mandarin&Math class for kids.',
    site_name: 'WuKong Education',
    // images: [
    //   {
    //     url: 'https://cdnwukong.com/images/public/og/offical-site-1.png',
    //     width: 300,
    //     height: 300,
    //     alt: '悟空中文',
    //   },
    // ],
  },
  twitter: {
    handle: '@handle',
    // 卡片页脚中所使用网站的 @username。
    site: '@site',
    cardType: 'summary',
  },
};
