import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import LogoutImg from '@public/images/login/logout_black.svg';
import classNames from 'classnames';
import { traceEvent } from '@wk/wk-gatherer';
import styles from './style.module.scss';
import { StudentInfo } from '@/request/interface/login';
import { getStuCenterUrl } from '@/utils/utils';

interface Props {
  logout: () => void;
  handleClose: () => void;
  student: StudentInfo | undefined;
}

const Logout: FC<Props> = ({ logout, handleClose, student }) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.logout}>
      <div className={styles.avatarContainer}>
        <div className={styles.avatarContent}>
          <div className={styles.avatar}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={student?.data?.HeadImageUrl || '/images/login/avatar.svg'} />
          </div>
        </div>
        <span>{student?.fullName || 'Student'}</span>
      </div>
      <div
        onClick={() => {
          traceEvent({ ak: 'official_mobile', data: { _event: 'O_OfficialWebGoCenter', account_id: student?.uuid } });
          window.open(getStuCenterUrl());
        }}
        className={classNames(styles.item, styles.stuCenter)}
      >
        <div className={styles.itemIcon} style={{ transform: 'rotate(180deg)' }}>
          <Image src={LogoutImg} alt="logout_black" layout="responsive" />
        </div>
        <span>{t('学员中心')}</span>
      </div>
      <div
        onClick={() => {
          logout();
          handleClose();
        }}
        className={styles.item}
      >
        <div className={styles.itemIcon}>
          <Image src={LogoutImg} alt="logout_black" layout="responsive" />
        </div>
        <span>{t('退出登录')}</span>
      </div>
    </div>
  );
};

export default Logout;
