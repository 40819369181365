import Cookie from 'js-cookie';
import CacheStorage from '@/utils/cacheStorage';
import { EDU_URL, SCH_URL, TOKEN } from '@/utils/constants';
// eslint-disable-next-line import/no-cycle
import { isProduction } from './utils';

export const getTokenInfo = () => {
  return CacheStorage.getItem(TOKEN);
};

export const setSameDomain = (token?: string) => {
  // 线上环境针对国内用户，格外提供一个 www-intl.wukongedu.net 的官网域名用于登录，然后跳转到 student.wukongedu.net 这个域名
  const domain = !isProduction() || window.location.hostname.includes('wukongedu.net') ? EDU_URL : SCH_URL;
  Cookie.set(TOKEN, token || getTokenInfo(), {
    domain,
    path: '/',
    expires: 31,
  });
};

export const setTokenInfo = (token: string) => {
  // CacheStorage.setItem(TOKEN, token);
  setSameDomain(token);
};

export const removeTokenInfo = () => {
  CacheStorage.removeItem(TOKEN);
  Cookie.remove(TOKEN, {
    domain: !isProduction() ? EDU_URL : SCH_URL,
    path: '/',
  });
};

export const hasTokenInfo = () => {
  const token = CacheStorage.getItem(TOKEN);

  return !!token;
};
