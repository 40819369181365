/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import cls from 'classnames';
import Select from 'react-select';
import qs from 'query-string';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import { traceEvent, getBasicInfo } from '@wk/wk-gatherer';
import { useUpdateEffect, useLocation } from 'react-use';
import Input from './Input';
import PhoneInput from './PhoneInput';
import Toast from './Toast';
import LoadingModal from './Loading';
import Success from './Success';
import { MyContext } from '@/config/context-manager';
import {
  AGE_CHINESE_ZH,
  AGE_CHINESE_EN,
  AGE_MATH_ZH,
  AGE_MATH_EN,
  SUBJECT_ZH,
  SUBJECT_EN,
  AGE_ENGLISH_EN,
  AGE_ENGLISH_ZH,
} from '../../utils/formOptions';
import { messageIsRepeat } from '../../utils/informationJudgment';
import { createLeadNoRemind } from '../../request/nav';
import { report } from '../../utils/buryingPoint';
import styles from './style.module.scss';
import VerifyModal from '@/components/VerifyModal';
import VerifyDrawer from '@/components/VerifyDrawer';
import { getThemeColor } from '@/utils/landing';
import useGTM from '@/hooks/useGtag';

interface VerificationInfo {
  verificationCode?: string;
  loadingModal?: any;
}

const optionRules = [
  {
    match: (subject: string, language: string) => subject === 'Math' && language === 'en',
    action: () => AGE_MATH_EN,
  },
  {
    match: (subject: string, language: string) => subject === 'Math' && language === 'zh',
    action: () => AGE_MATH_ZH,
  },
  {
    match: (subject: string, language: string) => subject === 'Chinese' && language === 'en',
    action: () => AGE_CHINESE_EN,
  },
  {
    match: (subject: string, language: string) => subject === 'Chinese' && language === 'zh',
    action: () => AGE_CHINESE_ZH,
  },
  {
    match: (subject: string, language: string) => subject === 'English' && language === 'en',
    action: () => AGE_ENGLISH_EN,
  },
  {
    match: (subject: string, language: string) => subject === 'English' && language === 'zh',
    action: () => AGE_ENGLISH_ZH,
  },
];

interface LeadIdMapping {
  [key: string]: {
    [subKey: string]: string;
  };
}

const leadIdMap: LeadIdMapping = {
  default: {
    Chinese: '8ac58659-6601-4ba7-af9e-62346a7b06cd',
    English: 'ba633a68-677b-4bdf-b97c-0fb00dfa7072',
    Math: '75bb3907-0c60-4a11-a832-52f69cb7173f',
  },
  en: {
    Chinese: 'b6711d4e-6de4-4465-a4bd-3dca3d3e8c88',
    English: '5af923fd-a077-42b5-b44c-9f6174d5a93f',
    Math: '399260d3-18d1-4b03-a161-73d41c6d8d61',
  },
  position: {
    Chinese: '109ba957-0409-467a-8bf6-6426c3f3017f',
    English: '889a9a55-215b-42bc-a470-a3cf71e85df6',
    Math: 'b8249c46-b646-45e1-8dc1-7f087a084753',
  },
  positionNav: {
    Chinese: '9a5c06f6-ddd4-4393-87a4-e00ea858eb39',
    English: '382a4733-b409-4c7f-a212-83976045d570',
    Math: '77024b22-441a-42a7-a1e0-bf2b427ab23f',
  },
};

/**
 * 获取年龄选项
 *
 * @param {string} subject 学科
 * @param {string} language 页面语言
 * @return {array}
 */
export const getAgeOptions = (subject: string, language: string) => {
  const result = optionRules.find((item) => item.match(subject, language));

  return result?.action();
};

export interface FormRefProps {
  showPCForm: () => void;
  showMobileForm: () => void;
}

interface FormProps {
  type?: string;
  forwardedRef?: React.ForwardedRef<FormRefProps>;
}

const Form: React.FC<FormProps> = (props) => {
  const { type, forwardedRef } = props;
  const { t, i18n } = useTranslation(['form', 'common']);
  const router = useRouter();
  const { locale } = useContext(MyContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const fromRef = useRef<HTMLDivElement>(null);
  const [fullPhone, setFullPhone] = useState<[string, string]>(['', '']);
  const [country, setCountry] = useState('');
  const [checked, setChecked] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [phone, setPhone] = useState<boolean>(false);
  const [age, setAge] = useState<boolean>(false);
  const [subject, setSubject] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<string>('');
  const [ageData, setAgeData] = useState<string>('');
  const [subjectData, setSubjectData] = useState<string>('Chinese');
  const [inviteCode, setInviteCode] = useState<string>('');
  // 超过首屏
  const [scrollHide, setScrollHide] = useState<boolean>(false);
  // 屏幕到底部
  // const [scrollBottom, setScrollBottom] = useState<boolean>(false);
  // 移动端表单
  const [isMobileForm, setIsMobileForm] = useState<boolean>(false);
  // pc悬浮表单
  const [isPcForm, setIsPcForm] = useState<boolean>(false);
  // 影藏按钮
  const [hideBtn] = useState<boolean>(type === 'positionNav');
  // 完成页
  const [isSuccessVisible, setIsSuccessVisible] = useState<boolean>(false);
  // l参数
  const [leadId, setLeadId] = useState<string>('8ac58659-6601-4ba7-af9e-62346a7b06cd');
  const [reportParams, setReportParams] = useState<any>();
  const [verifyModalVisible, setVerifyModalVisible] = useState<boolean>(false);
  const [verifyDrawerVisible, setVerifyDrawerVisible] = useState<boolean>(false);
  // 手机验证码， 选择手机区号与时区不同时显示
  const [isPhoneVerify, setIsPhoneVerify] = useState<boolean>(false);
  const pageName = '官网';
  const EMAIL_REG = /^[\w-.]+@[\w-.]+\.[a-zA-Z]{2,}$/;
  const PHONE_REG = /^[0-9\\-]{4,15}$/;
  const sendDataToGTM = useGTM();
  const state = useLocation();

  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    setScrollHide(scrollTop > 700);
    // setScrollBottom(locale === 'en' ? scrollTop > 8700 : scrollTop > 7500);
  };

  const getLeadId = (sub: string) => {
    const localeKey = locale === 'en' ? 'en' : 'default';
    const leadIdNew =
      (type ? leadIdMap[type]?.[sub] : leadIdMap[localeKey][sub]) || '8ac58659-6601-4ba7-af9e-62346a7b06cd';

    setLeadId(leadIdNew);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (type) return;
    setReportParams({
      l: leadId,
      subject: subjectData === 'Chinese' ? 'CHINESE' : 'MATH',
      landingPageVersion: pageName,
      pv: null,
      uv: null,
    });
    report(
      `${pageName}_${leadId}_${subjectData}`,
      {
        ...reportParams,
        event: `g_OfficalWebsitePV`,
        pv: 1,
        originalUrl: window.location.href,
      },
      true,
    );

    report(`${pageName}_${leadId}_${subjectData}`, {
      ...reportParams,
      event: `g_OfficalWebsiteUV`,
      uv: 1,
    });
  }, []);

  const handleVerificationCode = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 720) {
      setVerifyModalVisible(true);
      return;
    }
    if (windowWidth < 720) {
      setVerifyDrawerVisible(true);
    }
  };
  const showPCForm = () => {
    setIsPcForm(true);
  };
  const showMobileForm = () => {
    setIsMobileForm(true);
  };

  const hidePCMobileForm = () => {
    setIsPcForm(false);
    setIsMobileForm(false);
  };

  useImperativeHandle(forwardedRef, () => ({
    showPCForm,
    showMobileForm,
  }));

  useUpdateEffect(() => {
    setTimeout(() => {
      const drawer = fromRef.current;
      // 获取原窗口的高度
      const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const resizeCallback = () => {
        // console.log('resizeCallback');
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
          // 当软键盘弹起，在此处操作
          drawer?.classList.add(styles.panel);
        } else {
          // 当软键盘收起，在此处操作
          drawer?.classList.remove(styles.panel);
        }
      };
      if (isMobileForm) {
        // console.log('addEventListener');
        window.addEventListener('resize', resizeCallback);
      } else {
        // console.log('removeEventListener');
        window.removeEventListener('resize', resizeCallback);
      }
    }, 300);
  }, [isMobileForm]);

  const getInputId = () => {
    switch (type) {
      case 'position':
        return 'inputId';
      case 'positionNav':
        return 'inputIdNav';
      default:
        return 'inputIdp';
    }
  };

  const checkboxInputId = getInputId();

  const createLead = async ({ verificationCode, loadingModal }: VerificationInfo) => {
    const params: any = {
      timezone: JSON.parse(sessionStorage.getItem('timeZone') || '')?.itemValue,
      timezoneText: JSON.parse(sessionStorage.getItem('timeZone') || '')?.itemName,
      country: JSON.parse(sessionStorage.getItem('countryIp') || ''),
      countryCode: fullPhone[0],
      lead: leadId || '',
      formId: '',
      email: emailData,
      createFrom: 'h5',
      leadSource: 'h5',
      mobile: fullPhone[1],
      name: fullPhone[1],
      userId: '',
      time: '2022-12-13 13:03:05',
      leadType: subjectData,
      code: '',
      // 小于3 大于18 传空
      age: ageData || '',
      recommendStudentSId: '',
      verificationCode: verificationCode || '',
      isCode: isPhoneVerify,
      payload: getBasicInfo() || {},
      inviteCode: inviteCode || '',
    };
    localStorage.setItem('createData', JSON.stringify(params));

    await createLeadNoRemind(params)
      .then((res: any) => {
        if (res?.data?.trackId) {
          traceEvent({
            data: {
              _event: type ? 'O_LZTC_FreeReceiveLeadsId' : 'O_LZMK_FreeReceiveLeadsId',
              track_id: res.data.trackId,
            },
          });
        }
        if (
          res.repetitionLead ||
          res.data?.hasRecommend ||
          (subjectData === 'Math' && (Number(ageData) < 6 || Number(ageData) > 15)) ||
          subjectData === 'English'
        ) {
          setIsSuccessVisible(true);
          setIsPcForm(false);
        } else {
          const url = qs.stringifyUrl({
            url: locale === 'en' ? '/en/booking' : 'booking',
            query: {
              code: res?.data?.studentCode,
            },
          });
          router.push(url);

          if (type) {
            report(`${pageName}_表单按钮_${leadId}_${subjectData}`, {
              ...reportParams,
              event: `g_OfficalWebsitePopLeads`,
              pv: 1,
              originalUrl: window.location.href,
            });
          } else {
            report(`${pageName}_表单按钮_${leadId}_${subjectData}`, {
              ...reportParams,
              event: `g_OfficalWebsiteLeads`,
              pv: 1,
              originalUrl: window.location.href,
            });
          }
          hidePCMobileForm();
        }
      })
      .finally(() => {
        LoadingModal.hide();
        if (loadingModal) {
          loadingModal.hide();
        }
      })
      .catch((err) => {
        const { response } = err;
        const { data = {}, status } = response;
        const { errors, message } = data;
        const errMsg = (errors && errors[Object.keys(errors)[0]]) || message || err.message;
        if (messageIsRepeat(errMsg)) {
          Toast.info(errMsg);
        } else {
          Toast.info(errMsg);
        }
        traceEvent({
          data: {
            _event: type ? 'O_LZTC_FreeReceiveLeadsId' : 'O_LZMK_FreeReceiveLeadsId',
            error_code: status,
          },
        });
      });
  };
  const formSubmit = () => {
    if (type) {
      report(
        `${pageName}_表单按钮_${leadId}_${subjectData}`,
        {
          ...reportParams,
          event: `g_OfficalWebsitePopLeadsClick`,
          pv: 1,
          originalUrl: window.location.href,
        },
        true,
      );
    } else {
      report(
        `${pageName}_表单按钮_${leadId}_${subjectData}`,
        {
          ...reportParams,
          event: `g_OfficalWebsiteLeadsClick`,
          pv: 1,
          originalUrl: window.location.href,
        },
        true,
      );
    }
    traceEvent({
      data: {
        _event: type ? 'O_LZTC_FreeReceiveClick' : 'O_LZMK_FreeReceiveClick',
        button_name: t('表单-按钮'),
        InputBox_area: fullPhone?.[0],
        InputBox_mobile: fullPhone?.[1],
        InputBox_mail: emailData,
        InputBox_age: ageData,
        InputBox_subject: subjectData,
      },
    });
    if (isMobileForm || isPcForm) {
      const gtagIdentifyModalMap = {
        Chinese: 'collection_hp_bottomsuctionpop_chinese',
        Math: 'collection_hp_bottomsuctionpop_math',
        English: 'collection_hp_bottomsuctionpop_english',
      };
      sendDataToGTM(gtagIdentifyModalMap[(subjectData as 'Math' | 'Chinese' | 'English') || 'Chinese']);
    } else {
      const gtagIdentifyMap = {
        Math: 'collection_hp_firstscreen_math',
        Chinese: 'collection_hp_firstscreen_chinese',
        English: 'collection_hp_firstscreen_english',
      };
      sendDataToGTM(gtagIdentifyMap[(subjectData as 'Math' | 'Chinese' | 'English') || 'Chinese']);
    }
    if (!fullPhone[1]) {
      setPhone(true);
      return;
    }
    if (!emailData) {
      setEmail(true);
      return;
    }
    if (!ageData) {
      setAge(true);
      return;
    }
    if (!subjectData) {
      setSubject(true);
      return;
    }
    if (!checked) {
      Toast.info(`${t('表单-协议')}`);
      return;
    }
    // 判断区号是否改变 改变短信校验
    if (isPhoneVerify) {
      handleVerificationCode();
      return;
    }
    LoadingModal.show(t('表单-加载中'));
    createLead({});
  };

  const submitVerificationCode = async ({ verificationCode, loadingModal }: VerificationInfo) => {
    setVerifyDrawerVisible(false);
    setVerifyModalVisible(false);
    createLead({ verificationCode, loadingModal });
  };
  return (
    <section
      id="retentionForm"
      className={cls(
        styles.container1,
        {
          [styles.localeEn]: locale === 'en',
        },
        (type === 'position' || type === 'positionNav') && styles.sectionMobile,
        isMobileForm && styles.sectionForm,
        isPcForm && styles.sectionPcForm,
        type === 'positionNav' && styles.sectionMobileHighZIndex,
      )}
    >
      <div
        className={cls(
          styles.container,
          (type === 'position' || type === 'positionNav') && styles.containerMobile,
          isMobileForm && styles.containerForm,
          isPcForm && styles.containerPcForm,
        )}
        ref={fromRef}
      >
        <div
          className={styles.closeImg}
          onClick={() => {
            setIsMobileForm(false);
            setIsPcForm(false);
          }}
        >
          <Image
            width={12}
            height={12}
            layout="responsive"
            src={
              type === 'position' || type === 'positionNav' ? '/images/home/close-suc.svg' : '/images/home/close.svg'
            }
            alt="关闭"
            className={cls(styles.closeImgPc, type !== 'position' && type !== 'positionNav' && styles.closeImgPcNone)}
          />
          <Image
            className={styles.closeImgM}
            width={12}
            height={12}
            layout="responsive"
            src="/images/home/close.svg"
            alt="关闭"
          />
        </div>
        <div className={cls(styles.formTitle, isPcForm && styles.formTitlePc)}>
          <span className={styles.formTitle1}>{t('表单-标题1')}</span>
          <span className={styles.formTitle2}>{t('表单-标题2')}</span>
        </div>
        <PhoneInput
          value={fullPhone}
          defaultValue={fullPhone[1]}
          handleChangeCountry={() => {
            setIsPhoneVerify(true);
          }}
          onChangeCountry={(value) => {
            setCountry(value);
          }}
          onChange={(value: [string, string] | string) => {
            if (typeof value === 'string') {
              setFullPhone((pre) => {
                return [value, pre?.[1]];
              });
            } else {
              setFullPhone(value);
              setPhone(!PHONE_REG.test(value[1]));
            }
          }}
          maxLength={30}
          className={cls(styles.input, phone && styles.errorPhone)}
        />
        {phone && <span className={styles.formError}>{t('表单-错误-手机号')}</span>}
        <Input
          placeholder={t('表单-邮箱')}
          className={cls(email && styles.errorInput)}
          name="email"
          onChange={(e) => {
            setEmail(!EMAIL_REG.test(e?.target?.value));
            setEmailData(e?.target?.value);
          }}
        />
        {email && <span className={styles.formError}>{t('表单-错误-邮箱')}</span>}
        <Select
          classNamePrefix="select"
          onChange={(e) => {
            if (e) {
              setAge(!e);
              setAgeData(e?.value);
            }
          }}
          className={cls(styles.select, age && styles.errorSelect)}
          options={getAgeOptions(subjectData, locale)}
          isSearchable={false}
          placeholder={t('表单-孩子年龄')}
          name="age"
        />
        {age && <span className={styles.formError}>{t('表单-错误-年龄')}</span>}
        <Select
          classNamePrefix="select"
          onChange={(e) => {
            if (e) {
              setSubject(!e);
              setSubjectData(e?.value);
              getLeadId(e?.value);
            }
          }}
          options={locale === 'en' ? SUBJECT_EN : SUBJECT_ZH}
          className={cls(styles.select, subject && styles.errorSelect)}
          isSearchable={false}
          placeholder={t('表单-课程')}
          name="subject"
        />
        {subject && <span className={styles.formError}>{t('表单-错误-科目')}</span>}
        <Input placeholder={t('common:邀请码（非必填）')} onChange={(e) => setInviteCode(e?.target?.value || '')} />
        <div className={cls(styles.formRadio, isPcForm && styles.formRadioPosition)}>
          <input
            type="checkbox"
            id={checkboxInputId}
            checked={checked}
            onChange={(e) => {
              console.log('e----->', e?.target?.checked);
              setChecked(e?.target?.checked);
            }}
          />
          <label htmlFor={checkboxInputId} />
          <span className={styles.agreement}>
            {t('表单-隐私协议1')}{' '}
            <a href={`${state.origin}/${i18n.language}/terms/?layout=no`} target="_blank" rel="noreferrer">
              {t('表单-隐私协议2')}
            </a>{' '}
            {t('表单-隐私协议3')}
            <a href={`${state.origin}/${i18n.language}/policy/?layout=no`} target="_blank" rel="noreferrer">
              {' '}
              {t('表单-隐私协议4')}
            </a>
          </span>
        </div>
        {(isMobileForm || isPcForm) && (
          <div>
            <button
              type="button"
              className={cls(styles.formButtonMobile, isPcForm && styles.formButtonPc)}
              onClick={formSubmit}
            >
              {t('表单-按钮')}
            </button>
          </div>
        )}
        {!isMobileForm && !isPcForm && (
          <div>
            <button type="button" className={styles.formButton} onClick={formSubmit}>
              {t('表单-按钮')}
            </button>
            <div className={styles.submitBtnHand}>
              <Image width={85} height={84} layout="responsive" src="/images/home/hand.svg" alt="悟空中文" />
            </div>
          </div>
        )}
      </div>
      {!hideBtn && !isPcForm && (
        <div className={cls(styles.pcBtnPosition, !scrollHide && styles.pcBtnPositionHide)}>
          <div className={styles.positionBoxWkImg} />
          <div className={styles.positionBoxSlogan} />
          <div className={styles.pcBtnPositionBox}>
            <PhoneInput
              value={fullPhone}
              onChangeCountry={(value) => setCountry(value)}
              onChange={(value: [string, string] | string) => {
                if (typeof value === 'string') {
                  setFullPhone((pre) => {
                    return [value, pre?.[1]];
                  });
                } else {
                  setFullPhone(value);
                  setPhone(!PHONE_REG.test(value[1]));
                }
              }}
              maxLength={30}
              className={cls(styles.input, styles.inputPosition)}
              position
            />
            {phone && <p className={styles.positionPhoneError}>{t('表单-错误-手机号')}</p>}
            <div
              className={styles.positionBtn}
              onClick={() => {
                if (!phone) {
                  setIsPcForm(true);
                  setFullPhone(fullPhone);
                  report(`${pageName}_底部按钮_${leadId}_${subjectData}`, {
                    ...reportParams,
                    event: `g_OfficalWebsiteBottomLeads`,
                    pv: 1,
                    originalUrl: window.location.href,
                  });
                }
                report(
                  `${pageName}_底部按钮_${leadId}_${subjectData}`,
                  {
                    ...reportParams,
                    event: `g_OfficalWebsiteBottomLeadsClick`,
                    pv: 1,
                    originalUrl: window.location.href,
                  },
                  true,
                );
                traceEvent({
                  data: {
                    _event: 'O_XD_FreeReceiveClick',
                    button_name: t('表单-按钮'),
                    InputBox_mobile: `${fullPhone?.[0] || ''}${fullPhone?.[1] || ''}`,
                  },
                });
                sendDataToGTM('entry_hp_bottomsuction');
              }}
            >
              {t('表单-吸底按钮领取')}
            </div>
          </div>
        </div>
      )}
      {!hideBtn && !isMobileForm && (
        <div
          className={cls(styles.mobileBtnPositionZh, locale === 'en' && styles.mobileBtnPosition)}
          onClick={() => {
            setIsMobileForm(true);
          }}
        />
      )}
      <Success
        visible={isSuccessVisible}
        onClose={() => {
          setIsSuccessVisible(false);
        }}
      />
      {isPhoneVerify && (
        <VerifyModal
          visible={verifyModalVisible}
          handleClose={() => setVerifyModalVisible(false)}
          handleOpen={() => setVerifyModalVisible(true)}
          handleSubmit={submitVerificationCode}
          themeColor={getThemeColor('CHINESE')}
          initValue={[fullPhone[0] ?? '', fullPhone[1] ?? '']}
          reportParams={{ ...reportParams }}
          pageName={pageName}
        />
      )}
      {isPhoneVerify && (
        <VerifyDrawer
          language="CH"
          visible={verifyDrawerVisible}
          handleClose={() => setVerifyDrawerVisible(false)}
          handleOpen={() => setVerifyDrawerVisible(true)}
          handleSubmit={submitVerificationCode}
          themeColor={getThemeColor('CHINESE')}
          initValue={[fullPhone[0] ?? '', fullPhone[1] ?? '']}
          reportParams={{ ...reportParams }}
          pageName={pageName}
        />
      )}
    </section>
  );
};

export default Form;
