import React, { forwardRef, useRef } from 'react';
import cls from 'classnames';
import styles from './style.module.scss';
import { isIOS } from '@/utils/utils';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixCls?: string;
  suffixCls?: string;
  size?: 'smail' | 'large' | 'medium';
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    prefix,
    suffix,
    prefixCls,
    suffixCls,
    size = 'medium',
    onKeyDown,
    onPressEnter,
    ...restProps
  } = props || {};
  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   const inputElement = inputRef.current;
  //   const drawer = inputElement?.closest?.('.rc-drawer-content-wrapper') as HTMLDivElement;
  //   const focusCallback = () => {
  //     drawer.classList.add(styles.panel);
  //   };
  //   const blurCallback = () => {
  //     drawer.classList.remove(styles.panel);
  //   };
  //   // if (inputElement && drawer) {
  //   //   inputElement.addEventListener('focus', focusCallback);
  //   //   inputElement.addEventListener('blur', blurCallback);
  //   // }

  //   return () => {
  //     if (inputElement && drawer) {
  //       inputElement.removeEventListener('focus', focusCallback);
  //       inputElement.removeEventListener('blur', blurCallback);
  //     }
  //   };
  // }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.keyCode === 13) {
      onPressEnter?.(event);
    }
    onKeyDown?.(event);
  };

  return (
    <div className={cls(styles.inputWrapper, styles[`input-wrapper-${size}`], className)}>
      {prefix && <div className={prefixCls}>{prefix}</div>}
      <input
        className={styles.input}
        onKeyDown={handleKeyDown}
        {...restProps}
        ref={ref || inputRef}
        style={{
          ...(restProps?.style || {}),
          ...(isIOS() ? { fontSize: 16 } : {}),
        }}
      />
      {suffix && <div className={suffixCls}>{suffix}</div>}
    </div>
  );
});

export default Input;
