/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useMount, useUpdateEffect } from 'react-use';
import Input from '../Input';
// eslint-disable-next-line import/extensions
import CountryCodePanel, { CountryCodePanelProps } from '../CountryCodePanel';
import styles from './style.module.scss';
import { getCountryList } from '@/request/login';

interface PhoneInputProps extends Pick<CountryCodePanelProps, 'language'> {
  value?: [string, string, string?];
  initValue?: string;
  onChange?(value: [string, string, string?]): void;
  placeholder?: string;
  className?: string;
  countryCodeClassName?: string;
  arrowClassName?: string;
  onClick?(): void;
  onChangeCountry?: (value: string) => void;
  wrapperCls?: string;
  needRequestCountries?: boolean;
}

const PhoneInput = ({
  value = ['', ''],
  initValue,
  onChange,
  placeholder = '请输入您的手机号',
  className,
  countryCodeClassName,
  arrowClassName,
  language = 'zh',
  onClick,
  onChangeCountry,
  needRequestCountries,
  wrapperCls,
}: PhoneInputProps) => {
  const [countryCodePanelVisible, setCountryCodePanelVisible] = useState(false);
  const { t } = useTranslation(['common']);
  const [countryCode, setCountryCode] = useState<string>('');
  const countriesRef = useRef<any>();
  const isUserInputRef = React.useRef<boolean>(false);
  const phoneValueRef = useRef<string>('');

  const handleFocus = () => {
    isUserInputRef.current = true;
  };

  const handleBlur = () => {
    isUserInputRef.current = false;
  };

  const requestCountries = () => {
    getCountryList().then((res: any) => {
      const { local, countries } = res;
      countriesRef.current = countries;
      if (local?.areaCode === '+86') {
        // 如果是+86默认更换为+1
        const country = countries?.find((item: any) => item?.areaCode === '+1');
        onChange?.([country?.areaCode ?? '', phoneValueRef.current]);
        onChangeCountry?.(country?.name ?? '');
        setCountryCode(country?.areaCode ?? '');
      } else {
        onChange?.([local?.areaCode ?? '', phoneValueRef.current]);
        onChangeCountry?.(local.name);
        setCountryCode(local?.areaCode ?? '');
      }
    });
  };

  useMount(() => {
    requestCountries();
  });

  useUpdateEffect(() => {
    if (needRequestCountries) {
      requestCountries();
    }
  }, [needRequestCountries]);

  useUpdateEffect(() => {
    if (initValue && countriesRef.current?.length) {
      const initValueArr = initValue.split('&');
      onChange?.([initValueArr[0], initValueArr[1]] as [string, string, (string | undefined)?]);
      onChangeCountry?.(initValueArr?.[2] ?? '');
      setCountryCode(initValueArr?.[0] ?? '');
    }
  }, [initValue, countriesRef.current]);

  const CodeSelect = (
    <CountryCodePanel
      value={value[0]}
      onVisibleChange={setCountryCodePanelVisible}
      onChange={({ code, enName }) => {
        onChange?.([code, value[1]]);
        onChangeCountry?.(enName);
      }}
      language={language}
    >
      {({ value: codeValue, showPanel }) => (
        <div className={classNames(styles.countryCode, countryCodeClassName)} onClick={showPanel}>
          <span>{codeValue}</span>
          <svg
            viewBox="0 0 7 5"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={classNames(styles.arrow, arrowClassName, countryCodePanelVisible && styles.open)}
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-28.000000, -8.000000)" fill="#4D4D4D">
                <path
                  d="M30.3666622,7.18573692 L33.6911586,9.84272915 C34.0541499,10.1328378 34.113233,10.6622799 33.8231243,11.0252712 C33.7841656,11.0740174 33.7399048,11.1182782 33.6911586,11.157237 L30.3666622,13.8142292 C30.0036709,14.1043378 29.4742288,14.0452548 29.1841202,13.6822634 C29.0649283,13.5331276 29,13.3478894 29,13.1569753 L29,7.84299083 C29,7.37831276 29.376696,7.00161675 29.8413741,7.00161675 C30.0322882,7.00161675 30.2175263,7.06654507 30.3666622,7.18573692 Z"
                  transform="translate(31.500000, 10.500000) rotate(-270.000000) translate(-31.500000, -10.500000) "
                />
              </g>
            </g>
          </svg>
        </div>
      )}
    </CountryCodePanel>
  );

  return (
    <div className={classNames(styles.phoneInputWrap, wrapperCls)}>
      <Input
        prefix={CodeSelect}
        prefixCls={styles.inputCode}
        type="number"
        className={classNames(className, styles.phoneInput)}
        placeholder={t(placeholder)}
        value={value[1]}
        onChange={(e) => {
          const { value: targetValue } = e.target;
          if (!isUserInputRef.current && initValue) {
            return;
          }
          if (!isUserInputRef.current && !/^\d+$/.test(targetValue)) {
            return;
          }
          if (!isUserInputRef.current) {
            phoneValueRef.current = targetValue;
            return;
          }
          onChange?.([value?.[0] || countryCode, targetValue]);
        }}
        onClick={() => {
          onClick?.();
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default React.memo(PhoneInput);
