import React, { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styles from './style.module.scss';
// eslint-disable-next-line import/no-cycle
import MobileLoginForm from './MobileLoginForm';
// eslint-disable-next-line import/no-cycle
import EmailLoginForm from './EmailLoginForm';

interface Props {
  handleLoginFinish: () => void;
  handleClose: () => void;
  handleOpen: () => void;
  params?: { phone: string[] } | undefined;
}

const LoginPC: FC<Props> = ({ handleLoginFinish, handleClose, handleOpen, params }) => {
  const { t } = useTranslation(['common']);
  const [tabType, setTabType] = useState<'mobile' | 'email'>('mobile');
  const [loginType, setLoginType] = useState<'password' | 'code'>('code');
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const router = useRouter();

  return (
    <div className={styles.loginPC}>
      {router.asPath.includes('/resources/') ? (
        <>
          <h2 className="text-[24px] text-center font-semibold">{t('加入悟空')}</h2>
          <h2 className="text-[24px] text-center font-semibold" dangerouslySetInnerHTML={{ __html: t('免费领资料') }} />
        </>
      ) : (
        <>
          {' '}
          <div className={styles.title}>{t('登录')}</div>
          <div className={styles.subTitle}>{t('欢迎加入WuKong')}</div>
          <div className={styles.description}>{t('新用户首次登录免费领取课程')}</div>
        </>
      )}
      {/* <div className={styles.tab}>
        <div className={styles.tabPane} onClick={() => setTabType('mobile')}>
          <span
            style={{
              color: tabType === 'mobile' ? '#484C70' : '#8D8FA6',
            }}
          >
            {t('手机')}
          </span>
          {tabType === 'mobile' && <div className={styles.line} />}
        </div>
        <div className={styles.tabPane} onClick={() => setTabType('email')}>
          <span
            style={{
              color: tabType === 'email' ? '#484C70' : '#8D8FA6',
            }}
          >
            {t('邮箱')}
          </span>
          {tabType === 'email' && <div className={styles.line} />}
        </div>
      </div> */}
      <div style={{ display: tabType === 'mobile' ? 'block' : 'none' }}>
        <MobileLoginForm
          handleLoginFinish={handleLoginFinish}
          handleClose={handleClose}
          handleOpen={handleOpen}
          params={params}
          loginType={loginType}
          setLoginType={setLoginType}
          tabType={tabType}
          setTabType={setTabType}
          privacyChecked={privacyChecked}
          setPrivacyChecked={setPrivacyChecked}
        />
      </div>
      <div style={{ display: tabType === 'email' ? 'block' : 'none' }}>
        <EmailLoginForm
          handleLoginFinish={handleLoginFinish}
          handleClose={handleClose}
          handleOpen={handleOpen}
          loginType={loginType}
          setLoginType={setLoginType}
          tabType={tabType}
          setTabType={setTabType}
          privacyChecked={privacyChecked}
          setPrivacyChecked={setPrivacyChecked}
        />
      </div>
    </div>
  );
};

export default LoginPC;
