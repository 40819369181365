import React, { FC, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import Dialog from 'rc-dialog';
import RCForm from 'rc-field-form';
import 'rc-dialog/assets/index.css';
import Image from 'next/image';
import { debounce } from 'lodash';
import ReturnImg from '@public/images/common/return2.svg';
import CloseImg from '@public/images/common/close2.svg';
import { useTranslation } from 'next-i18next';
import FormItem from '@/components/Form/FormItem';
import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import Toast from '@/components/Toast';
import LoadingModal from '@/components/Form/Loading';
import { EventTrackingReq } from '@/request/booking';
import styles from './style.module.scss';
import { sendVoiceVerificationCode, verifyVoiceVerificationCode } from '@/request/nav';
import VerifySlideFixed, { CaptchaSuccess } from '@/components/verifition/verifySlideFixed';
import { MyContext } from '@/config/context-manager';
import { VerificationInfo } from '@/interface/common';

interface FormValues {
  phone: string[];
  code: string;
}

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleReturn: () => void;
  handleSubmit: (verificationInfo: VerificationInfo) => void;
  themeColor: { disabledColor: string; validColor: string };
  reportParams: EventTrackingReq;
  pageName: string;
  phone: string[];
  countDown: number;
  setCountDowm: (count: number) => void;
  reportEvents: (eventName: string) => void;
  ref: any;
}

const VoiceModal: FC<Props> = forwardRef(
  (
    {
      visible,
      handleClose,
      handleReturn,
      handleSubmit,
      themeColor,
      phone,
      countDown,
      setCountDowm,
      reportEvents,
      pageName,
    },
    ref,
  ) => {
    const { locale } = useContext(MyContext);
    const { t } = useTranslation(['form']);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isSlideShow, setIsSlideShow] = useState<boolean>(false);
    const [verifyCodeDirections, setVerifyCodeDirections] = useState(t('验证码-立即获取'));
    const [form] = RCForm.useForm();

    const isFromClassChose = pageName === 'FORM_CLASS_CHOOSE';

    const handleVerifyCodeVerify = () => {
      setIsSlideShow(true);
      reportEvents(
        !isFromClassChose ? 'g_FirstOrder_GetVoiceVerify_Click' : 'g_SecondOrder_VerifyPop_GetVoiceVerify_Click',
      );
    };

    const closeVerify = () => {
      setIsSlideShow(false);
    };

    // 获取验证码
    const handleVerifyCode = (cap: string) => {
      if (countDown !== 0) {
        return;
      }
      let COUNT_DOWN = 60;
      LoadingModal.show(t('表单-加载中'));
      // 发送验证码
      sendVoiceVerificationCode({
        phone: `${phone[0]} ${phone[1]}`,
        captchaVerification: cap,
        locale: locale === 'zh' ? 'zh' : 'en',
      })
        .then(() => {
          setCountDowm(60);
          setVerifyCodeDirections(t('验证码-重新获取'));
          const timer = window.setInterval(() => {
            COUNT_DOWN -= 1;
            setCountDowm(COUNT_DOWN);
            if (COUNT_DOWN === 0) window.clearTimeout(timer);
          }, 1000);
        })
        .finally(() => {
          LoadingModal.hide();
        });
    };

    useImperativeHandle(ref, () => ({
      handleVerifyCode,
    }));

    const verifySuccess = async ({ captchaVerification }: CaptchaSuccess) => {
      try {
        setIsSlideShow(false);
        handleVerifyCode(captchaVerification);
      } catch (err) {
        console.error(err);
      }
    };

    const handleFinishDebounce = async (values: FormValues) => {
      if (!isFromClassChose) {
        reportEvents('g_FirstOrder_VoiceVerifyPop_Submit_Click');
      }
      if (isFromClassChose) {
        reportEvents('g_SecondOrder_VoiceVerifyPop_Submit_Click');
      }
      const { code } = values || {};
      let verifyResult: boolean = true;
      const params = { phone: `${phone[0]} ${phone[1]}`, verificationCode: code };
      LoadingModal.show(t('表单-加载中'));
      const result = await verifyVoiceVerificationCode(params);
      verifyResult = result?.content;
      if (verifyResult) {
        handleSubmit({
          verificationCode: code,
          loadingModal: LoadingModal,
        });
      } else {
        LoadingModal.hide();
        Toast.info(t('验证码-语音验证码不正确'));
      }
    };

    const handleFinish = debounce(handleFinishDebounce, 500);

    return (
      <>
        <Dialog
          className={styles.voiceModal}
          visible={visible}
          animation="zoom"
          maskAnimation="fade"
          // onClose={handleClose}
          width={468}
        >
          <div className={styles.returnImg} onClick={handleReturn}>
            <Image src={ReturnImg} alt="返回" />
          </div>
          <div className={styles.closeImg} onClick={handleClose}>
            <Image src={CloseImg} alt="关闭" />
          </div>
          <div className={styles.title}>{t('验证码-语音验证码')}</div>
          <div className={styles.subTitle}>{t('验证码-播报验证码')}</div>
          <RCForm form={form} onFinish={handleFinish} className={styles.form}>
            <div className={styles.code}>
              <FormItem
                className={styles.fixFormItem}
                errClassName={styles.formItemErr}
                name="code"
                rules={[
                  {
                    validator(_, value) {
                      const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
                      setIsValid(formLength === 1);
                      // 不能全是空格 或者为空
                      const length = value?.split(' ')?.join('')?.length;
                      if (!length) {
                        return Promise.reject(new Error(t('验证码-请输入验证码')));
                      }
                      if (length !== 4) {
                        return Promise.reject(new Error(t('验证码-输入语音验证码提醒')));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                showErr={false}
              >
                <Input
                  className={styles.input}
                  placeholder={t('验证码-输入语音验证码')}
                  maxLength={4}
                  type="tel"
                  onFocus={() => {
                    reportEvents(
                      !isFromClassChose ? 'g_FirstOrder_formVoiceVerify_Click' : 'g_SecondOrder_formVoiceVerify_Click',
                    );
                  }}
                  // className={classNames(styles.verifyInput, styles.input)}
                  // onBlur={() => doTrackList([FIELDS_MAP.CODE_CLICK, FIELDS_MAP.CODE_CLICK_USER])}
                />
              </FormItem>
              <div className={styles.verifyCodeContainer}>
                <Button
                  onClick={handleVerifyCodeVerify}
                  disabled={countDown !== 0}
                  className={styles.verifyCode}
                  style={{
                    color: themeColor.validColor,
                  }}
                >
                  {countDown === 0 ? verifyCodeDirections : `${countDown} s`}
                </Button>
              </div>
            </div>
            <Button
              theme="red"
              type="submit"
              onClick={() => form.submit()}
              className={styles.submitBtn}
              style={{
                backgroundColor: isValid ? themeColor.validColor : themeColor.disabledColor,
              }}
              disabled={!isValid}
            >
              {t('验证码-确认提交')}
            </Button>
          </RCForm>
        </Dialog>
        <VerifySlideFixed isSlideShow={isSlideShow} onClose={closeVerify} success={verifySuccess} />
      </>
    );
  },
);

export default VoiceModal;
