import { FieldProps } from 'rc-field-form/es/Field';
import React from 'react';
import Form from 'rc-field-form';
import classNames from 'classnames';
import styles from './style.module.scss';

interface FormItemProps extends FieldProps {
  className?: string;
  labelClassName?: string;
  errClassName?: string;
  label?: React.ReactNode;
  style?: React.CSSProperties;
  showErr?: boolean;
}

/**
 * @see https://field-form-react-component.vercel.app/demo/layout
 * 参考自 antd 表单的基础组件
 */
const FormItem = ({
  label,
  children,
  style,
  className,
  labelClassName,
  errClassName,
  showErr = true,
  ...restProps
}: FormItemProps) => {
  return (
    <Form.Field {...restProps}>
      {(control, meta, form) => {
        const hasError = !!meta.errors.length;
        return (
          <div className={classNames(styles.formItem, className)} style={style}>
            {label && <p className={classNames(styles.label, labelClassName)}>{label}</p>}
            <p className={classNames(styles.inputContainer, showErr && hasError && 'wk-form-item-error')}>
              {typeof children === 'function'
                ? children(control, meta, form)
                : children &&
                  React.cloneElement(children, {
                    ...control,
                  })}
            </p>
            <div className={classNames(styles.error, errClassName)}>
              {meta.errors.map((err) => (
                <div key={err}>{err}</div>
              ))}
            </div>
          </div>
        );
      }}
    </Form.Field>
  );
};

export default FormItem;
