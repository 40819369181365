/**
 * 根据科目获取主题色
 *
 * @param {string} subject 学科
 * @return {*}
 */
// eslint-disable-next-line import/prefer-default-export
export const getThemeColor = (subject: string) => {
  const themeMap = {
    CHINESE: { validColor: '#FF5353', disabledColor: '#FFCFCF' },
    MATH: { validColor: '#0B88FA', disabledColor: 'rgba(11, 136, 250, 0.2)' },
    ENGLISH: { validColor: '#6D38FB', disabledColor: 'rgba(109, 56, 251, 0.2)' },
  } as any;

  return themeMap[subject || 'CHINESE'];
};
