import React, { forwardRef } from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixCls?: string;
  suffixCls?: string;
  size?: 'smail' | 'large' | 'medium';
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  isPassword?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    prefix,
    suffix,
    prefixCls,
    suffixCls,
    size = 'medium',
    onKeyDown,
    onPressEnter,
    isPassword,
    ...restProps
  } = props || {};

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.keyCode === 13) {
      onPressEnter?.(event);
    }
    onKeyDown?.(event);
  };

  const handleForbiddenEvents = (e: any) => {
    if (isPassword || restProps.type === 'password') {
      e?.preventDefault?.();
    }
  };

  return (
    <div className={cls(styles.inputWrapper, styles[`input-wrapper-${size}`], className)}>
      {prefix && <div className={prefixCls}>{prefix}</div>}
      <input
        className={styles.input}
        onKeyDown={handleKeyDown}
        {...restProps}
        ref={ref}
        autoComplete="new-password"
        onPaste={handleForbiddenEvents}
        onCopy={handleForbiddenEvents}
      />
      {suffix && <div className={suffixCls}>{suffix}</div>}
    </div>
  );
});

export default Input;
