import RCForm from 'rc-field-form';
import React, { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { throttle } from 'lodash';
import FormItem from '@/components/Form/FormItem';
import styles from './style.module.scss';
import Button from '../Button';
import ToastLoading from '@/components/ToastLoading';
import { registerAccountV2 } from '@/request/login';
// eslint-disable-next-line import/no-cycle
import { setTokenInfo } from '@/utils/token';
import Input from '../Input';

interface FormValues {
  password: string;
}

interface Props {
  handleClose: () => void;
  handleLoginFinish: () => void;
  params: any;
}

const SetLoginPasswordForm: FC<Props> = ({ handleLoginFinish, handleClose, params }) => {
  const [form] = RCForm.useForm();
  const { t } = useTranslation(['common']);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { phone = '', email = '', country = '', verificationCode = '' } = params || {};

  const handleFinish = throttle(async (values?: FormValues) => {
    ToastLoading.show(t('加载中...'));
    try {
      const { content } = await registerAccountV2({
        target: email || phone || '',
        country,
        password: values?.password,
        registerWay: !email ? 'PHONE' : 'EMAIL',
        verificationCode,
      });

      const { token } = content;
      if (!token) {
        return;
      }
      setTokenInfo(token);
      handleLoginFinish();
      ToastLoading.hide();
      handleClose();
    } catch {
      ToastLoading.hide();
    }
  }, 1000);

  const checkSubmit = () => {
    const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
    setCanSubmit(formLength === 1);
  };

  return (
    <div className={styles.resetFormContainer}>
      <div className={styles.title}>{t('设置登录密码')}</div>
      <div className={styles.subTitle}>
        <span>{t('为了您的账号安全，请先完善账号信息')}</span>
      </div>
      <RCForm className={styles.form} form={form} onFinish={handleFinish}>
        <input readOnly autoComplete="off" style={{ display: 'none', height: 0 }} />
        <FormItem
          style={{ marginBottom: 12 }}
          name="password"
          rules={[
            {
              validator() {
                checkSubmit();
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder={t('8-20位密码，必须包含字母、数字')}
            maxLength={20}
            suffixCls={styles.password}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, @next/next/no-img-element
            suffix={
              <img
                onClick={() => setShowPassword(!showPassword)}
                src={showPassword ? '/images/login/password_open.svg' : '/images/login/password_close.svg'}
                alt=""
              />
            }
            type={showPassword ? 'text' : 'password'}
          />
        </FormItem>
        <FormItem>
          <div onClick={() => handleFinish()} className={styles.later}>
            {t('以后再说')}
          </div>
        </FormItem>
        <Button onClick={() => form.submit()} disabled={!canSubmit} style={{ marginTop: 64 }}>
          {t('完成')}
        </Button>
      </RCForm>
    </div>
  );
};

export default SetLoginPasswordForm;
