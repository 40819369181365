import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import Dialog from 'rc-dialog';
import RCForm from 'rc-field-form';
import 'rc-dialog/assets/index.css';
import { debounce } from 'lodash';
import Image from 'next/image';
import CloseImg from '@public/images/common/close.svg';
import { useTranslation } from 'next-i18next';
import { EventTrackingReq } from '@/request/booking';
import LoadingModal from '@/components/Form/Loading';
import Toast from '@/components/Toast';
import Input from '@/components/Form/Input';
import { PHONE_REG } from '@/utils/constants';
import Button from '@/components/Button';
import FormItem from '@/components/Form/FormItem';
import PhoneInput from '@/components/Form/PhoneInput';
import styles from './style.module.scss';
import VerifySlideFixed, { CaptchaSuccess } from '@/components/verifition/verifySlideFixed';
import { sendPhoneVerificationCode, verifyPhoneVerificationCode } from '@/request/nav';
import { report } from '@/utils/buryingPoint';
import VoiceModal from '../VoiceModal';
import { VerificationInfo } from '@/interface/common';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum VERIFY_TYPE {
  VOICE = 'VOICE',
  MOBILE = 'MOBILE',
}

interface FormValues {
  phone: string[];
  code: string;
}

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleSubmit: (verificationInfo: VerificationInfo) => void;
  themeColor: { disabledColor: string; validColor: string };
  initValue: string[];
  reportParams: EventTrackingReq;
  pageName: string;
  phoneVerifyPlaceholderValue?: string;
}

const VerifyModal: FC<Props> = ({
  visible,
  handleClose,
  handleOpen,
  handleSubmit,
  themeColor,
  initValue,
  reportParams,
  pageName,
  phoneVerifyPlaceholderValue,
}) => {
  const { t } = useTranslation(['form']);
  const [isSlideShow, setIsSlideShow] = useState<boolean>(false);
  const [verifyCodeDirections, setVerifyCodeDirections] = useState(t('验证码-立即获取'));
  const [countDown, setCountDowm] = useState(0);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [voiceModalVisible, setVoiceModalVisible] = useState<boolean>(false);
  const [verifyType, setVerifyType] = useState<VERIFY_TYPE>(VERIFY_TYPE.MOBILE);
  const voiceModalRef = useRef<{ handleVerifyCode: (cap: string) => void }>(null);
  const [form] = RCForm.useForm();

  const isFromClassChose = pageName === 'FORM_CLASS_CHOOSE';

  const phoneVerifyPlaceholder = useMemo(() => {
    if (phoneVerifyPlaceholderValue) {
      return phoneVerifyPlaceholderValue;
    }
    return t('验证码-请输入验证码');
  }, [phoneVerifyPlaceholderValue]);

  useEffect(() => {
    if (initValue) {
      form.setFieldsValue({
        phone: initValue,
      });
    }
  }, [initValue]);

  const reportEvents = (eventName: string) => {
    report(
      `${pageName}_${eventName}`,
      {
        ...reportParams,
        event: eventName,
        originalUrl: window.location.href,
      },
      true,
    );
  };

  const handleVerifyCodeVerify = (type: VERIFY_TYPE) => {
    if (countDown !== 0) {
      return;
    }
    if (type === VERIFY_TYPE.MOBILE) {
      reportEvents(!isFromClassChose ? 'g_FirstOrder_GetVerify_Click' : 'g_SecondOrder_VerifyPop_GetVerify_Click');
    }
    if (type === VERIFY_TYPE.VOICE) {
      reportEvents(
        !isFromClassChose ? 'g_FirstOrder_GetVoiceVerify_Click' : 'g_SecondOrder_VerifyPop_GetVoiceVerify_Click',
      );
    }
    setVerifyType(type);
    setIsSlideShow(true);
  };

  const closeVerify = () => {
    setIsSlideShow(false);
  };

  // 获取验证码
  const handleVerifyCode = (cap: string) => {
    if (countDown !== 0) {
      return;
    }
    let COUNT_DOWN = 60;
    const phoneValue = `${form.getFieldValue('phone')[0]} ${form.getFieldValue('phone')[1]}`;
    if (!phoneValue) {
      Toast.info(t('验证码-请输入验证码-提醒'));
      return;
    }
    LoadingModal.show(t('表单-加载中'));
    // 发送验证码
    sendPhoneVerificationCode({
      phone: phoneValue,
      captchaVerification: cap,
    })
      .then(() => {
        setCountDowm(60);
        setVerifyCodeDirections(t('验证码-重新获取'));
        const timer = window.setInterval(() => {
          COUNT_DOWN -= 1;
          setCountDowm(COUNT_DOWN);
          if (COUNT_DOWN === 0) window.clearTimeout(timer);
        }, 1000);
      })
      .finally(() => {
        LoadingModal.hide();
      });
  };

  const verifySuccess = async ({ captchaVerification }: CaptchaSuccess) => {
    try {
      setIsSlideShow(false);
      if (verifyType === VERIFY_TYPE.VOICE) {
        setVoiceModalVisible(true);
        handleClose();
        voiceModalRef.current?.handleVerifyCode?.(captchaVerification);
        return;
      }
      handleVerifyCode(captchaVerification);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFinishDebounce = async (values: FormValues) => {
    reportEvents(!isFromClassChose ? 'g_FirstOrder_VerifyPop_Submit_Click' : 'g_SecondOrder_VerifyPop_Submit_Click');
    const { phone, code } = values || {};
    let verifyResult: boolean = true;
    const params = { phone: `${phone?.[0]} ${phone?.[1]}`, code };
    LoadingModal.show(t('表单-加载中'));
    const result = await verifyPhoneVerificationCode(params);
    verifyResult = result?.content;
    if (verifyResult) {
      handleSubmit({ verificationCode: code, loadingModal: LoadingModal });
    } else {
      LoadingModal.hide();
      Toast.info(t('验证码-手机验证码不正确'));
    }
  };

  const handleFinish = debounce(handleFinishDebounce, 500);

  return (
    <>
      <Dialog
        className={styles.verifyModal}
        visible={visible}
        animation="zoom"
        maskAnimation="fade"
        onClose={handleClose}
        width={468}
      >
        <div className={styles.closeImg} onClick={handleClose}>
          <Image src={CloseImg} alt="关闭" />
        </div>
        <div className={styles.title}>{isFromClassChose ? t('验证码-锁定课程时间') : t('验证码-手机验证')}</div>
        <RCForm form={form} onFinish={handleFinish} className={styles.form}>
          <FormItem
            showErr={false}
            name="phone"
            label=""
            className={styles.fixFormItem}
            errClassName={styles.formItemErr}
            initialValue={initValue}
            style={{ marginBottom: 16 }}
            rules={[
              {
                validator(_, value) {
                  const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
                  setIsValid(formLength === 2);
                  if (!value || !value[1]) {
                    return Promise.reject(new Error(t('验证码-手机号不能为空')));
                  }
                  if (!PHONE_REG.test(value[1])) {
                    return Promise.reject(new Error(t('验证码-手机号格式不正确')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput className={styles.phoneInput} disabled />
          </FormItem>
          <div className={styles.code}>
            <FormItem
              className={styles.fixFormItem}
              errClassName={styles.formItemErr}
              name="code"
              rules={[
                {
                  validator(_, value) {
                    const formLength = Object.values(form.getFieldsValue()).filter((val) => !!val).length;
                    setIsValid(formLength === 2);
                    // 不能全是空格 或者为空
                    const length = value?.split(' ')?.join('')?.length;
                    if (!length) {
                      return Promise.reject(new Error(t('验证码-请输入验证码-提醒')));
                    }
                    if (length !== 4) {
                      return Promise.reject(new Error(t('验证码-请输入短信验证码')));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              showErr={false}
            >
              <Input
                className={styles.input}
                placeholder={phoneVerifyPlaceholder}
                maxLength={4}
                type="tel"
                onFocus={() => {
                  reportEvents(!isFromClassChose ? 'g_FirstOrder_formVerify_Click' : 'g_SecondOrder_formVerify_Click');
                }}
                // className={classNames(styles.verifyInput, styles.input)}
                // onBlur={() => doTrackList([FIELDS_MAP.CODE_CLICK, FIELDS_MAP.CODE_CLICK_USER])}
              />
            </FormItem>
            <div className={styles.verifyCodeContainer}>
              <Button
                onClick={() => handleVerifyCodeVerify(VERIFY_TYPE.MOBILE)}
                disabled={countDown !== 0}
                className={styles.verifyCode}
                style={{
                  color: themeColor.validColor,
                }}
              >
                {countDown === 0 ? verifyCodeDirections : `${countDown} s`}
              </Button>
            </div>
          </div>
          <div
            onClick={() => handleVerifyCodeVerify(VERIFY_TYPE.VOICE)}
            className={styles.voiceModalTrigger}
            style={{
              opacity: countDown > 0 ? 0.5 : 1,
              cursor: countDown > 0 ? 'not-allowed' : 'pointer',
            }}
          >
            {t('验证码-接听语音验证码')}
          </div>
          <Button
            type="submit"
            onClick={() => form.submit()}
            theme="red"
            className={styles.submitBtn}
            style={{
              backgroundColor: isValid ? themeColor.validColor : themeColor.disabledColor,
            }}
            disabled={!isValid}
          >
            {t('验证码-确认提交')}
          </Button>
        </RCForm>
      </Dialog>
      <VoiceModal
        visible={voiceModalVisible}
        handleReturn={() => {
          setVoiceModalVisible(false);
          handleOpen();
        }}
        handleClose={() => {
          setVoiceModalVisible(false);
          handleClose();
        }}
        handleSubmit={(data) => {
          setVoiceModalVisible(false);
          handleSubmit(data);
        }}
        themeColor={themeColor}
        reportParams={reportParams}
        pageName={pageName}
        phone={initValue}
        countDown={countDown}
        setCountDowm={setCountDowm}
        ref={voiceModalRef}
        reportEvents={reportEvents}
      />
      <VerifySlideFixed isSlideShow={isSlideShow} onClose={closeVerify} success={verifySuccess} />
    </>
  );
};

export default VerifyModal;
