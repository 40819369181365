import Toast from '@/components/Toast';
import { gatewayRsRequest } from './index';

gatewayRsRequest.interceptors.response.use(
  (arr) => {
    return arr;
  },
  (err) => {
    const { config, response } = err;
    const { status } = response;
    if (status && status === 401 && !config?.skipErrorHandler) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('cpUserInfo');
      Toast.error('401');
    }
    throw err;
  },
);

export interface ISubscribeReq {
  email: string;
  source?: string;
}

export interface ISubscribeRes {
  content: boolean;
}

// 邮件订阅
export const subscribe = (params: ISubscribeReq) => {
  return gatewayRsRequest.post<ISubscribeRes, ISubscribeRes>(`/public/email/subscribe`, {
    ...params,
  });
};
