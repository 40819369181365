import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { useMount, useUpdateEffect } from 'react-use';
import Input from '../Input';
import { getCountryList } from '@/request/login';

import styles from './style.module.scss';

const NUMBER_REG = /^\d+$/g;

export interface PhoneInputProps {
  placeholder: string;
  className?: string;
  onChange?: (value: [string, string] | string) => void;
  value?: [string, string];
  initValue?: string;
  maxLength?: number;
  codeClassName?: string;
  onChangeCountry?: (value: string) => void;
  onClick?: () => void;
  needRequestCountries?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  const {
    placeholder,
    className,
    onChange,
    maxLength,
    value = ['', ''],
    initValue,
    codeClassName,
    onChangeCountry,
    onClick,
    needRequestCountries,
  } = props;
  const { t } = useTranslation(['common']);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentLetter, setCurrentLetter] = useState<string>('');
  const [currentOptions, setCurrentOptions] = useState<{ label: string; value: string }[]>([]);
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const [countryCode, setCountryCode] = useState<string>('');
  const countriesRef = useRef<any>();
  const isUserInputRef = React.useRef<boolean>(false);
  const phoneValueRef = useRef<string>('');

  const handleFocus = () => {
    isUserInputRef.current = true;
  };

  const handleBlur = () => {
    isUserInputRef.current = false;
  };

  const requestCountries = () => {
    getCountryList().then((res: any) => {
      const { local, countries } = res;
      countriesRef.current = countries;
      if (local?.areaCode === '+86') {
        // 如果是+86默认更换为+1
        const country = countries?.find((item: any) => item?.areaCode === '+1');
        onChange?.([country?.areaCode ?? '', phoneValueRef.current]);
        onChangeCountry?.(country?.name ?? '');
        setCountryCode(country?.areaCode ?? '');
      } else {
        onChange?.([local?.areaCode ?? '', phoneValueRef.current]);
        onChangeCountry?.(local.name);
        setCountryCode(local?.areaCode ?? '');
      }
      setOptions(countries?.map((item: any) => ({ label: item.name, value: item.areaCode })));
      setCurrentOptions(countries?.map((item: any) => ({ label: item.name, value: item.areaCode })));
    });
  };

  useMount(() => {
    requestCountries();
  });

  useUpdateEffect(() => {
    if (needRequestCountries) {
      requestCountries();
    }
  }, [needRequestCountries]);

  useUpdateEffect(() => {
    if (initValue && countriesRef.current?.length) {
      const initValueArr = initValue.split('&');
      onChange?.([initValueArr[0], initValueArr[1]] as [string, string]);
      onChangeCountry?.(initValueArr?.[2] ?? '');
      setCountryCode(initValueArr?.[0] ?? '');
    }
  }, [initValue, countriesRef.current]);

  useEffect(() => {
    const handleDomClick = () => {
      setVisible(false);
    };

    window.addEventListener('click', handleDomClick);

    return () => {
      window.removeEventListener('click', handleDomClick);
    };
  }, []);

  const handleSearchCountry = (e: any) => {
    const { value: searchText } = e.currentTarget;
    setSearchValue(searchText);
    setCurrentLetter('');
    if (searchText.match(NUMBER_REG) || searchText.trim().startsWith('+')) {
      setCurrentOptions(options?.filter((item) => item.value?.includes(searchText)));
    } else {
      setCurrentOptions(
        options?.filter((item) => item.label?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase())),
      );
    }
  };

  useUpdateEffect(() => {
    if (!visible) {
      handleSearchCountry({ currentTarget: { value: '' } });
    }
  }, [visible]);

  const codeNode = (
    <>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        role="link"
        className={cls(styles.codeBox, codeClassName)}
        onClick={(e: any) => {
          onClick?.();
          setVisible(!visible);
          e?.stopPropagation?.();
        }}
      >
        <span>{value?.[0]}</span>
        <svg
          width="14"
          height="14"
          className={visible ? styles.iconRotate : styles.icon}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.22229 11.0378C6.62253 11.533 7.37747 11.533 7.77771 11.0378L11.7458 6.12862C12.2744 5.47468 11.8089 4.5 10.9681 4.5H3.03193C2.19108 4.5 1.72565 5.47468 2.25422 6.12862L6.22229 11.0378Z"
            fill="black"
            fillOpacity="0.4"
          />
        </svg>
      </div>
      {visible && (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <div
          className={styles.list}
          role="button"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <Input
            placeholder={t('请输入国家或地区')}
            className={styles.search}
            value={searchValue}
            onChange={handleSearchCountry}
            prefix={
              <div className={styles.searchIcon}>
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.01" width="18" height="18" fill="#D8D8D8" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3078 11.6538C11.6323 11.3294 12.1583 11.3294 12.4827 11.6538L15.4199 14.5911C15.7444 14.9155 15.7444 15.4415 15.4199 15.7659C15.0955 16.0904 14.5695 16.0904 14.245 15.7659L11.3078 12.8287C10.9834 12.5043 10.9834 11.9783 11.3078 11.6538Z"
                    fill="#D1D1D1"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.13965 5.48624C3.43637 7.18953 3.43637 9.9511 5.13965 11.6544C6.84294 13.3577 9.60451 13.3577 11.3078 11.6544C13.0111 9.9511 13.0111 7.18953 11.3078 5.48624C9.60451 3.78295 6.84294 3.78295 5.13965 5.48624ZM3.96477 12.8293C1.61261 10.4771 1.61261 6.66351 3.96477 4.31135C6.31693 1.9592 10.1305 1.9592 12.4827 4.31135C14.8348 6.66351 14.8348 10.4771 12.4827 12.8293C10.1305 15.1814 6.31693 15.1814 3.96477 12.8293Z"
                    fill="#D1D1D1"
                  />
                </svg>
              </div>
            }
          />
          <div className={styles.letterBox}>
            {new Array(26).fill('').map((_, index) => (
              // eslint-disable-next-line jsx-a11y/interactive-supports-focus
              <div
                onClick={() => {
                  setSearchValue('');
                  setCurrentLetter(String.fromCharCode(65 + index));
                  setCurrentOptions(options?.filter((item) => item.label?.startsWith(String.fromCharCode(65 + index))));
                }}
                role="button"
                key={index}
                className={cls(styles.letter, currentLetter === String.fromCharCode(65 + index) && styles.letterActive)}
              >
                {String.fromCharCode(65 + index)}
              </div>
            ))}
          </div>
          <div className={styles.listTip}>
            <svg className={styles.tipIcon} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.01" width="16" height="16" fill="white" />
              <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#FF5353" />
              <line
                x1="8.00005"
                y1="4.7"
                x2="8.00005"
                y2="7.8"
                stroke="#FF5353"
                strokeWidth="1.4"
                strokeLinecap="round"
              />
              <circle cx="7.99995" cy="10.8" r="0.8" fill="#FF5353" />
            </svg>
            <span className={styles.tipText}>{t('暂不支持中国大陆手机号')}</span>
          </div>
          <div className={styles.listBox}>
            <div ref={listRef} style={{ height: 'auto' }}>
              {currentOptions?.map((item, index) => (
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                  className={cls(styles.listItem, value?.[0] === item.value && styles.listItemActive)}
                  role="button"
                  onClick={() => {
                    onChange?.([item?.value ?? '', value?.[1] ?? '']);
                    setVisible(false);
                    onChangeCountry?.(item.label);
                  }}
                  key={index}
                >
                  <span>{item.label}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <Input
      onChange={(e) => {
        const { value: targetValue } = e.target;
        if (!isUserInputRef.current && initValue) {
          return;
        }
        if (!isUserInputRef.current && !/^\d+$/.test(targetValue)) {
          return;
        }
        if (!isUserInputRef.current) {
          phoneValueRef.current = targetValue;
          return;
        }
        onChange?.([value?.[0] || countryCode, targetValue]);
      }}
      placeholder={placeholder}
      maxLength={maxLength}
      prefixCls={styles.code}
      prefix={codeNode}
      className={className}
      style={{ padding: '0 16px 0 0' }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value[1]}
    />
  );
};

export default PhoneInput;
