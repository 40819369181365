import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { traceEvent } from '@wk/wk-gatherer';
import { throttle } from 'lodash';
import styles from './style.module.scss';
import Modal from '@/components/Drawer';
import ModalMobile from '@/components/ModalMobile';
// eslint-disable-next-line import/no-cycle
import MobileRegistForm from '../MobileRegistForm';
// eslint-disable-next-line import/no-cycle
import LoginPC from '..';
import { LoginBtnReportParams, OtherLoginWays } from '@/request/interface/login';
import ToastLoading from '@/components/ToastLoading';
import { loginByPhone } from '@/request/login';
import { setTokenInfo } from '@/utils/token';
// eslint-disable-next-line import/no-cycle
import BindAccountForm from '../BindAccountForm';
import RegistSuccess from '../RegistSuccess';
import Toast from '@/components/Toast';
import { subscribe } from '@/request/commonGatWay';
import LoginContext from '@/context/LoginContext';

interface Props {
  handleOpen: () => void;
  handleClose: () => void;
  handleLoginFinish: (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => void;
}

const Register: FC<Props> = ({ handleOpen, handleClose, handleLoginFinish }) => {
  const { t, i18n } = useTranslation(['common']);
  const [mobileLoginVisible, setMobileLoginVisible] = useState<boolean>(false);
  const [loginPCVisible, setLoginPCVisible] = useState<boolean>(false);
  const [otherLoginMethod] = useState<OtherLoginWays>(['GOOGLE_ANDROID', 'APPLE']);
  const [bindAccountVisible, setBindAccountVisible] = useState<boolean>(false);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const [params, setParams] = useState<any>({});
  const googleBtnRef = useRef(null);
  const [registType, setRegistType] = useState<'手机' | '微信' | '谷歌' | '谷歌提示' | '苹果'>('谷歌');
  const { googleLoginSDKLoaded, appleLoginSDKLoaded } = useContext(LoginContext);

  const accreditSuccessCallback = async (
    code: string,
    type: 'GOOGLE_ANDROID' | 'APPLE_APP',
    source: 'GOOGLE' | 'APPLE',
  ) => {
    ToastLoading.show(t('加载中...'));
    try {
      const { content } = await loginByPhone({
        oauthType: type,
        code,
      });
      const { errorCode, errorMessage, token, oauthType = '', unionId = '', openId = '', email = '' } = content || {};
      ToastLoading.hide();
      if (errorCode === 409) {
        setParams({ oauthType, unionId, openId });
        setBindAccountVisible(true);
        handleClose();
        return;
      }
      if (errorCode && errorMessage) {
        Toast.info(errorMessage);
        return;
      }
      if (token) {
        setTokenInfo(token);
        if (content?.accountData?.loginOrRegister === 'login') {
          handleLoginFinish();
        } else {
          setRegistType(type === 'GOOGLE_ANDROID' ? '谷歌' : '苹果');
          setSuccessVisible(true);
          handleLoginFinish(true, {
            data: { _event: 'O_RegisterSuccess', logon_method: type === 'GOOGLE_ANDROID' ? '谷歌' : '苹果' },
          });
        }
        subscribe({ email, source });
        handleClose();
      }
    } finally {
      ToastLoading.hide();
    }
  };

  const handleGoogleBtnLClick = throttle(
    useCallback(() => {
      traceEvent({ ak: 'official_mobile', data: { _event: 'O_GoogleClick', popup_name: '注册弹窗' } });
    }, []),
    500,
  );

  const initGoogleLogin = () => {
    window?.google?.accounts?.id?.initialize?.({
      client_id: '539432422945-v4n3mh2o3j6goqhslsggiup6c75jv7ro.apps.googleusercontent.com',
      callback: (res: any) => {
        accreditSuccessCallback(res?.credential, 'GOOGLE_ANDROID', 'GOOGLE');
      },
    });

    window?.google?.accounts?.id?.renderButton?.(googleBtnRef.current, {
      theme: 'outline',
      size: 'large',
      logo_alignment: 'left',
      shape: 'rectangular',
      click_listener: handleGoogleBtnLClick,
    });
  };

  const initAppleLogin = () => {
    window?.AppleID?.auth?.init?.({
      clientId: 'com.wukongedu.wkofficialsite',
      scope: 'email',
      redirectURI: window.location.origin,
      state: 'initial',
      // nonce: '[NONCE]',
      usePopup: true,
    });
  };

  const handleOpenApple = async () => {
    try {
      traceEvent({ ak: 'official_mobile', data: { _event: 'O_AppleClick', popup_name: '注册弹窗' } });
      const data: any = await window?.AppleID?.auth?.signIn?.();
      if (data?.authorization?.id_token) {
        accreditSuccessCallback(data?.authorization?.id_token, 'APPLE_APP', 'APPLE');
      } else {
        console.error('苹果授权登录失败');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (appleLoginSDKLoaded && otherLoginMethod?.includes?.('APPLE')) {
      initAppleLogin();
    }
  }, [appleLoginSDKLoaded]);

  useEffect(() => {
    if (googleLoginSDKLoaded && otherLoginMethod?.includes?.('GOOGLE_ANDROID')) {
      initGoogleLogin();
    }
  }, [googleLoginSDKLoaded]);

  return (
    <>
      <div className={styles.register}>
        <div className={styles.title}>{t('注册')}</div>
        <div
          onClick={() => {
            traceEvent({
              ak: 'official_mobile',
              data: {
                _event: 'O_PhoneClick',
                popup_name: '注册弹窗',
              },
            });
            handleClose();
            setMobileLoginVisible(true);
          }}
          className={classNames(styles.loginBtn, styles.phoneBtn)}
        >
          {t('手机注册')}
        </div>
        {otherLoginMethod?.includes?.('GOOGLE_ANDROID') && googleLoginSDKLoaded && (
          <div className={classNames(styles.loginBtn, styles.googleBtn)}>
            {t('Google注册')}
            <div className={styles.googlContent} ref={googleBtnRef} />
          </div>
        )}
        {otherLoginMethod?.includes?.('APPLE') && appleLoginSDKLoaded && (
          <div onClick={handleOpenApple} className={classNames(styles.loginBtn, styles.appleBtn)}>
            {t('Apple注册')}
          </div>
        )}
        {/* <div className={classNames(styles.loginBtn, styles.facebookBtn)}>{t('Facebook注册')}</div>
      <div className={classNames(styles.loginBtn, styles.wechatBtn)}>{t('微信注册')}</div> */}
        <div className={styles.login}>
          <span style={{ marginRight: 10 }}>{t('已有账号?')}</span>
          <span
            onClick={() => {
              traceEvent({ ak: 'official_mobile', data: { _event: 'O_RegisterClick' } });
              handleClose();
              setLoginPCVisible(true);
            }}
            style={{ color: '#FF5353', cursor: 'pointer' }}
          >
            {t('点击登录')}
          </span>
        </div>
        <div className={styles.privacy}>
          <div>{t('选择任意方式注册即代表您阅读并同意')}</div>
          <div>
            <span
              onClick={() => {
                traceEvent({ ak: 'official_mobile', data: { _event: 'O_UserAgreementClick', popup_name: '注册弹窗' } });
                // window.open('https://student.wukongedu.net/agreement');
                window.open(`${window.location.origin}/${i18n.language}/terms/?layout=no`);
              }}
              className={styles.highlight}
            >
              {t('用户协议')}
            </span>
            <span style={{ margin: '0 5px' }}>{t('和')}</span>
            <span
              onClick={() => {
                traceEvent({ ak: 'official_mobile', data: { _event: 'O_PrivacyPolicyClick', popup_name: '注册弹窗' } });
                // window.open('https://www.wukongsch.com/policy/?layout=no');
                window.open(`${window.location.origin}/${i18n.language}/policy/?layout=no`);
              }}
              className={styles.highlight}
            >
              {t('隐私政策')}
            </span>
          </div>
        </div>
      </div>
      <Modal
        visible={mobileLoginVisible}
        handleClose={() => setMobileLoginVisible(false)}
        handleReturn={() => {
          setMobileLoginVisible(false);
          handleOpen();
        }}
      >
        <MobileRegistForm handleLoginFinish={handleLoginFinish} handleClose={() => setMobileLoginVisible(false)} />
      </Modal>
      <Modal visible={loginPCVisible} handleClose={() => setLoginPCVisible(false)} style={{ height: 572 }}>
        <LoginPC
          handleLoginFinish={handleLoginFinish}
          handleClose={() => setLoginPCVisible(false)}
          handleOpen={() => setLoginPCVisible(true)}
        />
      </Modal>
      <Modal
        visible={bindAccountVisible}
        handleClose={() => setBindAccountVisible(false)}
        handleReturn={() => {
          setBindAccountVisible(false);
          handleOpen();
        }}
      >
        <BindAccountForm
          handleOpen={() => setBindAccountVisible(true)}
          handleClose={() => setBindAccountVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={params}
          bindType="register"
        />
      </Modal>
      <ModalMobile visible={successVisible} handleClose={() => setSuccessVisible(false)} width="90%">
        <RegistSuccess registType={registType} handleLoginFinish={handleLoginFinish} />
      </ModalMobile>
    </>
  );
};

export default Register;
