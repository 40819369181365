import React from 'react';

export interface IContextProps {
  locale: string;
  isLoadCookiePlugins: boolean;
  // kaamel_cookie 判断功能是否启用获取具体适配法律框架
  framework?: { framework?: 'GDPR' | 'CCPA' | ''; finished?: boolean };
  // 谷歌分析同意
  kaamelGAAgree: boolean;
  // gtm同意
  kaamelGTMAgree: boolean;
}
export const MyContext = React.createContext({ isLoadCookiePlugins: false } as IContextProps);
