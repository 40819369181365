import React, { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './style.module.scss';
// eslint-disable-next-line import/no-cycle
import MobileLoginForm from './MobileLoginForm';
// eslint-disable-next-line import/no-cycle
import EmailLoginForm from './EmailLoginForm';

interface Props {
  handleLoginFinish: () => void;
  handleClose: () => void;
  handleOpen: () => void;
  params?: { phone: string[] } | undefined;
}

const LoginPC: FC<Props> = ({ handleLoginFinish, handleClose, handleOpen, params }) => {
  const { t } = useTranslation(['common']);
  const [tabType, setTabType] = useState<'mobile' | 'email'>('mobile');
  const [loginType, setLoginType] = useState<'password' | 'code'>('password');

  return (
    <div className={styles.loginPC}>
      <div className={styles.title}>{t('登录')}</div>
      <div className={styles.tab}>
        <div className={styles.tabPane} onClick={() => setTabType('mobile')}>
          <span
            style={{
              color: tabType === 'mobile' ? '#484C70' : '#8D8FA6',
            }}
          >
            {t('手机')}
          </span>
          <div className={styles.line} style={{ visibility: tabType === 'mobile' ? 'visible' : 'hidden' }} />
        </div>
        <div className={styles.tabPane} onClick={() => setTabType('email')}>
          <span
            style={{
              color: tabType === 'email' ? '#484C70' : '#8D8FA6',
            }}
          >
            {t('邮箱')}
          </span>
          <div className={styles.line} style={{ visibility: tabType === 'email' ? 'visible' : 'hidden' }} />
        </div>
      </div>
      <div style={{ display: tabType === 'mobile' ? 'block' : 'none' }}>
        <MobileLoginForm
          handleLoginFinish={handleLoginFinish}
          handleClose={handleClose}
          handleOpen={handleOpen}
          params={params}
          loginType={loginType}
          setLoginType={setLoginType}
          tabType={tabType}
        />
      </div>
      <div style={{ display: tabType === 'email' ? 'block' : 'none' }}>
        <EmailLoginForm
          handleLoginFinish={handleLoginFinish}
          handleClose={handleClose}
          handleOpen={handleOpen}
          loginType={loginType}
          setLoginType={setLoginType}
          tabType={tabType}
        />
      </div>
    </div>
  );
};

export default LoginPC;
