import React, { useContext } from 'react';
import cls from 'classnames';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';

interface SuccessProps {
  onClose(): void;
  visible: boolean;
}

const Success = ({ visible, onClose }: SuccessProps) => {
  const { t } = useTranslation(['form']);
  const { locale } = useContext(MyContext);
  // const [visible, setVisible] = useState<boolean>(false);
  // 二维码默认中文
  const QR_CODE_CHINESE = 'https://cdnwukong.com/images/form/imgs/public-form-qrcode.png';
  // 二维码默认数学
  // const QR_CODE_MATH = 'https://cdnwukong.com/images/form/imgs/public-form-qrcode.png';
  return (
    <div
      className={cls(styles.mask, {
        [styles.hide]: !visible,
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={cls(styles.container)}>
        <div
          className={styles.closeImg}
          onClick={() => {
            onClose();
          }}
        >
          <Image width={12} height={12} layout="responsive" src="/images/home/close-suc.svg" alt="关闭" />
        </div>
        <div className={styles.containerTitleBg}>
          <Image
            src={locale === 'en' ? '/images/home/success-title-img-en.png' : '/images/home/success-title-img.png'}
            layout="fill"
            objectFit="cover"
            priority
            objectPosition="right top"
            alt="悟空国际数学思维课"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.contentTips} dangerouslySetInnerHTML={{ __html: t('表单-成功页-标题1') }} />
          <div className={styles.qrCodeContent}>
            <img className={styles.ercode} src={QR_CODE_CHINESE} alt="qrcode" />
          </div>

          {locale !== 'en' ? (
            <div className={styles.bottomTips}>
              <span>扫码联系专属客服</span>
              <span>免费领取学习资料</span>
            </div>
          ) : (
            <p className={styles.bottomTips} dangerouslySetInnerHTML={{ __html: t('表单-成功页-标题2') }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Success;
