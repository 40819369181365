import queryString from 'query-string';

const apiUrl = process.env.NEXT_PUBLIC_API_HOST;

export interface Config extends RequestInit {
  token?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: object;
  // 是否显示错误提醒 默认显示
  errorToast?: boolean;
  /**
   * 结果是否转为json
   */
  toJson?: boolean;
  // 请求地址
  apiPrefix?: string;
  headers?: any;
}

const serverRequest = async <T>(
  endpoint: string,
  { data, token, headers, toJson = true, apiPrefix = '', ...customConfig }: Config = {},
): Promise<T> => {
  const config = {
    method: 'GET',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': data ? 'application/json' : '',
      ...headers,
    },
    ...customConfig,
  };

  if (config.method.toUpperCase() === 'GET') {
    if (data) {
      // eslint-disable-next-line no-param-reassign
      endpoint += `?${queryString.stringify(data)}`;
    }
  } else {
    config.body = JSON.stringify(data || {});
  }
  return fetch((endpoint.startsWith('http') && endpoint) || `${apiPrefix || apiUrl}${endpoint}`, config).then(
    async (response) => {
      let resData;
      if (toJson) {
        resData = await response.json();
      } else {
        resData = await response.text();
      }
      if (response.ok) {
        return <T>resData;
      }
      console.error('接口:', endpoint);
      console.error('请求参数:', data);
      console.error('错误消息:', resData?.message);
      return Promise.reject(resData);
    },
  );
};
export default serverRequest;
