import { sendEventTracking, EventTrackingReq } from '../request/booking';

// 功能：埋点上报 ，掉接口，函数参数：埋点名称，埋点参数，是否需要重复打印（默认不重复）
// name `${页面名称}_${事件名}_${l参数}`
export const report = (name: string, params: EventTrackingReq, repeat: boolean = false) => {
  // 如果不需要重复，并且localStorage没有有值， 则上报
  if (!repeat && !localStorage.getItem(name)) {
    localStorage.setItem(name, '1');
    // console.log('上报事件_不重复', name, params);
    sendEventTracking(params);
    // 需要重复上报
  } else if (repeat) {
    // console.log('上报事件_重复', name, params);
    sendEventTracking(params);
  }
};
// 判断 后端表单填错
export const formMessageIsErr = (msg: any) => {
  if (msg) {
    return /手机|格式|phone|mobile/.test(msg);
  }
  return false;
};
