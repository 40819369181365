import { create } from 'zustand';
import { StudentInfo } from '@/request/interface/login';

export interface UserState {
  user: StudentInfo | Record<string, string>;
  setUser: (user: StudentInfo) => void;
}

const useUserStore = create<UserState>((set) => ({
  user: {},
  setUser: (user: StudentInfo) => set({ user }),
}));

export default useUserStore;
