import React, { CSSProperties, FC, MouseEventHandler } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

interface Props {
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

const Button: FC<Props> = ({ onClick, style, className, children, disabled = false }) => {
  return (
    <div
      className={classNames(styles.button, className)}
      onClick={disabled ? () => {} : onClick}
      style={{ backgroundColor: disabled ? '#FFCFCF' : 'rgba(255, 83, 83, 1)', ...style }}
    >
      {children}
    </div>
  );
};

export default Button;
