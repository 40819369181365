import React from 'react';

const Mobile: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  return React.cloneElement(children, {
    ...children.props,
    className: `${children.props.className || ''} show-mobile hide-pc`,
  });
};

export default Mobile;
