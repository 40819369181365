/* eslint-disable import/prefer-default-export */
export const GTM_ARGUS = {
  id: 'GTM-W5L3TFB',
};

export const DISABLE_LAYOUT_PATHS = [
  '/download?enter=app',
  '/download/?enter=app',
  '/policy?layout=no',
  '/policy/?layout=no',
  '/terms/?layout=no',
];

export const DISABLE_TABOOLA_PATHS = ['/policy', '/terms'];

export const EMAIL_REG = /^[\w-.]+@[\w-.]+\.[a-zA-Z]{2,}$/;
export const PHONE_REG = /^[0-9\\-]{4,15}$/;

export const REPORT_PAGE_NAME_OFFICIAL_WEBSITE = '官网';

// 中文首页
export const REPORT_PAGE_NAME_OFFICIAL_WEBSITE_CHINESE_INDEX = '官网_CHINESE_INDEX';
// 数学首页
export const REPORT_PAGE_NAME_OFFICIAL_WEBSITE_MATH_INDEX = '官网_MATH_INDEX';

export const LEAD_CHINESE_EN = 'b6711d4e-6de4-4465-a4bd-3dca3d3e8c88';
export const LEAD_CHINESE_ZH = '8ac58659-6601-4ba7-af9e-62346a7b06cd';
export const BLOG_URL = process.env.NEXT_PUBLIC_BLOG_URL || 'https://wp-more.wukongedu.net/blog';

export const TOKEN = 'token';
export const EDU_URL = '.wukongedu.net';
export const SCH_URL = '.wukongsch.com';

export const WECHAT_QR_HOST = 'https://open.weixin.qq.com/connect/qrconnect';
export const WECHAT_APPID = process.env.NEXT_PUBLIC_WECHAT_APPID || 'wxf3acbfbbdef5ddcd';
export const AUTH_REDIRECT_URI = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI || '';
export const POST_MESSAGE_ID = 'd3Vrb25nbXNn';
export const DATE_FORMAT = 'YYYY-MM-DD';
