import React from 'react';
import ReactDOM from 'react-dom';
import styles from './style.module.scss';

interface LoadingProps {
  content: string;
}

let modalContainer: HTMLDivElement;

const LoadingComponents: React.FC<LoadingProps> = ({ content }) => {
  return (
    <div className={styles.loadContent}>
      <div className={styles.loadContainer}>
        <div className={styles.loading} />
        <p className={styles.content}>{content}</p>
      </div>
    </div>
  );
};

const renderLoading = (props: LoadingProps) => {
  modalContainer = document.createElement('div');
  ReactDOM.render(<LoadingComponents {...props} />, modalContainer);
  document.body.append(modalContainer);
};

const hideLoading = () => {
  if (modalContainer) {
    try {
      ReactDOM.unmountComponentAtNode(modalContainer);
      document.body.removeChild(modalContainer);
    } catch (error) {
      console.log(error);
    }
  }
};
const LoadingModal = {
  show(content: string) {
    return renderLoading({ content });
  },
  hide() {
    return hideLoading();
  },
};

export default LoadingModal;
