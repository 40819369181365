/* eslint-disable react/react-in-jsx-scope */
import ReactDOM from 'react-dom';
import errorIcon from './assets/error.svg';
import successIcon from './assets/success.svg';
import styles from './style.module.scss';

interface ToastProps {
  content: any;
  type: 'success' | 'error' | 'info';
}

const IconMap: Record<ToastProps['type'], any> = {
  info: null,
  success: <img src={successIcon.src} alt="" />,
  error: <img src={errorIcon.src} alt="" />,
};

const ToastComponents: React.FC<ToastProps> = ({ content, type }) => {
  const icon = IconMap[type];
  return (
    <div className={styles.toastContainer}>
      <div className={styles.toast}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <span>{content}</span>
      </div>
    </div>
  );
};

const renderToast = (props: ToastProps) => {
  const root = document.createElement('div');
  ReactDOM.render(<ToastComponents {...props} />, root);
  document.body.append(root);
  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(root);
    document.body.removeChild(root);
  }, 3e3);
};

const Toast = {
  info(content: string) {
    return renderToast({ content, type: 'info' });
  },
  success(content: string) {
    return renderToast({ content, type: 'success' });
  },
  error(content: string) {
    return renderToast({ content, type: 'error' });
  },
};

export default Toast;
