// 落地页可配置
import { request } from '@/request/index';

export interface AllIDReq {}

export interface AllIDRes {
  message: number;
}

export interface ConfigurableReq {
  id: string;
}

export interface ConfigurableRes {
  data: {
    message: string;
    content: boolean;
  };
}

interface GetLessonAvailableListReq {
  subject: string;
  timezone: string;
  operator: string;
  language?: string;
  chineseLevel?: string;
  grade?: string;
  country?: string;
}

export interface TimeZoneProps {
  content: {
    label: string;
    value: string;
  }[];
}

export type GetLessonAvailableListRes = {
  dayOfWeek: number;
  active: boolean;
  date: string;
  day: string;
  month: string;
  timeList: {
    startTime: string;
  }[];
  week: string;
  canSelect?: boolean;
  // 是否是圣诞
  isChristmas?: boolean;
}[];

// 约课
export interface SubmitReserveReq {
  age?: string;
  cookieId?: string;
  country?: string;
  countryCode?: string;
  courseDate: string;
  courseScheduleId?: string;
  courseTime: string;
  email?: string;
  firstName: string;
  grade?: string;
  language?: string;
  lastName?: string;
  lead?: string;
  operation?: string;
  phone?: string;
  reseller?: string;
  school?: string;
  subject: string;
  timeZoneText?: string;
  wechatId?: string;
  verificationCode?: string;
  studentCode?: string;
  source?: string;
  ChineseLevel?: string;
  city?: string;
  timeZoneStandard?: string;
  captchaVerification?: string;
}

export interface SubmitReserveRes {
  success: string;
  content: {
    until: number;
    errorCode: number;
  };
}

interface ReqCheckRes {
  repCode?: string;
  repData?: {
    captchaType?: string;
    token?: string;
    result?: boolean;
    opAdmin?: boolean;
  };
  success?: boolean;
  error?: boolean;
}

interface ReqCheckReq {
  captchaType?: string;
  pointJson?: string; // aes加密坐标信息
  token?: string; // get请求返回的token
}

interface GetPictureReq {
  captchaType?: string; // 验证码类型 clickWord
  clientUid?: string; // 客户端UI组件id,组件初始化时设置一次，UUID（非必传参数）
  ts?: number;
}
interface GetPictureRes {
  repCode?: string;
  repData?: {
    originalImageBase64?: string;
    point?: {
      // 默认不返回的，校验的就是该坐标信息，允许误差范围
      x?: number;
      y?: number;
    };
    jigsawImageBase64?: string;
    token?: string; // 一次校验唯一标识
    result?: string;
    opAdmin?: string;
    secretKey?: string;
  };
  success?: boolean;
  error?: boolean;
  repMsg?: string;
}

export interface EventTrackingReq {
  // 事件名称
  event: string;
  l?: string;
  // 页面版本
  landingPageVersion: string;
  // 有就传1 没有传null
  pv?: number | null;
  // 科目 默认  'CHINESE' | 'MATH'
  subject: string;
  // 有就传1 没有传null
  uv?: number | null;
  // 页面原始链接 pv时候传递
  originalUrl?: string;
  // 加载时间相关
  loadTime?: any;
  // 表单验证出错时 发送的参数
  leadFail?: {
    mobile?: string;
    message?: string;
    age?: string;
    email?: string;
    name?: string;
  };
  // 额外的数据 都放在这
  data?: {
    [eventData: string]: any;
    // 语言 :en / zh
    language?: string;
  };
}

interface ICreateTokenSignReq {
  // 验证码
  verificationCode?: string;
  // Chinese/Math
  subject?: string;
  // 区号
  countryCode?: string;
  mobile?: string;
  // 国家代码
  country?: string;
  // 学生id
  studentId?: string;
  email?: string;
}

interface ICreateTokenSignRes {
  token?: string;
  sign?: string;
}

export interface EventTrackingRes {
  msg: string;
  success: boolean;
}

export const submitReserve = (params: SubmitReserveReq) => {
  return request
    .post<SubmitReserveRes, SubmitReserveRes>('/public/reserve/v2/reserve/mobile', params, {
      skipErrorHandler: true,
    })
    .then((res) => res);
};

// 获取token进入学员中心
export const createTokenSign = (params: ICreateTokenSignReq) => {
  return request.post<ICreateTokenSignRes, ICreateTokenSignRes>('/public/create/token_sign', params);
};

export const getLessonAvailableList = (params: GetLessonAvailableListReq) => {
  return request.post<GetLessonAvailableListRes, GetLessonAvailableListRes>(
    '/public/reserve/v2/available_time',
    params,
  );
};

export const getTimeZoneList = () => {
  return request.get<TimeZoneProps, TimeZoneProps>(`/public/ref/timezones/ddl`);
};

export const getTimeZoneZhList = () => {
  return request.get<TimeZoneProps, TimeZoneProps>(`/public/ref/countries/zh/ddl`);
};

export const getPicture = (data: GetPictureReq) => {
  return request.post<GetPictureRes, GetPictureRes>(`/captcha/get`, data).then((res) => res);
};

export const reqCheck = (data: ReqCheckReq) => {
  return request.post<ReqCheckRes, ReqCheckRes>(`/captcha/check`, data).then((res) => res);
};

export const sendEventTracking = (params: EventTrackingReq) => {
  return request.post<EventTrackingRes, EventTrackingRes>(`/public/eventTracking/upload`, {
    ...params,
  });
};
