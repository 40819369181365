var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0vq4U-ynDaXrAOM6JnlqK"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/public/wk-official-site";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const VERCEL_ENV = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_STAGE;

Sentry.init({
  dsn: SENTRY_DSN || 'https://be5e6ff1a0794d679a70357278328bb3@o894200.ingest.sentry.io/5860519',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler(samplingContext) {
    // disable pageload transaction
    if (samplingContext.transactionContext.op === 'pageload') {
      return 0.005;
    }

    return 0.2;
  },
  ignoreErrors: [/Cannot read property 'im_guest_enable' of undefined/i],
  enabled: VERCEL_ENV === 'prod',

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
