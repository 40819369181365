import { parse } from 'qs';
// eslint-disable-next-line import/no-cycle
import { setSameDomain } from './token';

// eslint-disable-next-line import/prefer-default-export
export const generateId = (): any => {
  const cookieId = localStorage.getItem('cookie_id');
  if (!cookieId) {
    const newCookieId = `cookie_id${new Date().getTime()}`;
    localStorage.setItem('cookie_id', newCookieId);
  }
  return cookieId;
};

export const getQueryString = (name: string): any => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};

// 获取最大和最小的 随机整数
export const randomIntFromInterval = (min: number, max: number): number => {
  // min和max为范围的最小值和最大值
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isProduction = () => process.env.NEXT_PUBLIC_STAGE === 'prod';

interface Env {
  prod: string;
  test: string;
  develop: string;
}

// const envOrigin = {
//   prod: 'https://www.wukongsch.com',
//   test: 'https://wwww-test.wukongedu.net',
//   develop: 'https://wwww-dev.wukongedu.net',
// };

const stuEnvOrigin = {
  prod: 'https://student.wukongsch.com',
  prod_edu: 'https://student.wukongedu.net',
  test: 'https://student-test.wukongedu.net',
  develop: 'https://student-dev.wukongedu.net',
};

export const getStuCenterUrl = (from?: string, router?: string, hasQuery?: boolean) => {
  setSameDomain();
  const fromParam = from || 'official_website';
  const lang = window.location.href.includes('/zh/') ? 'zh' : 'en';
  let origin = stuEnvOrigin[(process.env.NEXT_PUBLIC_STAGE as keyof Env) || 'develop'];
  if (process.env.NEXT_PUBLIC_STAGE === 'prod' && window.location.hostname.includes('wukongedu.net')) {
    origin = stuEnvOrigin.prod_edu;
  }
  return `${origin}/${lang}/${router || ''}${hasQuery ? '&' : '?'}from=${fromParam}`;
};

export const isIOS = () => {
  const { userAgent } = navigator;
  return /iPhone|iPad|iPod/i.test(userAgent);
};

export const getLoginJumpUrl = (from?: string) => {
  const fromParam = from || 'official_website';
  const { return_url: returnUrl } = parse(window?.location?.search?.slice(1) || '');
  if (returnUrl) {
    setSameDomain();
    let decodeUrl = decodeURIComponent(returnUrl as string);
    // 如果是学员嵌入互动作业地址，直接跳学员中心首页
    if (decodeUrl.includes('/iexercise?')) {
      return getStuCenterUrl();
    }

    const lang = window.location.href.includes('/zh/') ? 'zh' : 'en';

    // 如果returnUrl上无语言信息，需要强当前语言信息加上
    if (!decodeUrl.includes(`/en`) && !decodeUrl.includes(`/zh`)) {
      const origin =
        isProduction() && window.location.hostname.includes('wukongsch.com') ? 'wukongsch.com' : 'wukongedu.net';
      decodeUrl = decodeUrl.replace(`${origin}`, `${origin}/${lang}`);
    }
    return decodeUrl.includes('?') ? `${decodeUrl}&from=${fromParam}` : `${decodeUrl}?from=${fromParam}`;
  }
  return getStuCenterUrl();
};

/**
 *通过url获取文件名
 *
 * @param {string} url
 * @return {*}  {string}
 */
export const getFileNameFromUrl = (url: string): string => {
  const lastSlashIndex = url?.lastIndexOf?.('/');
  return url?.substring?.(lastSlashIndex + 1);
};

/**
 * PC端下载文件
 * @param {String} path - 下载地址/下载请求地址。
 * @param {String} fileName - 下载文件的名字（考虑到兼容性问题，最好加上后缀名）
 */
export const downloadFileForPC = (url: string, fileName?: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName || getFileNameFromUrl(url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

/**
 * 移动端下载文件
 * @param {String} path - 下载地址/下载请求地址。
 */
export const downloadFileForMobile = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 将单词首字母变为大写
 *
 * @param {string} string
 * @return {*}
 */
export const capitalizeFirstLetter = (string: string) => {
  // 如果字符串为空，则返回空字符串
  if (!string) {
    return '';
  }

  // 将字符串的第一个字母转换为大写，并将其与字符串的其余部分连接起来
  return string[0].toUpperCase() + string.slice(1);
};

/**
 * 将秒数转换为时分秒格式
 *
 * @param {number} seconds
 * @return {*}
 */
export const formatSecondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (formattedHours === '00') {
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * 根据视频URL获取视频时长
 *
 * @param {string} fileUrl
 * @param {(time: string) => void} callback
 */
export const getVideoTime = (fileUrl: string, callback: (time: string) => void) => {
  const video = document.createElement('video');
  video.src = fileUrl as string;
  video.addEventListener('loadedmetadata', () => {
    if (!video?.duration) {
      return;
    }
    const time = formatSecondsToTime(video.duration);
    callback(time);
  });
};

/**
 * 根据音频URL获取音频时长
 *
 * @param {string} fileUrl
 * @param {(time: string) => void} callback
 */
export const getAudioTime = (fileUrl: string, callback: (time: string) => void) => {
  const audio = new Audio();
  audio.src = fileUrl;

  audio.addEventListener('loadedmetadata', () => {
    if (!audio?.duration) {
      return;
    }
    const time = formatSecondsToTime(audio.duration);
    callback(time);
  });
};

/**
 * 判断是否是PC设备
 *
 */
export const isPC = (userAgent?: string) =>
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent || globalThis?.navigator?.userAgent || '',
  );
