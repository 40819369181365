// 中文年龄
export const AGE_CHINESE_ZH = [
  {
    label: '3岁',
    value: '3',
  },
  {
    label: '4岁',
    value: '4',
  },
  {
    label: '5岁',
    value: '5',
  },
  {
    label: '6岁',
    value: '6',
  },
  {
    label: '7岁',
    value: '7',
  },
  {
    label: '8岁',
    value: '8',
  },
  {
    label: '9岁',
    value: '9',
  },
  {
    label: '10岁',
    value: '10',
  },
  {
    label: '11岁',
    value: '11',
  },
  {
    label: '12岁',
    value: '12',
  },
  {
    label: '13岁',
    value: '13',
  },
  {
    label: '14岁',
    value: '14',
  },
  {
    label: '15岁',
    value: '15',
  },
  {
    label: '16岁',
    value: '16',
  },
  {
    label: '17岁',
    value: '17',
  },
  {
    label: '18岁',
    value: '18',
  },
];
export const AGE_CHINESE_EN = [
  {
    label: '3 years old',
    value: '3',
  },
  {
    label: '4 years old',
    value: '4',
  },
  {
    label: '5 years old',
    value: '5',
  },
  {
    label: '6 years old',
    value: '6',
  },
  {
    label: '7 years old',
    value: '7',
  },
  {
    label: '8 years old',
    value: '8',
  },
  {
    label: '9 years old',
    value: '9',
  },
  {
    label: '10 years old',
    value: '10',
  },
  {
    label: '11 years old',
    value: '11',
  },
  {
    label: '12 years old',
    value: '12',
  },
  {
    label: '13 years old',
    value: '13',
  },
  {
    label: '14 years old',
    value: '14',
  },
  {
    label: '15 years old',
    value: '15',
  },
  {
    label: '16 years old',
    value: '16',
  },
  {
    label: '17 years old',
    value: '17',
  },
  {
    label: '18 years old',
    value: '18',
  },
];

// 数学年龄
export const AGE_MATH_ZH = [
  {
    label: '6岁',
    value: '6',
  },
  {
    label: '7岁',
    value: '7',
  },
  {
    label: '8岁',
    value: '8',
  },
  {
    label: '9岁',
    value: '9',
  },
  {
    label: '10岁',
    value: '10',
  },
  {
    label: '11岁',
    value: '11',
  },
  {
    label: '12岁',
    value: '12',
  },
  {
    label: '13岁',
    value: '13',
  },
  {
    label: '14岁',
    value: '14',
  },
  {
    label: '15岁',
    value: '15',
  },
];

export const AGE_MATH_EN = [
  {
    label: '6 years old',
    value: '6',
  },
  {
    label: '7 years old',
    value: '7',
  },
  {
    label: '8 years old',
    value: '8',
  },
  {
    label: '9 years old',
    value: '9',
  },
  {
    label: '10 years old',
    value: '10',
  },
  {
    label: '11 years old',
    value: '11',
  },
  {
    label: '12 years old',
    value: '12',
  },
  {
    label: '13 years old',
    value: '13',
  },
  {
    label: '14 years old',
    value: '14',
  },
  {
    label: '15 years old',
    value: '15',
  },
];

// 英语年龄中文
export const AGE_ENGLISH_ZH = [
  {
    label: '5岁',
    value: '5',
  },
  {
    label: '6岁',
    value: '6',
  },
  {
    label: '7岁',
    value: '7',
  },
  {
    label: '8岁',
    value: '8',
  },
  {
    label: '9岁',
    value: '9',
  },
  {
    label: '10岁',
    value: '10',
  },
  {
    label: '11岁',
    value: '11',
  },
  {
    label: '12岁',
    value: '12',
  },
];

// 英语年龄英文
export const AGE_ENGLISH_EN = [
  {
    label: '5 years old',
    value: '5',
  },
  {
    label: '6 years old',
    value: '6',
  },
  {
    label: '7 years old',
    value: '7',
  },
  {
    label: '8 years old',
    value: '8',
  },
  {
    label: '9 years old',
    value: '9',
  },
  {
    label: '10 years old',
    value: '10',
  },
  {
    label: '11 years old',
    value: '11',
  },
  {
    label: '12 years old',
    value: '12',
  },
];

export const SUBJECT_EN = [
  {
    label: 'Chinese',
    value: 'Chinese',
  },
  {
    label: 'Math',
    value: 'Math',
  },
  {
    label: 'English',
    value: 'English',
  },
];

export const SUBJECT_ZH = [
  {
    label: '中文',
    value: 'Chinese',
  },
  {
    label: '数学',
    value: 'Math',
  },
  {
    label: '英文',
    value: 'English',
  },
];

export const GRADE = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
];

export const CHINESE_LEVEL = [
  {
    label: '无中文学习经验',
    value: 'A',
  },
  {
    label: '零基础，听说流利',
    value: 'B',
  },
  {
    label: '<1年学习时间',
    value: 'C',
  },
  {
    label: '1-2年学习经验',
    value: 'D',
  },
  {
    label: '2+年学习经验',
    value: 'E',
  },
  {
    label: '中文为母语',
    value: 'F',
  },
];

export const CHINESE_LEVEL_EN = [
  {
    label: 'No background',
    value: 'A',
  },
  {
    label: 'Can talk about simple topics',
    value: 'B',
  },
  {
    label: '<1 year of study',
    value: 'C',
  },
  {
    label: '1-2 years of study',
    value: 'D',
  },
  {
    label: '>2 years of study',
    value: 'E',
  },
  {
    label: 'Native speaker',
    value: 'F',
  },
];

export const WEEK = [
  {
    zh: '周一',
    en: 'Mon',
    value: 1,
  },
  {
    zh: '周二',
    en: 'Tue',
    value: 2,
  },
  {
    zh: '周三',
    en: 'Wed',
    value: 3,
  },
  {
    zh: '周四',
    en: 'Thu',
    value: 4,
  },
  {
    zh: '周五',
    en: 'Fri',
    value: 5,
  },
  {
    zh: '周六',
    en: 'Sat',
    value: 6,
  },
  {
    zh: '周日',
    en: 'Sun',
    value: 7,
  },
];

export const MONTH = [
  {
    zh: '1月',
    en: 'Jan',
    value: 1,
  },
  {
    zh: '2月',
    en: 'Feb',
    value: 2,
  },
  {
    zh: '3月',
    en: 'Mar',
    value: 3,
  },
  {
    zh: '4月',
    en: 'Apr',
    value: 4,
  },
  {
    zh: '5月',
    en: 'May',
    value: 5,
  },
  {
    zh: '6月',
    en: 'Jun',
    value: 6,
  },
  {
    zh: '7月',
    en: 'Jul',
    value: 7,
  },
  {
    zh: '8月',
    en: 'Aug',
    value: 8,
  },
  {
    zh: '9月',
    en: 'Sep',
    value: 9,
  },
  {
    zh: '10月',
    en: 'Oct',
    value: 10,
  },
  {
    zh: '11月',
    en: 'Nov',
    value: 11,
  },
  {
    zh: '12月',
    en: 'Dec',
    value: 12,
  },
];
