import classNames from 'classnames';
import React from 'react';

import styles from './style.module.scss';

const MenuIcon = React.memo<{ open: boolean; className?: string }>(({ open, className }) => (
  <div className={classNames(styles.menuIcon, open && styles.open, className)}>
    <i />
    <i />
    <i />
  </div>
));

export default MenuIcon;
