import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import RegistSuccessImg from '@public/images/login/regist_success.svg';
import Image from 'next/legacy/image';
import styles from './style.module.scss';
import Button from '../Button';
import { getStuCenterUrl } from '@/utils/utils';
import { LoginBtnReportParams } from '@/request/interface/login';

interface Props {
  registType: '手机' | '微信' | '谷歌' | '谷歌提示' | '苹果';
  handleLoginFinish: (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => void;
}

const RegistSuccess: FC<Props> = ({ registType, handleLoginFinish }) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.registSuccess}>
      {registType === '手机' && (
        <>
          <div className={styles.title}>{t('恭喜领取中文体验课!')}</div>
          <div className={styles.subTitle}>{t('我们将尽快与您联系')}</div>
        </>
      )}
      {registType !== '手机' && <div className={styles.title}>{t('欢迎加入悟空中文!')}</div>}
      <div className={styles.img}>
        <Image src={RegistSuccessImg} layout="responsive" />
      </div>
      <Button
        onClick={() => {
          handleLoginFinish(true, {
            data: { _event: 'O_RegisterSuccessGoCenter', logon_method: registType },
          });
          window.open(getStuCenterUrl());
        }}
      >
        {t('进入学员中心')}
      </Button>
    </div>
  );
};

export default RegistSuccess;
