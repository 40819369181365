import ReactDOM from 'react-dom';
import classNames from 'classnames';
import React from 'react';
import styles from './style.module.scss';

interface LoadingProps {
  content: string;
  size?: 'm' | 'l';
  className?: string;
  contentSupplement?: string;
}

let modalContainer: HTMLDivElement;

const LoadingComponents: React.FC<LoadingProps> = ({ content, size = 'm', className, contentSupplement }) => {
  return (
    <div className={styles.loadContent}>
      <div className={classNames(styles.loadContainer, styles[`loadContent-${size}`], className)}>
        <div className={styles.loading} />
        <div className={styles.content}>{content}</div>
        {contentSupplement && <div className={styles.contentSupplement}>{contentSupplement}</div>}
      </div>
    </div>
  );
};

const renderLoading = (props: LoadingProps) => {
  modalContainer = document.createElement('div');
  // eslint-disable-next-line react/react-in-jsx-scope
  ReactDOM.render(<LoadingComponents {...props} />, modalContainer);
  document.body.append(modalContainer);
};

const hideLoading = () => {
  if (modalContainer) {
    try {
      ReactDOM.unmountComponentAtNode(modalContainer);
      document.body.removeChild(modalContainer);
    } catch (error) {
      console.log(error);
    }
  }
};
const Loading = {
  show(content: string, props?: Omit<LoadingProps, 'content'>) {
    return renderLoading({ content, ...props });
  },
  hide() {
    return hideLoading();
  },
};

export default Loading;
