import { create } from 'zustand';
import { GetNavListResponse } from '@/request/interface/resources';
import { BlogPost } from '@/interface/home';

export interface ResourcesState {
  navList: GetNavListResponse['content'] | null;
  setNavList: (navList: GetNavListResponse['content']) => void;
  blogList: BlogPost[] | null;
  setBlogList: (blogList: BlogPost[]) => void;
}

const useResourcesStore = create<ResourcesState>((set) => ({
  navList: null,
  setNavList: (navList: GetNavListResponse['content']) => set({ navList }),
  blogList: null,
  setBlogList: (blogList: BlogPost[]) => set({ blogList }),
}));

export default useResourcesStore;
