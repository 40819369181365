/* eslint-disable @typescript-eslint/dot-notation */
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cls from 'classnames';
import Cookies from 'js-cookie';
import bg from '@public/icon/mobile-nav-background.png';
// import logoutSVG from '@public/images/login/logout.svg';
import Image from 'next/legacy/image';
import Script from 'next/script';
import Head from 'next/head';
import { traceEvent } from '@wk/wk-gatherer';
import jsonwebtoken from 'jsonwebtoken';
import Select from './components/Select';
import useGTM from '@/hooks/useGtag';
import { generateNamePrefix } from '@/hooks/helpers';
// 通过 dynamic 引入 线上老数据 会引起点击 打开form后 切换 页面 js未清除 css未加载问题 必须刷新后才会 ，原因是切换页面nav不会重新加载；
// 通过点击 再加载 form 如果网络差 点击form会加载很久 取消了点击再加载
// 最近 页面 始终引入form；点击切换的时候显示影藏
import Form, { FormRefProps } from '@/components/Form';

import styles from './style.module.scss';
import MenuIcon from './components/MenuIcon';
import generatePaths from './generatePaths';
import LoginPC from '@/components/LoginPC';
import Modal from '../Modal';
import { getStudentList } from '@/request/login';
import cacheStorage from '@/utils/cacheStorage';
import { EDU_URL, SCH_URL, TOKEN } from '@/utils/constants';
import { LoginBtnReportParams, StudentInfo } from '@/request/interface/login';
import { hasTokenInfo, removeTokenInfo } from '@/utils/token';
import Drawer from '../Drawer';
import LoginMobile from '../LoginMobile';
import Logout from '../LoginMobile/Logout';
import { getLoginJumpUrl, getStuCenterUrl, isProduction } from '@/utils/utils';
import GoogleAutoLogin from '../LoginPC/GoogleAutoLogin';
import LoginContext from '@/context/LoginContext';
import useIsMobile from '@/hooks/useIsMobile';
import useIsPC from '@/hooks/useIsPC';
import { getNavList } from '@/request/resources';
import { GetNavListResponse } from '@/request/interface/resources';
import eventBus from '@/utils/eventBus';
import useUserStore from '@/store/useUserStore';
import useResourcesStore from '@/store/useResourcesStore';

interface NavProps {
  className?: string;
}

const Nav: React.FC<NavProps> = ({ className }) => {
  const { t } = useTranslation(['common', 'path', 'login']);
  const router = useRouter();
  const { locale } = router;
  const [openInMobile, setOpenInMobile] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [student, setStudent] = useState<StudentInfo>();
  const [loginPCVisible, setLoginPCVisible] = useState<boolean>(false);
  const [loginMobileVisible, setLoginMobileVisible] = useState<boolean>(false);
  const [logoutDrawerVisible, setLogoutDrawerVisible] = useState<boolean>(false);
  const openInMobileRef = useRef(false);
  const keyRef = useRef(0);
  const sendDataToGTM = useGTM();
  const prefixName = generateNamePrefix(`Header_`);
  const [isLogin, setIsLogin] = useState<boolean>(hasTokenInfo());
  const [googleLoginSDKLoaded, setGoogleLoginSDKLoaded] = useState<boolean>(false);
  const [appleLoginSDKLoaded, setAppleLoginSDKLoaded] = useState<boolean>(false);
  const childRef = useRef<FormRefProps>(null);
  const [showForm, setShowForm] = useState(false);
  const setUser = useUserStore((state) => state.setUser);
  const { setNavList, resourceNavList } = useResourcesStore((state) => {
    return { setNavList: state.setNavList, resourceNavList: state.navList };
  });

  const isMobile = useIsMobile();
  const isPC = useIsPC();

  useEffect(() => {
    getNavList().then((navList) => {
      setNavList(navList as GetNavListResponse['content']);
    });
  }, []);

  const handleGA = (e: React.MouseEvent, gaName?: string, label?: string) => {
    if (gaName) {
      e.stopPropagation();
      sendDataToGTM(prefixName`${gaName}`);
      traceEvent({
        data: {
          _event: 'O_NavigationBarClick',
          tab_name: label || gaName,
          page_position: window.location.href,
        },
      });
    }
  };

  const handleMenuButtonClick = useCallback(() => {
    setOpenInMobile(!openInMobile);
    openInMobileRef.current = !openInMobile;
  }, [openInMobile]);

  const handleLanguageClick = useCallback(() => {
    traceEvent({
      data: {
        _event: 'O_LanguageClick',
        button_name: locale === 'en' ? 'EN' : '中文',
        page_position: window.location.href,
      },
    });
    setShowLanguage(!showLanguage);
  }, [showLanguage]);

  const logout = () => {
    traceEvent({
      ...(window.innerWidth <= 720 ? { ak: 'official_mobile' } : {}),
      data: { _event: 'O_OfficialWebLogOut' },
    });
    setStudent(undefined);
    setIsLogin(false);
    removeTokenInfo();
    window?.Intercom?.('shutdown');
    window.location.reload();
  };

  const getStudents = async (loginBtnReportParams?: LoginBtnReportParams) => {
    if (!hasTokenInfo()) {
      setIsLogin(false);
      return;
    }
    setIsLogin(true);
    const results = await getStudentList();
    const currentStudent = results?.[0];
    setStudent(currentStudent);
    setUser(currentStudent);
    if (currentStudent.uuid && currentStudent.hmacSHA256AccountId) {
      const {
        uuid,
        fullName,
        hmacSHA256AccountId,
        data,
        chinesePayStatus,
        englishPayStatus,
        mathPayStatus,
        country,
        code,
        payStatus,
      } = currentStudent || {};
      // 为 intercom 客服系统设置登录态
      const subjects = [];
      if (chinesePayStatus === 'PAID') {
        subjects.push('Chinese');
      }
      if (englishPayStatus === 'PAID') {
        subjects.push('ELA');
      }
      if (mathPayStatus === 'PAID') {
        subjects.push('Math');
      }
      window?.Intercom?.('update', {
        user_id: uuid,
        name: fullName,
        Country: country,
        City: data?.city,
        Subject: subjects.join(','),
        StudentCode: code,
        Language: locale === 'en' ? 'EN' : 'ZH',
        PayStatus: payStatus,
        user_hash: hmacSHA256AccountId,
      });
    }
    if (loginBtnReportParams?.data) {
      const token = cacheStorage.getItem(TOKEN);
      const decoded = jsonwebtoken.decode(token);
      traceEvent({
        ...(window.innerWidth <= 720 ? { ak: 'official_mobile' } : {}),
        data: {
          ...loginBtnReportParams?.data,
          account_id: results?.[0]?.uuid || decoded?.sub,
        },
      });
      if (loginBtnReportParams?.data?.['_event'] === 'O_RegisterSuccessGoCenter') {
        window.location.reload();
      }
    }
  };

  // 监听路由变化 避免在移动端点击了跳转地址 但导航未关闭
  useEffect(() => {
    getStudents();
    const handleRouteChange = () => {
      setShowLanguage(false);
      setShowForm(false);
      if (openInMobileRef.current) {
        setOpenInMobile(false);
        // TODO 优化 关闭展开的按钮
        keyRef.current += 1;
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const setCookie = (localeData: string) => {
    // 删除子域名语言，避免老用户cookie下存在2个NEXT_LOCALE
    Cookies.remove('NEXT_LOCALE');
    Cookies.set('NEXT_LOCALE', localeData, {
      domain: !isProduction() ? EDU_URL : SCH_URL,
      path: '/',
    });
  };

  const navConfigs = useMemo(() => generatePaths(t), [t]);

  const handleLanguageSelect = (option: { value: string }) => {
    if (locale === option.value) return;
    traceEvent({
      data: {
        _event: 'O_LanguageListClick',
        list_name: option.value === 'en' ? 'EN' : '中文',
      },
    });
    setCookie(option.value);
    sendDataToGTM(prefixName`${option.value}`);
    const query = router.query;
    const { return_url: returnUrl } = query;
    let ap = router.asPath
    if (returnUrl) {
      const isEn = option.value === 'en';
      if (isEn) {
        ap = ap.replaceAll(encodeURIComponent('/zh'), '');
      } else {
        if (ap.indexOf(encodeURIComponent('/en')) >= 0) {
          ap = ap.replaceAll(encodeURIComponent('/en'), encodeURIComponent('/zh'));
        } else {
          if (ap.indexOf(encodeURIComponent('/zh')) < 0) {
            ap = ap + encodeURIComponent('/zh');
          }
        }
      }
    }
    router.replace(router.pathname, ap, { locale: option.value }).then(() => {
      window.location.reload();
    });
  };

  const handleLoginFinish = async (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => {
    await getStudents(loginBtnReportParams);
    // 如果存在token，且传递参数需要跳转到学生中心，且非学习园地页面，则跳转学员中心
    if (cacheStorage.getItem(TOKEN) && !notToStuCenter && !router.pathname.includes('/resources/')) {
      window.location.href = getLoginJumpUrl();
    }
  };

  useEffect(() => {
    const handleCustomEvent = (data: string) => {
      const { notToStuCenter, loginBtnReportParams } = JSON.parse(data);
      handleLoginFinish(notToStuCenter, loginBtnReportParams);
    };

    eventBus.on('handleLoginFinish', handleCustomEvent);

    // 清理监听器
    return () => {
      eventBus.off('handleLoginFinish', handleCustomEvent);
    };
  }, []);

  useEffect(() => {
    if (!isLogin && window.location?.search?.includes('return_url')) {
      if (window.innerWidth > 720) {
        setLoginPCVisible(true);
      } else {
        setLoginMobileVisible(true);
      }
    }
  }, [isLogin]);

  const handleShowForm = (type: string) => {
    setShowForm(true);
    traceEvent({
      data: { _event: 'O_LZDB_FreeReceiveClick', page_position: window.location.href },
    });
    setTimeout(() => {
      if (type === 'pc') {
        childRef?.current?.showPCForm();
      }
      if (type === 'mobile') {
        setOpenInMobile(false);
        childRef?.current?.showMobileForm();
      }
    }, 350);
  };
  return (
    <>
      <Head>
        {/* eslint-disable-next-line @next/next/no-css-tags */}
        <link rel="stylesheet" href="/css/motion.css" />
      </Head>
      <div
        id="header"
        className={cls(styles.box, {
          [styles.localeEn]: locale === 'en',
          [styles.highZIndex]: openInMobile,
        })}
        key={keyRef.current}
      >
        {window.innerWidth <= 720 && (
          <Script
            src={`https://accounts.google.com/gsi/client?time=${Date.now()}}`}
            onLoad={() => setGoogleLoginSDKLoaded(true)}
          />
        )}
        <Script
          src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
          onLoad={() => setAppleLoginSDKLoaded(true)}
        />
        <div className={cls(styles.container, className)}>
          <div className={styles.menuContainer} onClick={handleMenuButtonClick}>
            <MenuIcon open={openInMobile} />
          </div>
          <section
            className={styles.logo}
            onClick={(e) => {
              handleGA(e, 'Logo');
            }}
          >
            <Link href="/" legacyBehavior>
              <a>
                <span className={styles.logoLink}>wukong</span>
                <img src="/icon/logo.svg" alt="logo" width={120} height={40} />
              </a>
            </Link>
          </section>
          <nav
            className={cls(styles.content, {
              [styles.contentMobileShow]: openInMobile,
            })}
          >
            <ul className={styles.nav}>
              {navConfigs.map((item, i) => {
                let node = item.href ? (
                  <div className={styles.navText}>
                    <Link
                      href={item?.hrefEn && router?.locale === 'en' ? item?.hrefEn : item.href}
                      legacyBehavior
                      locale={item.hrefEn ? false : undefined}
                    >
                      {item.label}
                    </Link>
                  </div>
                ) : (
                  <div className={styles.navTextNoHref}>
                    <span>{item.label}</span>
                  </div>
                );

                if (item.children) {
                  let options = [...item.children];
                  if (item.href === '/resources/') {
                    const resourceNavListLabels = Object.keys(resourceNavList || {});
                    options = item.children.filter(({ label }) => resourceNavListLabels.includes(label));
                  }
                  node = <Select options={options}>{node}</Select>;
                }

                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    className={styles.navItem}
                    key={i}
                    onClick={(e) => {
                      handleGA(e, item.ga, item.label);
                    }}
                  >
                    {node}
                  </li>
                );
              })}
            </ul>
            <div className={styles.navBg}>
              <Image layout="responsive" src={bg} alt="nav-background" />
            </div>
            {isMobile && !isLogin && (
              <a
                className={cls(styles.classBtn)}
                rel="noreferrer"
                onClick={() => {
                  handleShowForm('mobile');
                }}
              >
                <span>{t('导航-免费试课')}</span>
              </a>
            )}
          </nav>
          <div className={styles.rightNav}>
            <div
              className={cls(styles.languageBox, {
                [styles.languageBoxActive]: showLanguage,
              })}
            >
              <div className={cls(styles.languageTextBox)} onClick={handleLanguageClick}>
                <span className={cls(styles.languageText)}>{locale === 'en' ? 'EN' : '中文'}</span>
              </div>
              <div className={cls(styles.languageSelectBox)}>
                <div className={cls(styles.languageSelectItem)}>
                  <Link
                    href={router.pathname}
                    locale="en"
                    className={cls(styles.languageItemText, {
                      [styles.languageItemActive]: locale === 'en',
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLanguageSelect({ value: 'en' });
                    }}
                  >
                    EN
                  </Link>
                </div>
                <div className={cls(styles.languageSelectItem)}>
                  <Link
                    href={`/zh${router.pathname}`}
                    className={cls(styles.languageItemText, {
                      [styles.languageItemActive]: locale === 'zh',
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLanguageSelect({ value: 'zh' });
                    }}
                  >
                    中文
                  </Link>
                </div>
              </div>
            </div>
            {isLogin && window.innerWidth > 720 && (
              <div className={styles.studentCenter}>
                <a
                  href={getStuCenterUrl()}
                  target="_blank"
                  className={styles.studentCenterBtn}
                  rel="noreferrer"
                  onClick={() => {
                    const token = cacheStorage.getItem(TOKEN);
                    const decoded = jsonwebtoken.decode(token);
                    traceEvent({
                      ...(window.innerWidth <= 720 ? { ak: 'official_mobile' } : {}),
                      data: { _event: 'O_OfficialWebGoCenter', account_id: student?.uuid || decoded?.sub },
                    });
                    sendDataToGTM(prefixName`StudyCenter`);
                  }}
                >
                  <span>{t('进入学员中心')}</span>
                </a>
              </div>
            )}
            {!isLogin && (
              <div className={styles.login}>
                <a
                  className={cls(styles.loginBtn)}
                  rel="noreferrer"
                  onClick={() => {
                    const windowWidth = window.innerWidth;
                    traceEvent({
                      ...(window.innerWidth <= 720 ? { ak: 'official_mobile' } : {}),
                      data: { _event: 'O_Login_ButtonClick' },
                    });
                    sendDataToGTM('login_hp_head');
                    if (windowWidth > 720) {
                      setLoginPCVisible(true);
                      return;
                    }
                    if (windowWidth <= 720) {
                      setLoginMobileVisible(true);
                    }
                  }}
                >
                  <span>{t('登录')}</span>
                </a>
              </div>
            )}
            {isLogin && (
              <div
                onClick={() => {
                  if (window.innerWidth <= 720) {
                    setLogoutDrawerVisible(true);
                  }
                }}
                className={styles.avatarContainer}
              >
                <div className={styles.avatar}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={student?.data?.HeadImageUrl || '/images/login/avatar.svg'} />
                </div>
                {window.innerWidth > 720 && (
                  <div className={styles.selectBox}>
                    <div className={styles.userInfo}>
                      <div className={styles.icon}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img src={student?.data?.HeadImageUrl || '/images/login/avatar.svg'} />
                      </div>
                      <span title={student?.fullName || 'Student'} className={styles.text}>
                        {student?.fullName || 'Student'}
                      </span>
                    </div>
                    <div className={styles.userLine} />
                    <div className={styles.selectItem} onClick={logout}>
                      <div className={styles.icon} />
                      <span className={styles.text}>{t('退出登录')}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {isPC && !isLogin && (
              <div className={styles.login}>
                <a
                  className={cls(styles.classBtn)}
                  rel="noreferrer"
                  onClick={() => {
                    handleShowForm('pc');
                  }}
                >
                  <span>{t('导航-免费试课')}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoginContext.Provider
        value={{
          googleLoginSDKLoaded,
          appleLoginSDKLoaded,
          setGoogleLoginSDKLoaded,
          setAppleLoginSDKLoaded,
          loginPCVisible,
          loginMobileVisible,
        }}
      >
        {window.innerWidth > 720 && (
          <Modal visible={loginPCVisible} handleClose={() => setLoginPCVisible(false)}>
            <LoginPC
              handleLoginFinish={handleLoginFinish}
              handleClose={() => setLoginPCVisible(false)}
              handleOpen={() => setLoginPCVisible(true)}
            />
          </Modal>
        )}
        {window.innerWidth > 720 && <GoogleAutoLogin handleLoginFinish={handleLoginFinish} />}
        {window.innerWidth <= 720 && (
          <Drawer visible={loginMobileVisible} handleClose={() => setLoginMobileVisible(false)} style={{ height: 572 }}>
            <LoginMobile
              handleLoginFinish={handleLoginFinish}
              handleClose={() => setLoginMobileVisible(false)}
              handleOpen={() => setLoginMobileVisible(true)}
            />
          </Drawer>
        )}
      </LoginContext.Provider>
      {isLogin && window.innerWidth <= 720 && (
        <Drawer
          visible={logoutDrawerVisible}
          handleClose={() => setLogoutDrawerVisible(false)}
          style={{ height: 216, backgroundColor: '#F5F5FC' }}
        >
          <Logout logout={logout} handleClose={() => setLogoutDrawerVisible(false)} student={student} />
        </Drawer>
      )}
      {showForm && <Form type="positionNav" forwardedRef={childRef} />}
    </>
  );
};

export default Nav;
