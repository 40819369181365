import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { traceEvent } from '@wk/wk-gatherer';
import styles from './style.module.scss';
import Modal from '@/components/Modal';
// eslint-disable-next-line import/no-cycle
import Register from '../Register';
import { loginByPhone } from '@/request/login';
import ToastLoading from '@/components/ToastLoading';
import { setTokenInfo } from '@/utils/token';
import { OtherLoginWays } from '@/request/interface/login';
// eslint-disable-next-line import/no-cycle
import BindAccountForm from '../BindAccountForm';
import { isProduction } from '@/utils/utils';
import { subscribe } from '@/request/commonGatWay';
import LoginContext from '@/context/LoginContext';
import RegistSuccess from '../RegistSuccess';
import Toast from '@/components/Toast';

interface Props {
  handleOpen: () => void;
  handleClose: () => void;
  handleLoginFinish: (notToStuCenter?: boolean) => void;
  pageType: string;
  tabType: 'mobile' | 'email';
  setTabType: (tabType: 'mobile' | 'email') => void;
  privacyChecked: boolean;
  setPrivacyChecked: (privacyChecked: boolean) => void;
}

const Footer: FC<Props> = ({
  handleOpen,
  handleClose,
  handleLoginFinish,
  pageType,
  tabType,
  setTabType,
  privacyChecked,
  setPrivacyChecked,
}) => {
  const { t, i18n } = useTranslation(['common']);
  const [registerModalVisible, setRegisterModalVisible] = useState<boolean>(false);
  const [otherLoginMethod] = useState<OtherLoginWays>(['GOOGLE_ANDROID', 'WECHAT_APP', 'APPLE']);
  const [params, setParams] = useState<any>({});
  const [bindAccountVisible, setBindAccountVisible] = useState<boolean>(false);
  // const initGoogleSdk = useRef(false);
  const googleBtnRef = useRef(null);
  const { googleLoginSDKLoaded, appleLoginSDKLoaded } = useContext(LoginContext);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const [registType, setRegistType] = useState<'手机' | '微信' | '谷歌' | '谷歌提示' | '苹果'>('谷歌');

  const checkPrivacy = () => {
    if (!privacyChecked) {
      Toast.info(
        i18n.language === 'en'
          ? `${t('请先同意')} ${t('用户协议')} ${t('和')} ${t('隐私政策')}`
          : `${t('请先同意')}《${t('用户协议')}》${t('和')}《${t('隐私政策')}》`,
      );
      return false;
    }

    return true;
  };

  const accreditSuccessCallback = async (
    code: string,
    type: 'GOOGLE_ANDROID' | 'APPLE_APP',
    source: 'GOOGLE' | 'APPLE',
  ) => {
    ToastLoading.show(t('加载中...'));
    try {
      const { content } = await loginByPhone({
        oauthType: type,
        code,
      });
      const { errorCode, token, oauthType = '', unionId = '', openId = '', email = '' } = content || {};
      ToastLoading.hide();
      if (errorCode === 409) {
        setParams({ oauthType, unionId, openId });
        setBindAccountVisible(true);
        handleClose();
        setPrivacyChecked(false);
      } else if (token) {
        setTokenInfo(token);
        if (content?.accountData?.loginOrRegister === 'login') {
          handleLoginFinish();
        } else {
          setRegistType(type === 'GOOGLE_ANDROID' ? '谷歌' : '苹果');
          setSuccessVisible(true);
          handleLoginFinish(true);
        }
        subscribe({ email, source });
        handleClose();
        setPrivacyChecked(false);
      }
    } finally {
      ToastLoading.hide();
    }
  };

  const handleGoogleBtnLClick = throttle(
    useCallback(() => {
      traceEvent({ data: { _event: 'O_GoogleClick', popup_name: '登录弹窗' } });
    }, []),
    500,
  );

  const initGoogleLogin = () => {
    window?.google?.accounts?.id?.initialize?.({
      client_id: '539432422945-v4n3mh2o3j6goqhslsggiup6c75jv7ro.apps.googleusercontent.com',
      callback: (res: any) => {
        accreditSuccessCallback(res?.credential, 'GOOGLE_ANDROID', 'GOOGLE');
      },
    });

    window?.google?.accounts?.id?.renderButton?.(googleBtnRef.current, {
      theme: 'outline',
      size: 'large',
      logo_alignment: 'left',
      type: 'icon',
      width: 44,
      shape: 'rectangular',
      click_listener: handleGoogleBtnLClick,
    });
  };

  const initAppleLogin = () => {
    window?.AppleID?.auth?.init?.({
      clientId: 'com.wukongedu.wkofficialsite',
      scope: 'email',
      redirectURI: window.location.origin,
      state: 'initial',
      // nonce: '[NONCE]',
      usePopup: true,
    });
  };

  const handleOpenApple = async () => {
    try {
      traceEvent({ data: { _event: 'O_AppleClick', popup_name: '登录弹窗' } });
      const checkedPrivacy = checkPrivacy();
      if (!checkedPrivacy) {
        return;
      }
      const data: any = await window?.AppleID?.auth?.signIn?.();
      if (data?.authorization?.id_token) {
        accreditSuccessCallback(data?.authorization?.id_token, 'APPLE_APP', 'APPLE');
      } else {
        console.error('苹果授权登录失败');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (appleLoginSDKLoaded && otherLoginMethod?.includes?.('APPLE')) {
      initAppleLogin();
    }
  }, [appleLoginSDKLoaded]);

  useEffect(() => {
    if (googleLoginSDKLoaded && privacyChecked && otherLoginMethod?.includes?.('GOOGLE_ANDROID')) {
      initGoogleLogin();
    }
  }, [googleLoginSDKLoaded, privacyChecked]);

  const handleOpenWechat = useCallback(() => {
    traceEvent({ data: { _event: 'O_WeChatClick', popup_name: '登录弹窗' } });
    const checkedPrivacy = checkPrivacy();
    if (!checkedPrivacy) {
      return;
    }
    const iframeWidth = 600;
    const iframeHeight = 550;
    const iframeTop = (window.screen.height - iframeHeight) / 2;
    const iframeLeft = (window.screen.width - iframeWidth) / 2;
    const url = `https://student${isProduction() ? '' : '-dev'}.wukongedu.net/${
      i18n?.language
    }/login/wechat-login?pageType=${pageType}`;
    window.open(
      url,
      'iframe',
      `height=${iframeHeight}, width=${iframeWidth}, top=${iframeTop}, left=${iframeLeft}, scrollbars=yes`,
    );
  }, [privacyChecked]);

  const sendCode = useCallback(
    throttle(async (event: MessageEvent) => {
      try {
        const { data, origin } = event || {};
        if (origin.includes('.wukongedu.net') && typeof data === 'string') {
          const { stuCode, pageType: msgPageType } = JSON.parse(data || '{}') || {};
          if (msgPageType !== pageType) {
            return;
          }

          ToastLoading.show(t('加载中...'));
          const { content } = await loginByPhone({
            oauthType: isProduction() ? 'WECHAT_WEB_STU_PROD' : 'WECHAT_WEB_STU_DEV',
            code: stuCode,
          });

          const { errorCode, token, oauthType = '', unionId = '', openId = '' } = content || {};
          ToastLoading.hide();
          if (errorCode === 409) {
            setParams({ oauthType, unionId, openId });
            setBindAccountVisible(true);
            handleClose();
            setPrivacyChecked(false);
          } else if (token) {
            setTokenInfo(token);
            if (content?.accountData?.loginOrRegister === 'login') {
              handleLoginFinish();
            } else {
              setRegistType('微信');
              setSuccessVisible(true);
              handleLoginFinish(true);
            }
            handleClose();
            setPrivacyChecked(false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    window.addEventListener('message', sendCode);
    return () => {
      window.removeEventListener('message', sendCode);
    };
  }, [sendCode]);

  const handleSwitch = () => {
    setTabType(tabType === 'mobile' ? 'email' : 'mobile');
  };

  return (
    <>
      <div className={styles.footer}>
        {/* <div className={styles.register}>
          <span style={{ marginRight: 10 }}>{t('还没有账号?')}</span>
          <span
            onClick={() => {
              traceEvent({ data: { _event: 'O_RegisterClick' } });
              handleClose();
              setRegisterModalVisible(true);
            }}
            style={{ color: '#FF5353', cursor: 'pointer' }}
          >
            {t('点击注册')}
          </span>
        </div> */}
        <div className={styles.otherLoginTitle}>
          <div className={styles.line} />
          <span>{t('其他方式登录')}</span>
          <div className={styles.line} />
        </div>
        <div className={styles.loginBtnContainer}>
          <div className={styles.loginBtnContent}>
            <div onClick={handleSwitch} className={styles.loginBtn}>
              <div
                className={styles.switchBtnImage}
                style={{
                  backgroundImage:
                    tabType === 'mobile' ? `url('/images/login/email.svg')` : `url('/images/login/phone.svg')`,
                }}
              />
            </div>
            {otherLoginMethod?.includes?.('GOOGLE_ANDROID') && googleLoginSDKLoaded && (
              <div onClick={checkPrivacy} className={classNames(styles.loginBtn, styles.googleBtn)}>
                {privacyChecked && <div className={styles.googlContent} ref={googleBtnRef} />}
              </div>
            )}
            {/* {otherLoginMethod?.includes?.('FACEBOOK') && (
              <div onClick={handleOpenFacebook} className={classNames(styles.loginBtn, styles.facebookBtn)}>
                {t('Facebook登录')}
              </div>
            )} */}
            {otherLoginMethod?.includes?.('APPLE') && appleLoginSDKLoaded && (
              <div onClick={handleOpenApple} className={classNames(styles.loginBtn, styles.appleBtn)} />
            )}
            {otherLoginMethod?.includes?.('WECHAT_APP') && (
              <div onClick={handleOpenWechat} className={classNames(styles.loginBtn, styles.wechatBtn)} />
            )}
          </div>
        </div>
        <div className={styles.privacy}>
          <input
            type="checkbox"
            id="privacyCheckBox"
            checked={privacyChecked}
            onChange={(e) => {
              setPrivacyChecked(e?.target?.checked);
            }}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="privacyCheckBox">
            {privacyChecked ? (
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.65" y="0.65" width="12.7" height="12.7" rx="2.35" stroke="#484C70" strokeWidth="1.3" />
                <path
                  d="M10.7471 6.12151C8.37126 7.21466 5.78786 10.6802 5.78786 10.6802C4.05229 8.1332 2.55216 7.51925 2.55216 7.51925L4.59427 6.56241C5.43009 7.51819 6.005 8.19831 6.005 8.19831C8.98079 3.55805 11.0113 3.10129 11.0113 3.10129L10.7471 6.12151Z"
                  fill="#484C70"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <rect
                  opacity="0.7"
                  x="0.65"
                  y="0.65"
                  width="12.7"
                  height="12.7"
                  rx="2.35"
                  stroke="#8D8FA6"
                  strokeWidth="1.3"
                />
              </svg>
            )}
          </label>
          <div className={styles.content}>{t('我已阅读并同意')}</div>
          <div className={styles.content}>
            {i18n?.language === 'zh' ? '《' : ''}
            <span
              onClick={() => {
                traceEvent({ data: { _event: 'O_UserAgreementClick', popup_name: '登录弹窗' } });
                // window.open('https://student.wukongedu.net/agreement');
                window.open(`${window.location.origin}/${i18n.language}/terms/?layout=no`);
              }}
              className={styles.highlight}
            >
              {t('用户协议')}
            </span>
            {i18n?.language === 'zh' ? '》' : ''}
            <span style={{ margin: '0 5px' }}>{t('和')}</span>
            {i18n?.language === 'zh' ? '《' : ''}
            <span
              onClick={() => {
                traceEvent({ data: { _event: 'O_PrivacyPolicyClick', popup_name: '登录弹窗' } });
                // window.open('https://www.wukongsch.com/policy/?layout=no');
                window.open(`${window.location.origin}/${i18n.language}/policy/?layout=no`);
              }}
              className={styles.highlight}
            >
              {t('隐私政策')}
            </span>
            {i18n?.language === 'zh' ? '》' : ''}
          </div>
        </div>
      </div>
      <Modal
        visible={registerModalVisible}
        handleClose={() => setRegisterModalVisible(false)}
        handleReturn={() => {
          setRegisterModalVisible(false);
          handleOpen();
        }}
      >
        <Register
          handleOpen={() => setRegisterModalVisible(true)}
          handleClose={() => setRegisterModalVisible(false)}
          handleLoginFinish={handleLoginFinish}
        />
      </Modal>

      <Modal
        visible={bindAccountVisible}
        handleClose={() => setBindAccountVisible(false)}
        handleReturn={() => {
          setBindAccountVisible(false);
          handleOpen();
        }}
      >
        <BindAccountForm
          handleOpen={() => setBindAccountVisible(true)}
          handleClose={() => setBindAccountVisible(false)}
          handleLoginFinish={handleLoginFinish}
          params={params}
          bindType="login"
        />
      </Modal>
      <Modal visible={successVisible} handleClose={() => setSuccessVisible(false)}>
        <RegistSuccess registType={registType} handleLoginFinish={handleLoginFinish} />
      </Modal>
    </>
  );
};

export default Footer;
