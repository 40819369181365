if (global.window) {
  window.addEventListener('load', () => {
    const path = window.location.pathname;
    const isZH = path.startsWith('/zh/') || path === '/zh';
    setTimeout(() => {
      (function (a, h, c, b, f, g) {
        /* eslint-disable */
        a.UdeskApiObject = f;
        a[f] =
          a[f] ||
          function () {
            (a[f].d = a[f].d || []).push(arguments);
          };
        g = h.createElement(c);
        g.async = 1;
        g.charset = 'utf-8';
        g.src = b;
        c = h.getElementsByTagName(c)[0];
        c.parentNode.insertBefore(g, c);
      })(window, document, 'script', 'https://assets-cli.s5.udesk.cn/im_client/js/udeskApi.js', 'ud');
      if(isZH){ // im方要求不要在配置中写判断，目测以前的`https://wukong.s5.udesk.cn/im_client/?web_plugin_id=${isEn ? '1449' : '1372'}` 写法是因为在低版本浏览器的兼容问题
        ud({
          code: '79gk8g',
          link: 'https://wukong.s5.udesk.cn/im_client/?web_plugin_id=1372',
          language: 'zh-cn', //语言-中文
        });
      } else {
        ud({
          code: '79gk8g',
          link: 'https://wukong.s5.udesk.cn/im_client/?web_plugin_id=1449',
          language: "en-us", //语言-英文
        });
      }
    }, 5e3);
  });
}
