import { requestEdm, request } from '@/request/index';
import { GetCountryRequest, GetCountryResponse } from '@/request/interface/nav';

// 获取地区 https://cp-public-dev.wukongacademy.com/public/reserve/country/info
export const getCountry = (params: GetCountryRequest) => {
  return requestEdm.post<GetCountryResponse, GetCountryResponse>(`/public/reserve/country/info`, params);
};

// 获取时间 public/timestamp
export const getTimestamp = () => {
  return request.get<number, number>(`/public/timestamp`);
};

export interface SendPhoneCode {
  phone: string;
  captchaVerification?: string;
}

export interface EmailVerificationCodeRequest {
  phone: string;
  code: string;
}

export interface EmailVerificationCodeResponse {
  content: boolean;
}

export interface SendVoiceCode {
  phone: string;
  captchaVerification?: string;
  locale: 'zh' | 'en';
}

export interface VoiceVerificationCodeRequest {
  phone: string;
  verificationCode: string;
}

export const createLeadNoRemind = (data: any) => {
  return getTimestamp().then((res) => {
    return request.post('/public/lead/create', data, {
      signature: true,
      timestamp: res,
      skipErrorHandler: true,
    });
  });
};

export const sendPhoneVerificationCode = (params: SendPhoneCode) => {
  return getTimestamp().then((res) => {
    return request.put('/public/sms/send', params, {
      signature: true,
      timestamp: res,
    });
  });
};

// 验证短信验证码
export const verifyPhoneVerificationCode = (data: EmailVerificationCodeRequest) => {
  return request.post<EmailVerificationCodeResponse, EmailVerificationCodeResponse>('/public/sms/verification', data);
};

// 发送语音验证码
export const sendVoiceVerificationCode = (params: SendVoiceCode) => {
  return getTimestamp().then((res) => {
    return request.put('/public/sms/send/voice', params, {
      signature: true,
      timestamp: res,
    });
  });
};

// 验证语音验证码
export const verifyVoiceVerificationCode = (data: VoiceVerificationCodeRequest) => {
  return request.post<EmailVerificationCodeResponse, EmailVerificationCodeResponse>(
    '/public/sms/verification/voice',
    data,
  );
};
