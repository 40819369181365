import React from 'react';

interface LoginContextType {
  googleLoginSDKLoaded: boolean;
  appleLoginSDKLoaded: boolean;
  setGoogleLoginSDKLoaded: (value: boolean) => void;
  setAppleLoginSDKLoaded: (value: boolean) => void;
  loginPCVisible: boolean;
  loginMobileVisible: boolean;
}

const LoginContext = React.createContext<LoginContextType>({
  googleLoginSDKLoaded: false,
  appleLoginSDKLoaded: false,
  setGoogleLoginSDKLoaded: () => {},
  setAppleLoginSDKLoaded: () => {},
  loginPCVisible: false,
  loginMobileVisible: false,
});

export default LoginContext;
