/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import cls from 'classnames';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import useIsMobile from '@/hooks/useIsMobile';

import { getCountry } from '@/request/nav';
import { MyContext } from '@/config/context-manager';
import Mobile from '@/components/Device/Mobile';
import PC from '@/components/Device/PC';
import FooterForm from '@/components/Footer/FooterForm';
import styles from './style.module.scss';
import generatePaths from '../Nav/generatePaths';
import rightPaths, { PathsItemProps } from './generatePaths';
import useGTM from '@/hooks/useGtag';
import { generateNamePrefix } from '@/hooks/helpers';

interface ITitle {
  tele?: string;
  teleZh: string;
  nation?: string;
  enName?: string;

  city?: string;
}

const teleInfoList: ITitle[] = [
  { enName: 'United States', city: '洛杉矶', nation: '美国', teleZh: '+1 (628) 333-2966', tele: '+1 (628) 358-9588' },
  { enName: 'Australia', city: '悉尼', nation: '澳大利亚', teleZh: '+61 7 31854732', tele: '+61 2 99116620' },
  { enName: 'New Zealand', city: '奥克兰', nation: '新西兰', teleZh: '+64 98709184', tele: '+64 98717076' },
  { enName: 'Canada', city: '多伦多', nation: ' 加拿大', teleZh: '+1 8672928226', tele: '+1 6479561731' },
  { enName: 'Singapore', city: '新加坡', nation: '新加坡', teleZh: '+65 69945213', tele: '+65 69945216' },
  { enName: 'United Kingdom', city: '伦敦', nation: '英国', teleZh: '+44 1135246470', tele: '+44 2033109899' },
  { enName: 'France', city: '巴黎', nation: '法国', teleZh: '+33 355409177', tele: '+33 259510005' },
  { enName: 'Spain', city: '马德里', nation: '西班牙', teleZh: '+34 978980565', tele: '+34 858887362' },
];

const socialMediaList = [
  {
    title: 'facebook',
    ga: 'facebook',
    src: '/icon/facebook.png',
    hoverSrc: '/icon/facebook-hover.png',
    link: 'https://www.facebook.com/wukongedu/',
  },
  {
    title: 'Youtube',
    ga: 'youtube',
    src: '/icon/youtube.png',
    hoverSrc: '/icon/youtube-hover.png',
    link: 'https://www.youtube.com/channel/UC_Ey8x88zFKeJ8pesQ6y_Aw',
  },
  {
    title: 'instagram',
    ga: 'Instagram',
    src: '/icon/instagram.png',
    hoverSrc: '/icon/instagram-hover.png',
    link: 'https://www.instagram.com/wukongchinese/ ',
  },
  {
    title: 'linkedin',
    ga: 'linkedin',
    src: '/icon/linkedin.png',
    hoverSrc: '/icon/linkedin-hover.png',
    link: 'https://cn.linkedin.com/company/wukong-education',
  },
  {
    title: 'redbook',
    ga: 'redbook',
    src: '/icon/xiaohongshu.png',
    hoverSrc: '/icon/xiaohongshu-hover.png',
    link: 'https://www.xiaohongshu.com/user/profile/5c23fc910000000005015d94?xhsshare=CopyLink&appuid=5c23fc910000000005015d94&apptime=1607474453',
  },
  {
    title: 'weibo',
    ga: 'weibo',
    src: '/icon/weibo.png',
    hoverSrc: '/icon/weibo-hover.png',
    link: 'https://weibo.com/kiwipedia123?refer_flag=1005055013_',
  },
  {
    title: 'wechat',
    ga: 'wechat',
    src: '/icon/wechat.png',
    hoverSrc: '/icon/wechat-hover.png',
    link: 'https://mp.weixin.qq.com/s?__biz=MzU2NDA3Mjc5NQ==&mid=100112261&idx=2&sn=ba4d3e5faa354423253b18aa65a0113a&chksm=7c53afb14b2426a7fb008936433eef5eba0fbb4f5fe17fa19a9ae5083b2ac65e67d7e633f3e0#rd',
  },
];

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation(['common', 'path']);
  const { asPath } = useRouter();
  const { locale, framework } = useContext(MyContext);

  const [curLoginNationTeleList, setCurLoginNationTeleList] = useState<ITitle[]>();
  const sendDataToGTM = useGTM();
  const prefixName = generateNamePrefix(`Footer_`);
  const isMobile = useIsMobile();
  const getCurLoationTele = () => {
    const CUR_LOGIN_NATION_TETE = window.sessionStorage.getItem('CUR_LOGIN_NATION_TETE');
    if (CUR_LOGIN_NATION_TETE && CUR_LOGIN_NATION_TETE.indexOf('teleZh') !== -1) {
      setCurLoginNationTeleList(CUR_LOGIN_NATION_TETE ? JSON.parse(CUR_LOGIN_NATION_TETE) : [{}]);
    } else {
      const curDate = new Date();
      const curTime = `${curDate.getMonth()}/${curDate.getDate()}/${curDate.getFullYear()} ${curDate.getHours()}:${curDate.getMinutes()}:${curDate.getSeconds()}`;
      getCountry({
        time: curTime,
      })
        .then((response) => {
          const curLoginNation = response.data.country;
          const newCurLoginNationTeleList = teleInfoList.filter((item) => {
            return item.enName === curLoginNation;
          });
          if (newCurLoginNationTeleList?.length > 0) {
            setCurLoginNationTeleList(newCurLoginNationTeleList);
            window.sessionStorage.setItem('CUR_LOGIN_NATION_TETE', JSON.stringify(newCurLoginNationTeleList));
          } else {
            const defaultData: ITitle[] = [
              {
                enName: 'New Zealand',
                city: '奥克兰',
                nation: '新西兰',
                teleZh: '+64 98709184',
                tele: '+64 98717076',
              },
            ];
            setCurLoginNationTeleList(defaultData);
            window.sessionStorage.setItem('CUR_LOGIN_NATION_TETE', JSON.stringify(defaultData));
          }
        })
        .catch(() => {
          const defaultData: ITitle[] = [
            {
              enName: 'New Zealand',
              city: '奥克兰',
              nation: '新西兰',
              teleZh: '+64 98709184',
              tele: '+64 98717076',
            },
          ];
          setCurLoginNationTeleList(defaultData);
        });
    }
  };

  useEffect(() => {
    getCurLoationTele();
  }, []);

  const crumbsNode = useMemo(() => {
    const clearPath = asPath.replace(/#.*/, '');
    const navConfigs = generatePaths(t).concat([
      {
        href: '/download/',
        label: t('下载中心'),
      },
      {
        href: '/faq/',
        label: t('常见问题'),
      },
      {
        href: '/joinus/',
        label: t('加入我们'),
      },
      {
        href: '/policy/',
        label: t('服务条款'),
      },
    ]);
    if (clearPath === '/') {
      return null;
    }

    const crumbs = [
      <Link href="/" legacyBehavior>
        {t('悟空中文')}
      </Link>,
    ];
    for (let i = 0; i < navConfigs.length; i += 1) {
      const element = navConfigs[i];
      if (element.href === clearPath) {
        crumbs.push(<span>{element.label}</span>);
      } else if (element.children && element.children.some((child) => child.href === clearPath)) {
        crumbs.push(
          <Link href={element.href || ''} legacyBehavior>
            {element.label}
          </Link>,
        );
        for (let j = 0; j < element.children.length; j += 1) {
          const child = element.children[j];
          if (child.href === clearPath) {
            crumbs.push(<span>{child.label}</span>);
            break;
          }
        }
      }
    }

    return crumbs;
  }, [t, asPath]);

  const openKaamel = () => {
    if (window.kaamelObj) {
      window.kaamelObj?.openPanel();
    }
  };

  const navConfigs = useMemo(() => {
    const oldArr = rightPaths(t);
    // console.log('framework', framework);
    // 展示cookie设置
    if (framework && framework.framework && framework.finished) {
      oldArr[1]?.children?.push({ label: t('隐私设置'), ga: 'privacySettings', fn: openKaamel });
    }
    return oldArr;
  }, [t, framework]);

  const handleGA = (gaName?: string) => {
    if (gaName) {
      sendDataToGTM(prefixName`${gaName}`);
    }
  };

  const handleGAMedia = (gaName?: string) => {
    if (gaName) {
      sendDataToGTM(prefixName`Followus`, { [gaName]: gaName });
    }
  };
  const navNode = (child: PathsItemProps[]) => {
    return (
      <>
        {child?.map((item, i) => {
          if (!item.href) {
            return (
              <li
                key={i}
                onClick={() => {
                  handleGA(item.ga);
                  if (item?.fn) {
                    item?.fn();
                  }
                }}
              >
                <a className={styles.navA}>{item.label}</a>
              </li>
            );
          }
          return (
            <li
              key={i}
              onClick={() => {
                handleGA(item.ga);
              }}
            >
              <Link href={item.href} legacyBehavior>
                {item.label}
              </Link>
            </li>
          );
        })}
      </>
    );
  };

  return (
    <footer
      id="footer"
      className={cls(className, styles.containerFooter, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.container}>
        {crumbsNode && (
          <p className={styles.crumbs}>
            {crumbsNode.map((item, i) => (
              <React.Fragment key={i}>
                {item}
                {i !== crumbsNode.length - 1 ? <span className={styles.arrow}>&gt;</span> : null}
              </React.Fragment>
            ))}
          </p>
        )}
        {/* 社交媒体 + 课程 关于我们 ：pc flex左右布局 mobile 上下  */}
        <div className={styles.footerTop}>
          <div className={styles.footerTopInner}>
            {!isMobile && <FooterForm />}

            <nav className={styles.footerTopNavBox}>
              {navConfigs.map((item, i) => {
                const childNode = navNode(item.children);
                return <ul key={i}>{childNode}</ul>;
              })}
            </nav>
          </div>

          <div>
            <p className={styles.footerTopTitle} dangerouslySetInnerHTML={{ __html: t('关注悟空社交媒体') }} />
            <ul className={styles.footerTopSocialMediaInner}>
              {socialMediaList.map((item) => (
                <li
                  key={item.title}
                  onClick={() => {
                    handleGAMedia(item.ga);
                  }}
                >
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <Image src={item.src} layout="responsive" width={30} height={30} alt={`悟空中文 ${item.title}`} />
                  </a>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <Image
                      src={item.hoverSrc}
                      layout="responsive"
                      width={30}
                      height={30}
                      alt={`悟空中文 ${item.title}`}
                    />
                  </a>
                </li>
              ))}
            </ul>
            {isMobile && <FooterForm />}
            <div className={styles.footerTopCallBox}>
              <div className={styles.footerTopCallBoxL}>
                <div className={styles.footerTopCall}>
                  <div className={styles.footerTopCallImg}>
                    <Image src="/icon/phone.svg" layout="responsive" width={24} height={24} alt="phone" />
                  </div>
                  <div className={styles.teleBox}>
                    {curLoginNationTeleList?.map((item) => (
                      <div className={styles.callInfo} key={item.nation}>
                        <p>
                          <a href={`tel:${item.tele}`} className={styles.tele}>
                            {item.tele}（English）
                          </a>
                        </p>
                        {item.teleZh && (
                          <p>
                            <a href={`tel:${item.teleZh}`} className={styles.tele}>
                              {item.teleZh}（中文）
                            </a>
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.footerTopCall}>
                  <div className={styles.footerTopCallImg}>
                    <Image src="/icon/mile.svg" layout="responsive" width={24} height={24} alt="mile" />
                  </div>
                  <p className={styles.callInfo}>contact@wukongsch.com</p>
                </div>
                <div className={styles.footerTopCall}>
                  <div className={styles.footerTopCallImg}>
                    <Image src="/icon/address.svg" layout="responsive" width={24} height={24} alt="mile" />
                  </div>
                  <div className={cls(styles.callInfo, styles.addressBox)}>
                    <p className={styles.addressDomesticTitle}>{t('地址-美国-标题')}</p>
                    <p className={styles.addressDomesticInfo}>{t('地址-美国-地址')}</p>
                    <p
                      className={styles.addressDomesticTitle}
                      dangerouslySetInnerHTML={{ __html: t('地址-新西兰-标题') }}
                    />
                    <p className={styles.addressDomesticInfo}>{t('地址-新西兰-地址')}</p>
                  </div>
                </div>
              </div>
              <div className={styles.footerTopCallBoxR}>
                <div className={styles.footerTopCallBoxQrCode}>
                  <Image src="/images/QR-code.jpg" layout="responsive" width={76} height={76} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerBottomPay}>
          <div className={styles.kidsafeWrap}>
            <div className={styles.kidsafeLayout}>
              <div className={styles.kidsafeSection}>
                <a
                  className={styles.kidsafe}
                  href="https://www.kidsafeseal.com/certifiedproducts/wukong.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={styles.logoLink}>kidsafe</span>
                  <img
                    className={styles.kidsafeImg}
                    width={150}
                    height={52}
                    src="https://www.kidsafeseal.com/sealimage/886916793933804774/wukong_extralarge_darktm.png"
                    alt="Wukong EDU (web and mobile) is a member of the kidSAFE Seal Program."
                  />
                </a>
              </div>
              <div className={styles.kidsafeEmptySection} />
            </div>
            <PC>
              <div className={styles.footerBottomPayImg}>
                <Image
                  src="/images/bak/pay-logo.png"
                  layout="responsive"
                  width={786}
                  height={40}
                  alt="悟空中文支付伙伴"
                />
              </div>
            </PC>
            <Mobile>
              <div className={styles.footerBottomPayImg}>
                <Image
                  src="/images/bak/pay-logo-mb.png"
                  layout="responsive"
                  width={300}
                  height={76}
                  alt="悟空中文支付伙伴"
                />
              </div>
            </Mobile>
          </div>
          <p>
            Copyright © 2021-2023 WuKong Education. All rights reserved.
            {'\n'}
            <Link href="/policy">Privacy</Link>
          </p>
        </div>
      </div>
      <div className={styles.footerLogo}>
        <section className={styles.footerLogoImg}>
          <Image src="/icon/logo.svg" layout="responsive" width={146} height={45} alt="悟空中文 logo" />
        </section>
      </div>
    </footer>
  );
};

export default Footer;
