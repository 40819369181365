import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { useRouter } from 'next/router';
import Footer from '@/components/Footer';

import Nav from '@/components/Nav';
import styles from './style.module.scss';
import { DISABLE_LAYOUT_PATHS } from '@/utils/constants';

const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  const [showLayout, setShowLayout] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const result = DISABLE_LAYOUT_PATHS?.some((item) => {
      return router.asPath?.startsWith(item);
    });

    setShowLayout(!result);
  }, []);

  return (
    <>
      {showLayout && <Nav className={styles.header} />}
      <div className={cls(styles.container, 'component-wrapper')}>
        <div className={cls(styles.content, 'component-wrapper-content')}>{children}</div>
        {showLayout && <Footer className={styles.footer} />}
      </div>
    </>
  );
};

export default PageWrapper;
