/* eslint-disable func-style */
import Cookie from 'js-cookie';

/**
 * Storage 仅在频繁都写中使用该 CacheStorage eg: axios 读写操作
 */
let store: Record<string, string> = {};

const setStoreItem = (key: string, value: string) => {
  store = { ...store, [key]: value };
};

function getItem<T = string>(key: string, initialValue = ''): T | string {
  if (!store[key]) {
    setStoreItem(key, Cookie.get(key) || '');
  }
  try {
    return store[key] ? JSON.parse(store[key]) : initialValue;
  } catch {
    return store[key] || initialValue;
  }
}

const removeItem = (key: string) => {
  if (store[key]) {
    delete store[key];
  }
  Cookie.remove(key);
};

function setItem<T>(key: string, value: T) {
  const result: string = (typeof value === 'object' ? JSON.stringify(value) : value) as string;
  setStoreItem(key, result);
  Cookie.set(key, result, { expires: 30 });
}

export default {
  getItem,
  setItem,
  removeItem,
};
