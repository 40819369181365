import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import React, { FC } from 'react';
import Image from 'next/legacy/image';
import ReturnImg from '@public/images/common/return.svg';
import CloseImg from '@public/images/common/close.svg';
import styles from './style.module.scss';

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleReturn?: () => void;
  width?: number | string;
}

const Modal: FC<Props> = ({ visible, handleClose, handleReturn, children, width }) => {
  return (
    <Dialog className={styles.modal} visible={visible} animation="zoom" maskAnimation="fade" width={width || '90%'}>
      {!!handleReturn && (
        <div className={styles.returnImg} onClick={handleReturn}>
          <Image src={ReturnImg} alt="返回" />
        </div>
      )}
      <div className={styles.closeImg} onClick={handleClose}>
        <Image src={CloseImg} alt="关闭" />
      </div>
      <div className={styles.content}>{children}</div>
    </Dialog>
  );
};

export default Modal;
