/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState, useContext } from 'react';
import Link from 'next/link';

import cls from 'classnames';
import arrow from '@public/icon/arrow.svg';
import Image from 'next/legacy/image';
import { traceEvent } from '@wk/wk-gatherer';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';
import useGTM from '@/hooks/useGtag';
import { generateNamePrefix } from '@/hooks/helpers';
import { SelectorChildrenItem } from '@/components/Nav/generatePaths';

interface SelectorProps {
  options: SelectorChildrenItem[];
}

const Select: React.FC<SelectorProps> = ({ options, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useContext(MyContext);
  const sendDataToGTM = useGTM();
  const prefixName = generateNamePrefix(`Header_`);

  const handleContainerClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleGA = (e: React.MouseEvent, item: SelectorChildrenItem) => {
    if (item?.ga) {
      e.stopPropagation();
      sendDataToGTM(prefixName`${item?.ga}`);
      traceEvent({
        data: {
          _event: item.traceEventGroupName,
          list_name: item?.label || item?.ga,
          page_position: window.location.href,
        },
      });
    }
  };

  return (
    <div
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
      onClick={handleContainerClick}
    >
      {children}

      <div
        className={cls(styles.arrow, {
          [styles.arrowSelected]: isOpen,
        })}
      >
        <Image src={arrow.src} alt="arrow" width={14} height={6} />
      </div>

      <ul
        className={cls(styles.options, {
          [styles.optionsActive]: isOpen,
        })}
      >
        {options.map((item, index) => {
          if (item.target && item.href) {
            return (
              <li
                className={styles.optionItem}
                key={index}
                onClick={(e) => {
                  handleGA(e, item);
                }}
              >
                <a href={item.href} target={item.target} rel="noreferrer">
                  {item.label}
                </a>
              </li>
            );
          }
          return (
            <li
              className={styles.optionItem}
              key={index}
              onClick={(e) => {
                handleGA(e, item);
              }}
            >
              <Link href={item.href} legacyBehavior>
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Select;
