import http, { AxiosResponse } from 'axios';
import Toast from '@/components/Toast';
import { TOKEN } from '@/utils/constants';
import cacheStorage from '@/utils/cacheStorage';

const request = http.create({
  baseURL: process.env.NEXT_PUBLIC_GATEWAY_RS_API_HOST,
  timeout: 30e3,
});

declare module 'axios' {
  interface AxiosRequestConfig {
    skipErrorHandler?: boolean;
    skipTransformResponse?: boolean;
    needAuth?: boolean;
    signature?: boolean;
    timestamp?: any;
  }
}

const responseSuccess = (res: AxiosResponse<any>) => {
  const { config } = res || {};
  if (config.skipTransformResponse) {
    return res.data;
  }

  return res.data;
};
const responseFail = (err: any) => {
  const { response, config, message: systemMessage, isAxiosError } = err;
  if (!isAxiosError || !response) {
    Toast.info(systemMessage);
  }
  if (err?.response) {
    const { status, data = {} } = response;
    const { errors, message } = data;
    const errMsg = (errors && errors[Object.keys(errors)[0]]) || message || err.message;

    if ((+status === 422 || +status === 403 || +status === 400) && !config?.skipErrorHandler) {
      const clearError = errMsg || '未知错误';
      Toast.info(clearError);
      throw new Error(`[Server Response Error]: ${clearError}`);
    }

    if (+status !== 401 && !config?.skipErrorHandler && (typeof data === 'string' || errMsg)) {
      Toast.info(errMsg);
    }
  }

  throw err;
};

request.interceptors.request.use((requestConfig) => {
  const { headers } = requestConfig;
  const token = cacheStorage.getItem(TOKEN);
  if (token) {
    headers.common.Authorization = `Bearer ${token}`;
  }
  headers.common['Accept-Language'] = window?.location?.href?.includes?.('/zh/') ? 'zh-CN' : 'en-US';
  return requestConfig;
});

request.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return responseFail(err);
  },
);

export default request;
