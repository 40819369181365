import { BLOG_URL } from '@/utils/constants';
import {
  GetMenusRequest,
  GetMenusResponse,
  GetNavListResponse,
  GetRecentCommendResponse,
  GetRecommendTopicListResponse,
  GetResourceDetailResponse,
  GetResourceListRequest,
  GetResourceListResponse,
  GetResourceRecommendResponse,
  GetTopicDetailResponse,
  Language,
  PostViewRecordRequest,
  PostViewRecordResponse,
  Subject,
} from './interface/resources';
import serverRequest from './serverRequest';
import clientRequest from '@/request/gatewayRequest';
import { BlogPost } from '@/interface/home';
import { capitalizeFirstLetter } from '@/utils/utils';

const apiUrl = process.env.NEXT_PUBLIC_GATEWAY_RS_API_HOST;

/**
 * 获取导航数据
 *
 * @return {*}
//  */
// export const getNavListServer = (locale: Language): Promise<GetNavListResponse> => {
//   return serverRequest(`/public/learning/garden/homepage/navigation/area`, {
//     method: 'post',
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };

/**
 * 获取导航数据
 *
 * @return {*}
 */
export const getNavList = () => {
  return clientRequest
    .post<GetNavListResponse, GetNavListResponse>('/public/learning/garden/homepage/navigation/area')
    .then((response) => response?.content);
};

/**
 * 获取最近推荐资源
 *
 * @param {Language} locale
 * @return {*}  {Promise<GetRecentCommendResponse>}
 */
// export const getRecentCommend = (locale: Language): Promise<GetRecentCommendResponse> => {
//   return serverRequest(`/public/learning/garden/homepage/recommend`, {
//     method: 'post',
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };
export const getRecentCommend = () => {
  return clientRequest
    .post<GetRecentCommendResponse, GetRecentCommendResponse>('/public/learning/garden/homepage/recommend')
    .then((response) => response?.content);
};

/**
 * 获取分类页筛选菜单
 *
 * @param {GetMenusRequest} params
 * @param {Language} locale
 * @return {*}  {Promise<GetMenusResponse>}
 */
// export const getMenus = (params: GetMenusRequest, locale: Language): Promise<GetMenusResponse> => {
//   return serverRequest(`/public/learning/garden/filter/area`, {
//     method: 'post',
//     data: params,
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };
export const getMenus = (params: GetMenusRequest) => {
  return clientRequest
    .post<GetMenusResponse, GetMenusResponse>('/public/learning/garden/filter/area', params)
    .then((response) => response?.content);
};

/**
 * 获取分类页资源列表
 *
 * @param {GetResourceListRequest} params
 * @return {*}
 */
export const getResourceList = (params: GetResourceListRequest) => {
  return clientRequest.post<GetResourceListResponse, GetResourceListResponse>(
    `/public/learning/garden/resource/search/${params.pageNum}`,
    { ...params, pageNum: undefined, tagIdList: params?.tagIdList?.length ? params?.tagIdList : undefined },
  );
};

/**
 * 服务端获取分类页资源列表
 *
 * @param {GetResourceListRequest} params
 * @return {*}
 */
export const getResourceListServer = (
  params: GetResourceListRequest,
  locale: Language,
): Promise<GetResourceListResponse> => {
  return serverRequest(`/public/learning/garden/resource/search/${params.pageNum}`, {
    method: 'post',
    data: { ...params, pageNum: undefined, tagIdList: params?.tagIdList?.length ? params?.tagIdList : undefined },
    apiPrefix: apiUrl,
    toJson: true,
    headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
  });
};

/**
 * 获取资源详情
 *
 * @param {string} id
 * @param {Language} locale
 * @return {*}  {Promise<GetRecentCommendResponse>}
 */
// export const getResourceDetail = (id: string, locale: Language): Promise<GetResourceDetailResponse> => {
//   return serverRequest(`/public/learning/garden/resource/detail/${id}`, {
//     method: 'get',
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };
export const getResourceDetail = (id: string) => {
  return clientRequest
    .get<GetResourceDetailResponse, GetResourceDetailResponse>(`/public/learning/garden/resource/detail/${id}`)
    .then((response) => response?.content);
};

/**
 * 获取相关学习资源推荐
 *
 * @param {string} id
 * @param {Language} locale
 * @return {*}  {Promise<GetRecentCommendResponse>}
 */
// export const getResourceRecommend = (id: string, locale: Language): Promise<GetResourceRecommendResponse> => {
//   return serverRequest(`/public/learning/garden/detail/recommend/${id}`, {
//     method: 'post',
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };
export const getResourceRecommend = (id: string) => {
  return clientRequest
    .post<GetResourceRecommendResponse, GetResourceRecommendResponse>(`/public/learning/garden/detail/recommend/${id}`)
    .then((response) => response?.content);
};

/**
 * 获取博客列表
 *
 * @param {string} id
 * @param {Language} locale
 * @return {*}  {Promise<GetResourceRecommendResponse>}
 */
export const getBlogList = (locale: Language): Promise<BlogPost[]> => {
  return serverRequest(`/wp-json/wp/v2/wk_get_blog_posts?lang=${locale}&per_page=4`, {
    method: 'get',
    apiPrefix: BLOG_URL,
    toJson: true,
  });
};
// export const getBlogList = (locale: Language) => {
//   return clientRequest.get<BlogPost[], BlogPost[]>(
//     `${BLOG_URL}/wp-json/wp/v2/wk_get_blog_posts?lang=${locale}&per_page=4`,
//   );
// };

/**
 * 获取专题详情
 *
 * @param {string} id
 * @param {Language} locale
 * @return {*}  {Promise<GetResourceRecommendResponse>}
 */
// export const getTopicDetail = (id: string, locale: Language): Promise<GetTopicDetailResponse> => {
//   return serverRequest(`/public/learning/garden/detail/topic/${id}`, {
//     method: 'post',
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };
export const getTopicDetail = (id: string) => {
  return clientRequest
    .post<GetTopicDetailResponse, GetTopicDetailResponse>(`/public/learning/garden/detail/topic/${id}`)
    .then((response) => response?.content);
};

/**
 * 获取推荐主题列表
 *
 * @param {Subject} subject
 * @param {string} id
 * @param {Language} locale
 * @return {*}  {Promise<GetRecommendTopicListResponse>}
 */
// export const getRecommendTopicList = (
//   subject: Subject,
//   id: string,
//   locale: Language,
// ): Promise<GetRecommendTopicListResponse> => {
//   return serverRequest(`/public/learning/garden/detail/topic/${capitalizeFirstLetter(subject)}/${id}`, {
//     method: 'post',
//     apiPrefix: apiUrl,
//     toJson: true,
//     headers: { 'Accept-Language': locale === 'zh' ? 'zh-CN' : 'en-US' },
//   });
// };
export const getRecommendTopicList = (subject: Subject, id: string) => {
  return clientRequest
    .post<GetRecommendTopicListResponse, GetRecommendTopicListResponse>(
      `/public/learning/garden/detail/topic/${capitalizeFirstLetter(subject)}/${id}`,
    )
    .then((response) => response?.content);
};

/**
 * 提交资源浏览、下载或播放记录
 *
 * @param {PostViewRecordRequest} params
 * @return {*}
 */
export const postViewRecord = (params: PostViewRecordRequest) => {
  return clientRequest.post<PostViewRecordResponse, PostViewRecordResponse>(
    '/public/learning/garden/resource/view/record',
    params,
  );
};
