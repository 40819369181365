/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import { checkForgetPwd, checkPhoneExist, sendBindCode, sendVerifyCodeV2, sendVerifyCodeV3 } from '@/request/login';

import styles from './style.module.scss';
import Input from '../Input';
import Toast from '@/components/Toast';
import VerifySlideFixed, { CaptchaSuccess } from '@/components/verifition/verifySlideFixed';
import ToastLoading from '@/components/ToastLoading';

export interface CodeInputProps {
  placeholder: string;
  className?: string;
  onChange?: (value: string) => void;
  target: string;
  maxLength?: number;
  onPressEnter?: () => void;
  type: 'EMAIL' | 'MOBILE_PHONE';
  way: 'LOGIN' | 'REGISTER';
  mode?: 'Normal' | 'Bind';
  codeClassName?: string;
  auto?: boolean;
  value?: string;
  apiUrl?: string;
  special?: boolean; // 特殊处理
  onNeedRegister?: (value: boolean) => void;
  onNeedLogin?: (value: boolean) => void;
  btnDisabled?: boolean;
}

const DEFAULT_COUNTDOWN_TIME: number = 60;

const CodeInput: React.FC<CodeInputProps> = (props) => {
  const {
    placeholder,
    className,
    onChange,
    target,
    maxLength,
    type,
    codeClassName,
    mode = 'Normal',
    way,
    value,
    onPressEnter,
    apiUrl,
    special,
    onNeedRegister,
    onNeedLogin,
    btnDisabled,
  } = props;
  const [sendFlag, setSendFlag] = useState(false);
  const { t } = useTranslation(['common']);
  const DEFAULT_CODE_TEXT = t('获取验证码');
  const [countDown, setCountDown] = useState<number>(-1);
  const [codeText, setCodeText] = useState(DEFAULT_CODE_TEXT); // 倒计时时长
  const [isSlideShow, setIsSlideShow] = React.useState<boolean>(false);

  const verifyTarget = useCallback((): boolean => {
    if (type === 'EMAIL' && !/^[^@]{1,}@[^@]{1,}$/.test(target)) {
      Toast.info(t('邮箱格式错误'));
      return true;
    }
    if (type === 'MOBILE_PHONE' && !/^\d+$/.test(target?.split?.(' ')?.[1])) {
      Toast.info(t('请输入有效手机号'));
      return true;
    }
    return false;
  }, [t, target, type]);

  // 发送验证码
  const sendCode = useCallback(
    async (code?: string) => {
      if (sendFlag) {
        return;
      }
      setSendFlag(true);
      if (codeText !== DEFAULT_CODE_TEXT || verifyTarget()) {
        setSendFlag(false);
        return;
      }

      setCountDown(DEFAULT_COUNTDOWN_TIME);

      if (special) {
        const { errorCode = '', message } = await checkForgetPwd(type === 'EMAIL' ? 'EMAIL' : 'MOBILE', target);
        if (errorCode && message) {
          setCountDown(-1);
          Toast.info(message);
          return;
        }
        if (errorCode === 1220) {
          setCountDown(-1);
          onNeedRegister?.(true);
          // Toast.info(t('该账号未注册'));
          return;
        }
        onNeedRegister?.(false);
      }

      if (apiUrl) {
        sendVerifyCodeV3(apiUrl, {
          target,
          verificationType: type,
          authenticationWay: way,
          captchaVerification: code,
        })
          .then((res: any) => {
            const { success, errorCode } = res;
            if (success === false && errorCode === 1201) {
              setCountDown(-1);
              onNeedRegister?.(true);
              return;
            }
            onNeedRegister?.(false);
          })
          .catch(() => {
            setCountDown(-1);
          });
        return;
      }

      if (mode === 'Normal') {
        sendVerifyCodeV2({ target, verificationType: type, authenticationWay: way, captchaVerification: code })
          .then((res: any) => {
            const { errorCode } = res || {};
            if (errorCode === 407 || errorCode === 406) {
              setCountDown(-1);
              Toast.info(t('该账号未注册'));
            }
          })
          .catch(() => {
            setCountDown(-1);
          });
      } else {
        sendBindCode({ target, verificationType: type, captchaVerification: code })
          .then((res: any) => {
            const { errorCode } = res || {};
            if (errorCode === 407 || errorCode === 406) {
              setCountDown(-1);
              Toast.info(t('该账号未注册'));
            }
          })
          .catch(() => {
            setCountDown(-1);
          });
      }
    },
    [sendFlag, mode, codeText, apiUrl, DEFAULT_CODE_TEXT, verifyTarget, target, type, way],
  );

  const existPhone = async () => {
    try {
      ToastLoading.show(t('加载中...'));
      const result = await checkPhoneExist({
        registerWay: 'PHONE',
        target,
      });
      if (result) {
        onNeedLogin?.(true);
        ToastLoading.hide();
        return true;
      }
      onNeedLogin?.(false);
      ToastLoading.hide();
      return false;
    } catch (e) {
      ToastLoading.hide();
      return true;
    }
  };

  const handleSend = async () => {
    if (btnDisabled) {
      return;
    }
    if (verifyTarget()) {
      return;
    }
    if (onNeedLogin) {
      const exist = await existPhone();
      if (exist) {
        return;
      }
    }
    target && codeText === DEFAULT_CODE_TEXT && setIsSlideShow(true);
  };

  useEffect(() => {
    let timer = -1;
    if (countDown > 0) {
      timer = window.setTimeout(() => {
        const time = countDown - 1;
        setCodeText(`${time} s`);
        setCountDown(time);
      }, 1e3);
    } else {
      setCodeText(DEFAULT_CODE_TEXT);
      setSendFlag(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countDown, DEFAULT_CODE_TEXT]);

  const codeNode = (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div role="link" className={codeClassName} onClick={handleSend}>
      {codeText}
    </div>
  );

  const closeVerify = () => {
    setIsSlideShow(false);
  };

  const verifySuccess = async ({ captchaVerification }: CaptchaSuccess) => {
    setIsSlideShow(false);
    sendCode(captchaVerification);
  };

  return (
    <>
      <VerifySlideFixed isSlideShow={isSlideShow} onClose={closeVerify} success={verifySuccess} />
      <Input
        onChange={(e) => onChange?.(e.currentTarget.value)}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        maxLength={maxLength}
        suffixCls={cls(styles.code, codeClassName)}
        suffix={codeNode}
        className={className}
        value={value}
      />
    </>
  );
};

export default CodeInput;
