export type Paths = {
  href?: string;
  hrefEn?: string;
  target?: string;
  label: string;
  ga?: string;
  children?: SelectorChildrenItem[];
}[];

export interface SelectorChildrenItem {
  label: string;
  href: string;
  hrefEn?: string;
  ga?: string;
  target?: string;
  // 自研埋点 下拉列表组名称
  traceEventGroupName?: string;
  children?: SelectorChildrenItem[];
}

const generatePaths = (t: (name: string) => string): Paths => {
  return [
    {
      label: t('首页'),
      href: '/',
      ga: '',
    },
    {
      label: t('中文课程'),
      href: '/chinese/',
      ga: 'ChineseLessons',
      children: [
        {
          label: t('启蒙中文'),
          href: '/chinese-introduction/',
          ga: 'IntroductionChinese',
          traceEventGroupName: 'O_ChineseChoiceClick',
        },
        {
          label: t('国际中文'),
          href: '/chinese-international/',
          ga: 'InternationalChinese',
          traceEventGroupName: 'O_ChineseChoiceClick',
        },
        {
          label: t('进阶中文'),
          href: '/chinese-advanced/',
          ga: 'AdvancedChinese',
          traceEventGroupName: 'O_ChineseChoiceClick',
        },
        {
          label: t('基础汉语'),
          href: '/chinese-basic/',
          ga: 'BasicChinese',
          traceEventGroupName: 'O_ChineseChoiceClick',
        },
        {
          label: t('悟空中文特色'),
          href: '/chinese-specialty/',
          ga: 'EDU',
          traceEventGroupName: 'O_ChineseChoiceClick',
        },
        { label: t('中文考试'), href: '/chinese-hsk-yct-ib/', ga: 'HSK', traceEventGroupName: 'O_ChineseChoiceClick' },
      ],
    },
    {
      label: t('数学课程'),
      href: '/math/',
      ga: 'MathLessons',
      children: [
        {
          label: t('数学 M2-4'),
          href: '/math-elementary/',
          ga: 'math-elementary',
          traceEventGroupName: 'O_MathChoiceClick',
        },
        {
          label: t('数学 M5-7'),
          href: '/math-advanced/',
          ga: 'math-advanced',
          traceEventGroupName: 'O_MathChoiceClick',
        },
        {
          label: t('竞赛课程'),
          href: '/math-contest/',
          ga: 'math-contest',
          traceEventGroupName: 'O_MathChoiceClick',
        },
        {
          label: t('数学特色'),
          href: '/math-contest/',
          ga: 'math-contest',
          traceEventGroupName: 'O_MathChoiceClick',
        },
      ],
    },
    {
      label: t('英文课程'),
      href: '/english/',
      ga: 'english',
    },
    {
      label: t('悟空师资'),
      href: '/teachers/',
      ga: 'Faculty',
    },
    {
      label: t('关于我们'),
      href: '/aboutus/',
      ga: 'AboutUs',
    },
    // {
    //   label: t('预约&评估'),
    //   href: '/appointment',
    //   ga: 'AssessmentReservation',
    //   children: [
    //     {
    //       label: t('预约中文课'),
    //       href: t('path:中文落地页'),
    //       target: '_blank',
    //       ga: 'BookingChineseCourses',
    //     },
    //     {
    //       label: t('预约数学课'),
    //       href: t('path:数学落地页'),
    //       target: '_blank',
    //       ga: 'BookingMathCourses',
    //     },
    //   ],
    // },
    {
      label: t('博客'),
      href: '/blog/zh/',
      hrefEn: '/blog/',
      ga: 'Blog',
    },
    {
      label: t('学习资料'),
      href: '/resources/',
      // ga: 'MathLessons',
      children: [
        {
          label: t('学习资料-中文'),
          href: '/resources/chinese/',
          children: [
            { label: t('字帖'), href: '/zitie/' },
            { label: t('学习计划'), href: '/zitie/' },
            { label: t('练习册'), href: '/zitie/' },
            { label: t('中国推荐书籍'), href: '/zitie/' },
          ],
        },
        {
          label: t('学习资料-数学'),
          href: '/resources/math/',
          children: [
            { label: t('练习册'), href: '/zitie/' },
            { label: t('习题精讲解析'), href: '/zitie/' },
            { label: t('竞赛专题提示'), href: '/zitie/' },
          ],
        },
        {
          label: t('学习资料-英文'),
          href: '/resources/english/',
          children: [
            { label: t('练习册'), href: '/zitie/' },
            { label: t('习题精讲解析'), href: '/zitie/' },
          ],
        },
        // {
        //   label: t('其他学习资料'),
        //   href: '/resources/other/',
        //   children: [{ label: t('全部'), href: '/zitie/' }],
        // },
        // {
        //   label: t('专题学习资料'),
        //   href: '',
        //   children: [
        //     { label: t('悟空新春特辑1'), href: '/resources/chinese/topic/qweqw/' },
        //     { label: t('悟空新春特辑2'), href: '/resources/chinese/topic/qweqwqwe/' },
        //     { label: t('悟空新春特辑3'), href: '/resources/chinese/topic/qweqewqw/' },
        //     { label: t('悟空新春特辑4'), href: '/resources/chinese/topic/qweqwqew/' },
        //     { label: t('悟空新春特辑5'), href: '/resources/chinese/topic/qwereqw/' },
        //   ],
        // },
      ],
    },
  ];
};

export default generatePaths;
