import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useUpdateEffect } from 'react-use';
import Modal from '@/components/Modal';
import { loginByPhone } from '@/request/login';
import ToastLoading from '@/components/ToastLoading';
import { hasTokenInfo, setTokenInfo } from '@/utils/token';
import BindAccountForm from '../BindAccountForm';
import { subscribe } from '@/request/commonGatWay';
import { LoginBtnReportParams } from '@/request/interface/login';
import LoginContext from '@/context/LoginContext';

interface Props {
  handleLoginFinish: (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams) => void;
}

const Footer: FC<Props> = ({ handleLoginFinish }) => {
  const { t } = useTranslation(['common']);
  const [params, setParams] = useState<any>({});
  const [bindAccountVisible, setBindAccountVisible] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const { setGoogleLoginSDKLoaded, loginPCVisible } = useContext(LoginContext);

  useUpdateEffect(() => {
    if (loginPCVisible && showPrompt) {
      window?.google?.accounts?.id?.cancel?.();
      setShowPrompt(false);
    }
  }, [loginPCVisible, showPrompt]);

  const initGoogleLogin = () => {
    window?.google?.accounts?.id?.initialize?.({
      client_id: '539432422945-v4n3mh2o3j6goqhslsggiup6c75jv7ro.apps.googleusercontent.com',
      callback: async (res: any) => {
        ToastLoading.show(t('加载中...'));
        try {
          const { content } = await loginByPhone({
            oauthType: 'GOOGLE_ANDROID',
            code: res?.credential,
          });
          const { errorCode, token, oauthType = '', unionId = '', openId = '', email = '' } = content || {};
          ToastLoading.hide();
          if (errorCode === 409) {
            setParams({ oauthType, unionId, openId });
            setBindAccountVisible(true);
          } else if (token) {
            setTokenInfo(token);
            // handleLoginFinish(false, {
            //   data: { _event: 'O_RegisterSuccess', logon_method: '谷歌提示' },
            // });
            handleLoginFinish();
            subscribe({ email, source: 'GOOGLE' });
          }
        } finally {
          ToastLoading.hide();
        }
      },
    });
    if (!loginPCVisible) {
      window?.google?.accounts?.id?.prompt?.(() => setShowPrompt(true));
    }
  };

  useEffect(() => {
    // 创建一个 <script> 元素
    const script = document.createElement('script');

    // 设置脚本的 src 属性为 https://accounts.google.com/gsi/client
    script.src = `https://accounts.google.com/gsi/client?time=${Date.now()}}`;

    // 定义脚本加载完成后的回调函数
    const handleScriptLoad = () => {
      // 在脚本加载完成后执行您的逻辑
      // 可以访问全局变量或调用特定函数
      setGoogleLoginSDKLoaded(true);
      if (!hasTokenInfo()) {
        initGoogleLogin();
      }
    };

    // 将回调函数绑定到脚本的 onload 事件
    script.onload = handleScriptLoad;

    // 将 <script> 元素添加到页面的 <head> 元素中
    document.head.appendChild(script);

    // 在组件卸载时清理脚本
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Modal visible={bindAccountVisible} handleClose={() => setBindAccountVisible(false)}>
      <BindAccountForm
        handleOpen={() => setBindAccountVisible(true)}
        handleClose={() => setBindAccountVisible(false)}
        handleLoginFinish={handleLoginFinish}
        params={params}
        bindType="login"
      />
    </Modal>
  );
};

export default Footer;
